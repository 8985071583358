import React, { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

import AuthLayout from '../../components/Layouts/AuthLayout';
import { useAuth } from '../../contexts/auth/context';
import PasswordForm from './PasswordForm';

const classes = {
  title: {
    textAlign: 'center',
  },
};

const PasswordRecover = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get('token');
  const { recoverPassword } = useAuth();

  useEffect(() => {
    if (!token) navigate('/auth/password/forgot');
  }, [token, navigate]);

  return (
    <AuthLayout>
      <Box mb={4.5} sx={classes.title}>
        <Typography variant="h1" color="primary.main">
          Define una contraseña para tu cuenta
        </Typography>
      </Box>
      <PasswordForm buttonText="Aceptar" passwordContextHandler={recoverPassword} />
    </AuthLayout>
  );
};

export default React.memo(PasswordRecover);
