import axios from 'axios';

import UserInterface, { LoginUserInterface, RegisterUserInterface } from '../interfaces/user';
import { axiosResponseHandler, axiosErrorHandler } from './handler';
import { ConfirmPasswordInterface, PasswordForgotInterface, RecoverPasswordInterface } from '../interfaces/auth';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/auth`,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true,
});

const register = async (
  user: RegisterUserInterface,
): Promise<UserInterface> => instance
  .post('/register', user)
  .then(axiosResponseHandler)
  .catch(axiosErrorHandler);

const retryEmailRegister = async (
  idUser: string,
): Promise<{ message: string }> => instance
  .post('/register/email', { id: idUser })
  .then(axiosResponseHandler)
  .catch(axiosErrorHandler);

const confirmPassword = async ({
  password,
  token,
}: ConfirmPasswordInterface): Promise<UserInterface> => instance
  .post(`/password?token=${token}`, password)
  .then(axiosResponseHandler)
  .catch(axiosErrorHandler);

const login = async (
  user: LoginUserInterface,
): Promise<UserInterface> => instance
  .post('/login', user)
  .then(axiosResponseHandler)
  .catch(axiosErrorHandler);

const forgotPassword = async (
  email: PasswordForgotInterface,
): Promise<UserInterface> => instance
  .post('/password/forgot', email)
  .then(axiosResponseHandler)
  .catch(axiosErrorHandler);

const recoverPassword = async ({
  password,
  token,
}: RecoverPasswordInterface): Promise<UserInterface> => instance
  .put(`/password?token=${token}`, password)
  .then(axiosResponseHandler)
  .catch(axiosErrorHandler);

const logout = () => instance.post('/logout');

export default {
  register,
  confirmPassword,
  login,
  forgotPassword,
  recoverPassword,
  logout,
  retryEmailRegister,
};
