import { memo, useEffect, useState } from 'react';
import {
  Backdrop,
  Box,
  CircularProgress,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';

import { useCourses } from '../../contexts/courses/context';

import ConfigurationForm from '../../components/Course/ConfigurationForm';
import CourseForm from '../../components/Course/CourseForm';
import InscriptionSteps from '../../components/InscriptionSteps';
import Layout from '../../components/Layouts/Layout';
import PaymentForm from '../../components/Course/PaymentForm';

import CourseInterface, {
  ConfigurationDetails,
  CreatePaymentInterface,
  EditCourseFormInterface,
  EditCourseInterface,
  PaymentInformation,
} from '../../interfaces/courses';

import classes from './classes';

const CourseEdit = () => {
  const { id: courseId } = useParams();

  const { fetchCourseById, updateCourse } = useCourses();
  const [currentStep, setCurrentStep] = useState(1);
  const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);
  const [currentCourse, setCurrentCourse] = useState<CourseInterface>(null);
  const [courseForm, setCourseForm] = useState<EditCourseInterface>();

  const goToNextStep = () => setCurrentStep((oldCurrentStep) => oldCurrentStep + 1);
  const goToPreviousStep = () => setCurrentStep((oldCurrentStep) => oldCurrentStep - 1);

  const onSubmit = (course: EditCourseFormInterface): void => {
    setCourseForm((currentCourseForm) => ({
      ...currentCourseForm,
      ...course,
      start_date: course.start_date.format('DD/MM/YYYY'),
      end_date: course.end_date.format('DD/MM/YYYY'),
      start_publication_date: course.start_publication_date.format('DD/MM/YYYY'),
      end_publication_date: course.end_publication_date.format('DD/MM/YYYY'),
      start_inscription_date: course.start_inscription_date.format('DD/MM/YYYY'),
      end_inscription_date: course.end_inscription_date.format('DD/MM/YYYY'),
      teachers: course.teachers.map(({
        category, name, email, signature,
      }) => ({
        category, name, email, signature,
      })),
      videos: course.videos.filter((video) => video.link !== ''),
    }));

    goToNextStep();
  };

  const onSubmitConfiguration = (configurationDetails: ConfigurationDetails) => {
    setCourseForm((currentCourseForm) => ({
      ...currentCourseForm,
      ...configurationDetails,
    }));

    goToNextStep();
  };

  const onSubmitPaymentForm = async (paymentForm: CreatePaymentInterface): Promise<void> => {
    setOpenBackdrop(true);

    const formData = new FormData();
    const oldImages = [];
    const oldSignatures = [];
    const teachersArray = [];

    courseForm.images.forEach((file) => {
      if (typeof file === 'string') {
        oldImages.push(file);
      } else {
        formData.append('images', file, file.name);
      }
    });

    courseForm.teachers.forEach(({
      signature, email, name, category,
    }) => {
      if (typeof signature === 'string') {
        oldSignatures.push(signature);
        teachersArray.push({
          email, name, category, signature,
        });
      } else if (!signature || (Array.isArray(signature) && signature.length === 0)) {
        teachersArray.push({
          email, name, category,
        });
      } else {
        const existingTeacher = teachersArray.find((teacher) => teacher.email === email);

        if (existingTeacher) {
          teachersArray.push(
            {
              email: existingTeacher.email,
              name: existingTeacher.name,
              category: existingTeacher.category,
            },
          );
        } else {
          teachersArray.push({
            email, name, category,
          });
        }

        formData.append('teacherSignature', signature as unknown as File, `${email}.jfif`);
      }
    });

    if (oldImages.length) formData.append('images', JSON.stringify(oldImages));

    if (courseForm.edition !== '') {
      formData.append('edition', courseForm.edition);
    }

    formData.append('banner', courseForm.banner);
    formData.append('description', courseForm?.description);
    formData.append('end_date', courseForm.end_date);
    formData.append('end_inscription_date', courseForm.end_inscription_date);
    formData.append('end_publication_date', courseForm.end_publication_date);
    formData.append('lecture_hours', courseForm.lecture_hours);
    formData.append('limit_vacancies', courseForm.limit_vacancies);
    formData.append('modality', courseForm.modality);
    formData.append('moodle', String(courseForm.moodle));
    formData.append('name', courseForm.name);
    formData.append('objectives', courseForm?.objectives);
    formData.append('payment_methods', JSON.stringify(paymentForm.payment_methods));
    formData.append('program', courseForm.program);
    formData.append('specialities', JSON.stringify(courseForm.specialities));
    formData.append('start_date', courseForm.start_date);
    formData.append('start_inscription_date', courseForm.start_inscription_date);
    formData.append('start_publication_date', courseForm.start_publication_date);
    formData.append('tariff', JSON.stringify(paymentForm.tariff));
    formData.append('teachers', JSON.stringify(teachersArray.filter((teacher) => typeof teacher.signature !== 'object')));
    formData.append('videos', JSON.stringify(courseForm.videos.map((video) => video.link)));
    formData.append('logos', JSON.stringify(courseForm.logos));
    formData.append('certificateContent', courseForm.certificateContent);

    if (paymentForm.isPreinscription) {
      formData.append('preinscription', JSON.stringify(paymentForm.preinscription));
    } else {
      formData.append('preinscription', JSON.stringify(null));
    }

    await updateCourse(courseId, formData);
    setOpenBackdrop(false);
  };

  const steps = [
    { text: '1. Información General', onClick: () => setCurrentStep(1) },
    { text: '2. Configuración', onClick: () => setCurrentStep(2) },
    { text: '3. Aranceles', onClick: () => setCurrentStep(3) },
  ];

  const stepGoBack = (information: PaymentInformation | ConfigurationDetails) => {
    setCurrentCourse((currentInformation) => ({ ...currentInformation, ...information }));

    goToPreviousStep();
  };

  useEffect(() => {
    const fetchCourse = async (): Promise<void> => {
      const courseToEdit = await fetchCourseById(courseId);
      setCurrentCourse(courseToEdit);
    };

    if (!courseForm) fetchCourse();
  }, [courseForm, courseId, fetchCourseById]);

  return (
    <Layout>
      <>
        <Typography
          variant="h1"
          color="primary.main"
          sx={classes.title}
        >
          Editar curso
        </Typography>
        <InscriptionSteps currentStep={currentStep} customSteps={steps} />
        {currentStep === 1 && (
          <CourseForm
            course={currentCourse}
            onSubmit={onSubmit}
          />
        )}
        {currentStep === 2 && (
          <ConfigurationForm
            course={currentCourse}
            onClickBack={(configurationDetails) => stepGoBack(configurationDetails)}
            onSubmit={onSubmitConfiguration}
          />
        )}
        {currentStep === 3 && (
          <PaymentForm
            course={currentCourse}
            mode="edit"
            onClickBack={(paymentInformation) => stepGoBack(paymentInformation)}
            onSubmit={onSubmitPaymentForm}
          />
        )}
        <Backdrop open={openBackdrop}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <CircularProgress size={80} thickness={5} />
            <Typography
              variant="h1"
              mt={1}
              mb={1}
              color="background.paper"
            >
              Tu curso se está actualizando
            </Typography>
            <Typography
              variant="h3"
              color="background.paper"
            >
              Aguarda un momento
            </Typography>
          </Box>
        </Backdrop>
      </>
    </Layout>
  );
};

export default memo(CourseEdit);
