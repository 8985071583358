import { modalityDisplayDictionary } from '../../helpers/courses';

import formatSpecialties from '../../utils/formatSpecialities';
import specialtiesData from '../../utils/especialidades.json';

import CourseInterface, { RowCourseInterface } from '../../interfaces/courses';

const buildRows = (courses: CourseInterface[]): RowCourseInterface[] => courses?.map(({
  end_date,
  end_inscription_date,
  end_publication_date,
  id,
  internalCourseId,
  modality,
  moodle,
  name,
  slug,
  specialities,
  start_date,
  start_inscription_date,
  start_publication_date,
}) => ({
  date: `desde ${start_date} hasta ${end_date}`,
  id,
  inscription_date: `desde ${start_inscription_date} hasta ${end_inscription_date}`,
  internalCourseId: internalCourseId || '',
  modality: modalityDisplayDictionary[modality],
  moodle,
  name,
  publication_date: `desde ${start_publication_date} hasta ${end_publication_date}`,
  see_course: 'VER CURSO',
  slug,
  speciality: specialities.length ? formatSpecialties(specialities) : '',
}));

const optionsFilter = [{
  label: 'Cursos gratuitos',
  key: 'free',
  value: true,
},
{
  label: 'Cursos arancelados',
  key: 'paid',
  value: true,
},
{
  label: 'Cursada virtual',
  key: 'modality',
  value: 'virtual',
},
{
  label: 'Cursada presencial',
  key: 'modality',
  value: 'presencial',
},
{
  label: 'Cursada mixta',
  key: 'modality',
  value: 'mixta',
},
{
  label: 'Moodle',
  key: 'moodle',
  value: true,
}];

const optionsSpecialty = [
  {
    id: '0',
    name: 'TODAS',
  },
  ...specialtiesData.map(({ Descripcion }, index) => ({
    id: (index + 1).toString(),
    name: Descripcion,
  })),
];

export {
  buildRows,
  optionsFilter,
  optionsSpecialty,
};
