import { memo } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { LoadingComponentProps } from '../../interfaces/controls';

const LoadingComponent = ({
  loadingSize, children, loading, center = false,
}: LoadingComponentProps): JSX.Element => {
  const styleContainer = center ? {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '25vh',
  } : { display: 'flex' };

  if (loading) {
    return (
      <Box sx={styleContainer}>
        <CircularProgress size={loadingSize} color="primary" />
      </Box>
    );
  }

  return children;
};

export default memo(LoadingComponent);
