import {
  createContext,
  useContext,
  useMemo,
  useReducer,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import authService from '../../services/authService';
import authReducer from './reducer';

import UserInterface, { RegisterUserInterface } from '../../interfaces/user';
import {
  AuthContextType,
  ConfirmPasswordInterface,
  LoginInterface,
  LogoutInterface,
  RecoverPasswordInterface,
} from '../../interfaces/auth';
import { ReactContainerProps } from '../../interfaces/props';

const AuthContext = createContext<AuthContextType>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const { state: locationState } = useLocation();
  const navigate = useNavigate();

  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  const { state, dispatch } = context;

  const setAuth = (
    auth: UserInterface,
  ) => {
    localStorage.setItem('auth', JSON.stringify(auth));
    dispatch({ type: 'FETCH_AUTH', auth });
  };

  const setAuthAndNavigateToHomeOrPreviousURL = (
    auth: UserInterface,
    redirectTo: string,
  ): void => {
    localStorage.setItem('auth', JSON.stringify(auth));
    dispatch({ type: 'FETCH_AUTH', auth });

    return navigate(redirectTo ?? locationState?.previousURL ?? '/courses');
  };

  const confirmPassword = async ({
    password,
    token,
  }: ConfirmPasswordInterface): Promise<void> => {
    try {
      await authService.confirmPassword({ password, token });
      enqueueSnackbar('Cuenta activada', { variant: 'success' });
      navigate('/login');
    } catch (e) {
      enqueueSnackbar(e, { variant: 'error' });
    }
  };

  const recoverPassword = async ({
    password,
    token,
  }: RecoverPasswordInterface): Promise<void> => {
    try {
      await authService.recoverPassword({ password, token });
      enqueueSnackbar('Se ha reestablecido tu contraseña', { variant: 'success' });
      navigate('/login');
    } catch (e) {
      enqueueSnackbar(e, { variant: 'error' });
    }
  };

  const login = async ({
    redirectTo,
    user,
  }: LoginInterface): Promise<void> => {
    try {
      const auth = await authService.login(user);
      setAuthAndNavigateToHomeOrPreviousURL(auth, redirectTo);
    } catch (e) {
      enqueueSnackbar(e, { variant: 'error' });
    }
  };

  const logout = async ({
    omitMoodleLogout,
    sessionExpired,
  }: LogoutInterface): Promise<void> => {
    try {
      const { auth } = state;
      await authService.logout();
      localStorage.removeItem('auth');
      dispatch({ type: 'LOGOUT', auth: undefined });

      const sessionExpiredParam = sessionExpired ? '?sessionExpired' : '';
      const currentURL = new URL(window.location.href);
      const returnURL = encodeURI(`${currentURL.protocol}//${currentURL.hostname}/login${sessionExpiredParam}`);
      const moodleLogoutURL = `${process.env.REACT_APP_MOODLE_URL}/auth/userkey/logout.php?return=${returnURL}`;

      if (omitMoodleLogout || !auth.moodleId) {
        navigate(`/login${sessionExpiredParam}`);
      } else {
        window.location.replace(moodleLogoutURL);
      }
    } catch (e) {
      enqueueSnackbar('Oops! Error inesperado', { variant: 'error' });
    }
  };

  const retryEmailRegister = async (idUser: string): Promise<{ message: string }> => {
    const sleep = (ms: number) => new Promise((r) => { setTimeout(r, ms); });
    try {
      const responseRetryEmailRegister = await authService.retryEmailRegister(idUser);
      await sleep(4000);
      return responseRetryEmailRegister;
    } catch (e) {
      enqueueSnackbar('Oops! Error al reenviar el mail', { variant: 'error' });
      await sleep(4000);
      return null;
    }
  };

  const registerUser = async (user: RegisterUserInterface): Promise<UserInterface> => {
    try {
      const userRegistered = await authService.register(user);
      if (userRegistered) {
        return userRegistered;
      }

      return null;
    } catch (e) {
      enqueueSnackbar('Usuario existente: Por favor, elige otro correo o documento.', { variant: 'error' });
      return null;
    }
  };

  return {
    confirmPassword,
    login,
    logout,
    recoverPassword,
    registerUser,
    retryEmailRegister,
    setAuth,
    state,
  };
};

const AuthProvider = ({ children }: ReactContainerProps) => {
  const authPersisted = localStorage.getItem('auth');
  const initialState: UserInterface | undefined = authPersisted
    ? JSON.parse(authPersisted) : undefined;

  const [state, dispatch] = useReducer(authReducer, {
    auth: initialState,
  });

  const value = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
    <AuthContext.Provider value={value as AuthContextType}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
