import React from 'react';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { useAuth } from '../../contexts/auth/context';
import { rolesDictionary } from '../../helpers/roles';
import { ProfileInterface } from '../../interfaces/layout';

const classes = {
  profileWrapper: {
    flexGrow: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
  },
  profileImageContainer: {
    textDecoration: 'none',
    ml: 2.5,
    pl: 2.5,
  },
  profileIcon: {
    fontSize: '30px',
  },
  profileDataContainer: {
    ml: 2,
    mr: 2,
  },
  profileName: {
    fontWeight: 600,
    lineHeight: '20px',
  },
  profileRole: {
    fontWeight: 400,
    lineHeight: '20px',
  },
  arrowContainer: {
    display: { xs: 'none', sm: 'block' },
    visibility: { xs: 'hidden', sm: 'visible' },
  },
  arrowIcon: {
    fontSize: '22px',
    color: 'rgba(0,0,0,0.54',
  },
};

const Profile = ({ openProfileSubMenu }: ProfileInterface) => {
  const { state: { auth: user } } = useAuth();

  return (
    <Box
      sx={classes.profileWrapper}
      onClick={openProfileSubMenu}
    >

      <Box style={classes.profileImageContainer}>
        <Tooltip title="Mi cuenta">
          <IconButton sx={{ p: 0 }}>
            <Avatar>
              <AccountCircleOutlinedIcon sx={classes.profileIcon} />
            </Avatar>
          </IconButton>
        </Tooltip>
      </Box>

      <Box sx={classes.profileDataContainer}>
        <Typography variant="h3" style={classes.profileName}>
          {`${user.first_name} ${user.last_name}`}
        </Typography>
        <Typography variant="h3" style={classes.profileRole} color="text.disabled">
          {rolesDictionary[user.role]}
        </Typography>
      </Box>
      <Box sx={classes.arrowContainer}>
        <KeyboardArrowDownIcon style={classes.arrowIcon} />
      </Box>
    </Box>
  );
};

export default React.memo(Profile);
