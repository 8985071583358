/* eslint-disable jsx-a11y/control-has-associated-label */
import {
  useEffect,
  useState,
  memo,
  useMemo,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  LinearProgress,
  Typography,
} from '@mui/material';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PortraitIcon from '@mui/icons-material/Portrait';
import KeyIcon from '@mui/icons-material/Key';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';

import paymentsService from '../../../services/paymentsService';
import useMercadopago from './useMercadopago';
import LoadingButton from '../../Controls/LoadingButton';
import ControlledTextField from '../../Inputs/ControlledTextField';
import rulesConstants from '../../../helpers/rulesConstants';
import ControlledSelect from '../../Inputs/ControlledSelect';
import { useCourses } from '../../../contexts/courses/context';
import { PaymentMethod } from '../../../interfaces/payments';
import { useMessageModal } from '../../../contexts/messageModal/context';
import { MPCardForm, MPForm, MPInstallmentsData } from '../../../interfaces/mercadopago';
import CourseInterface from '../../../interfaces/courses';
import { usePayments } from '../../../contexts/payments/context';

const classes = {
  formContainer: {
    maxWidth: '610px',
    boxSizing: 'border-box',
  },
  cardWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'start',
    maxHeight: '140px',
    boxSizing: 'border-box',
    transform: 'scale(0.65)',
  },
  buttonsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: { xs: 'column-reverse', md: 'row' },
    justifyContent: 'space-between',
  },
  backButton: {
    marginRight: { xs: '0px', md: '12px' },
  },
  submitButton: {
    marginLeft: { xs: '0px', md: '12px' },
    marginBottom: { xs: '12px', md: '0px' },
  },
};

declare global {
  interface HTMLElement {
    options: any[];
  }
}

interface Props {
  course?: CourseInterface;
  returnButtonAction: () => void;
}

// Este componente es del viejo form de Mercadopago, el cual es reemplazado
// por la opcion de Pagos Recurrentes con MP. Se deja y no se borra, por si
// se decide reutilizar nuevamente.
const MercadoPagoForm = ({ course, returnButtonAction }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);
  const [formMounted, setFormMounted] = useState<boolean>(false);
  const [isFormDisabled, setIsFormDisabled] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const { openMessageModal } = useMessageModal();
  const navigate = useNavigate();
  const {
    loading: mpLoading,
    setLoading: setMpLoading,
    mercadopago,
  } = useMercadopago();
  const { state: paymentState } = usePayments();
  const { getQuotes, getAmount } = useCourses();
  const { state: locationState } = useLocation();
  const {
    control, handleSubmit, watch, setValue,
  } = useForm<MPForm>({
    defaultValues: useMemo(() => ({
      'form-checkout__cardholderName': '',
      'form-checkout__cardholderEmail': '',
      'form-checkout__cardNumber': '',
      'form-checkout__cardExpirationMonth': '',
      'form-checkout__cardExpirationYear': '',
      'form-checkout__securityCode': '',
      'form-checkout__installments': '',
      'form-checkout__identificationType': '',
      'form-checkout__identificationNumber': '',
      'form-checkout__issuer': '',
    }), []),
  });
  const watchMPForm = watch();

  const [isValidCardNumber, setIsValidCardNumber] = useState<boolean>(false);
  const [maxCreditCardNumberLength, setMaxCreditCardNumberLength] = useState<number>(16);
  const [focused, setFocused] = useState<string>('');
  const [cardForm, setCardForm] = useState<MPCardForm>(null);
  const [formWasSubmitted, setFormWasSubmitted] = useState(false);

  const paymentCreateOrUpdate = (mpRequest) => (paymentState.payment
    ? paymentsService.update(paymentState.payment.id, { mpRequest })
    : paymentsService.create({
      course: locationState?.course.id,
      method: PaymentMethod.Mercadopago,
      conceptUser: locationState.conceptUser,
      mpRequest,
    }));

  const handleCardExpirationOnCardElement = (): string => `${watchMPForm['form-checkout__cardExpirationMonth'].length === 1 ? '0' : ''}${watchMPForm['form-checkout__cardExpirationMonth']}/${watchMPForm['form-checkout__cardExpirationYear']}`;

  const handleBlur = (): void => { setFocused(''); };

  const expirityHasError = () => {
    if (handleCardExpirationOnCardElement().length < 5) return false;
    const month = parseInt(watchMPForm['form-checkout__cardExpirationMonth'], 10);
    if (Number.isNaN(month) || month < 1 || month > 12) return true;
    const year = watchMPForm['form-checkout__cardExpirationYear'].length === 2 ? `20${watchMPForm['form-checkout__cardExpirationYear']}` : watchMPForm['form-checkout__cardExpirationYear'];
    if (Number.isNaN(year)) return true;
    const now = new Date();
    return now > new Date(parseInt(year, 10), month, 10, 0);
  };

  const handleUIOnSubmit = (bool: boolean): void => {
    setLoading(bool);
    setOpenBackdrop(bool);
    setIsFormDisabled(bool);
  };

  const onSubmit = async (): Promise<void> => {
    if (!isValidCardNumber || expirityHasError() || formWasSubmitted) return;
    setFormWasSubmitted(true); // to avoid double execution
    handleUIOnSubmit(true);
    const tokenForMPCardFormData = await cardForm.createCardToken();
    const mpCardFormData = cardForm.getCardFormData();
    mpCardFormData.token = tokenForMPCardFormData.token;

    paymentCreateOrUpdate(mpCardFormData)
      .then(() => {
        setLoading(false);
        setOpenBackdrop(false);
        openMessageModal({
          title: 'Pago aprobado',
          message: 'Se envió el detalle del curso a tu cuenta de email',
          variant: 'success',
          primaryButton: {
            onClick: () => navigate('/courses'),
          },
          closeButton: () => navigate('/courses'),
        });
      })
      .catch((error) => {
        handleUIOnSubmit(false);
        enqueueSnackbar(error, { variant: 'error' });
        setFormWasSubmitted(false);
      })
      .finally(() => { handleUIOnSubmit(false); setFormWasSubmitted(false); });
  };

  useEffect(() => {
    if (
      !mpLoading && mercadopago && !formMounted && !cardForm
      && (course || locationState?.course)
    ) {
      setCardForm(mercadopago.cardForm({
        amount: getAmount(course),
        autoMount: true,
        form: {
          id: 'form-checkout',
          cardholderName: { id: 'form-checkout__cardholderName' },
          cardholderEmail: { id: 'form-checkout__cardholderEmail' },
          cardNumber: { id: 'form-checkout__cardNumber' },
          cardExpirationMonth: { id: 'form-checkout__cardExpirationMonth' },
          cardExpirationYear: { id: 'form-checkout__cardExpirationYear' },
          securityCode: { id: 'form-checkout__securityCode' },
          installments: { id: 'form-checkout__installments' },
          identificationType: { id: 'form-checkout__identificationType' },
          identificationNumber: { id: 'form-checkout__identificationNumber' },
          issuer: { id: 'form-checkout__issuer' },
        },
        callbacks: {
          onFormMounted: (error: unknown) => {
            if (error) {
              enqueueSnackbar('Hubo un problema generando el formulario de pago. Por favor intentá nuevamente en unos minutos. Si el problema persiste, contacte a su entidad bancaria o pruebe con otra tarjeta.');
            } else {
              setValue('form-checkout__identificationType', 'DNI');
              setFormMounted(true);
            }
          },
          onInstallmentsReceived: (error: unknown, installmentsData: MPInstallmentsData) => {
            if (error) {
              enqueueSnackbar('Hubo un problema generando el formulario de pago. Por favor intentá nuevamente en unos minutos.');
            } else {
              document.getElementById('form-checkout__installments').options.length = 0;

              const oneMonthInstallment = installmentsData.payer_costs.find((installment) => installment.installments === '1');
              setValue('form-checkout__installments', oneMonthInstallment.installments);

              installmentsData.payer_costs.forEach((payerCost) => {
                const opt = document.createElement('option');
                opt.text = payerCost.recommended_message;
                opt.value = payerCost.installments;

                if (Number(opt.value) <= getQuotes(course)) {
                  document.getElementById('form-checkout__installments').appendChild(opt);
                }
              });
            }
          },
        },
      }));
    }
  }, [
    mercadopago, formMounted, mpLoading,
    cardForm, setMpLoading, enqueueSnackbar,
    locationState?.course, course, setValue, getAmount, getQuotes,
  ]);

  if (mpLoading) {
    return (
      <Box mt={3} sx={classes.formContainer}>
        <LinearProgress color="primary" />
        <Typography>
          Preparando el formulario de pago...
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      id="form-checkout"
      component="form"
      noValidate
      autoComplete="off"
      sx={classes.formContainer}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container spacing={2.5}>
        <Grid item xs={12} md={4}>
          <Box sx={classes.cardWrapper}>
            <Cards
              cvc={watchMPForm['form-checkout__securityCode']}
              expiry={handleCardExpirationOnCardElement()}
              focused={focused}
              name={watchMPForm['form-checkout__cardholderName']}
              number={watchMPForm['form-checkout__cardNumber']}
              locale={{ valid: 'fecha exp.' }}
              placeholders={{ name: 'NOMBRE Y APELLIDO' }}
              callback={({ maxLength }, isValid) => {
                setMaxCreditCardNumberLength(maxLength);
                setIsValidCardNumber(isValid);
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container spacing={2.5} mt={0.5}>
            <Grid item xs={12} md={7}>
              <ControlledTextField
                control={control}
                fieldName="form-checkout__cardNumber"
                fieldLabel="Número de tarjeta"
                type="tel"
                startAdornment={<CreditCardIcon />}
                onFocus={() => setFocused('number')}
                onBlur={handleBlur}
                inputProps={{ maxLength: maxCreditCardNumberLength }}
                rules={rulesConstants.textfield({ fieldLabel: 'Número de tarjeta' })}
                disabled={isFormDisabled}
                trim
              />
            </Grid>
            <Grid item xs={3} md={2.5}>
              <ControlledTextField
                control={control}
                fieldName="form-checkout__cardExpirationMonth"
                fieldLabel="Mes"
                onFocus={() => setFocused('expiry')}
                onBlur={handleBlur}
                type="tel"
                inputProps={{ maxLength: 2, style: { textAlign: 'center' } }}
                rules={rulesConstants.textfield({ fieldLabel: 'Mes' })}
                disabled={isFormDisabled}
                // To Do: Setear error cuando fecha de expiry es invalida
              />
            </Grid>
            <Grid item xs={3} md={2.5}>
              <ControlledTextField
                control={control}
                fieldName="form-checkout__cardExpirationYear"
                fieldLabel="Año"
                onFocus={() => setFocused('expiry')}
                onBlur={handleBlur}
                type="tel"
                inputProps={{ maxLength: 2, style: { textAlign: 'center' } }}
                rules={rulesConstants.textfield({ fieldLabel: 'Año' })}
                disabled={isFormDisabled}
                // To Do: Setear error cuando fecha de expiry es invalida
              />
            </Grid>
            <Grid item xs={12} md={7} order={{ xs: 5, md: 4 }}>
              <ControlledTextField
                control={control}
                fieldName="form-checkout__cardholderName"
                fieldLabel="Titular de la tarjeta"
                startAdornment={<PortraitIcon />}
                onFocus={() => setFocused('name')}
                onBlur={handleBlur}
                rules={rulesConstants.textfield({ fieldLabel: 'Titular de la tarjeta' })}
                disabled={isFormDisabled}
              />
            </Grid>
            <Grid item xs={6} md={5} order={{ xs: 4, md: 5 }}>
              <ControlledTextField
                control={control}
                fieldName="form-checkout__securityCode"
                fieldLabel="Código de seguridad"
                startAdornment={<KeyIcon />}
                type="password"
                onFocus={() => setFocused('cvc')}
                onBlur={handleBlur}
                rules={rulesConstants.textfield({ fieldLabel: 'Código de seguridad' })}
                disabled={isFormDisabled}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} md={4} order={{ xs: 4, md: 3 }}>
          <ControlledSelect
            control={control}
            fieldName="form-checkout__identificationType"
            fieldLabel="Tipo de documento"
            options={[]}
            rules={rulesConstants.select({ fieldLabel: 'Tipo de doc', message: 'Requerido' })}
            native
            disabled={isFormDisabled}
          />
        </Grid>
        <Grid item xs={12} md={8} order={{ xs: 3, md: 4 }}>
          <ControlledTextField
            control={control}
            fieldName="form-checkout__cardholderEmail"
            fieldLabel="Email"
            type="email"
            rules={rulesConstants.emailTextfield({ fieldLabel: 'Email' })}
            disabled={isFormDisabled}
          />
        </Grid>
        <Grid item xs={6} md={4} order={{ xs: 5, md: 5 }}>
          <ControlledTextField
            control={control}
            fieldName="form-checkout__identificationNumber"
            fieldLabel="Número de documento"
            rules={rulesConstants.textfield({ fieldLabel: 'Número de documento' })}
            disabled={isFormDisabled}
          />
        </Grid>
        <Grid item xs={12} md={8} order={{ xs: 6, md: 6 }}>
          <ControlledSelect
            control={control}
            fieldName="form-checkout__installments"
            fieldLabel="Cantidad de cuotas"
            options={[]}
            rules={rulesConstants.select({ fieldLabel: 'Cantidad de cuotas', message: 'Requerido' })}
            native
            disabled={isFormDisabled}
          />
        </Grid>
      </Grid>
      <Box mt={5} sx={classes.buttonsContainer}>
        <LoadingButton
          fullWidth
          loading={false}
          variant="outlined"
          sx={classes.backButton}
          onClick={returnButtonAction}
          disabled={isFormDisabled}
        >
          Volver
        </LoadingButton>
        <LoadingButton
          fullWidth
          loading={loading}
          variant="contained"
          type="submit"
          sx={classes.submitButton}
          disabled={isFormDisabled}
        >
          Pagar
        </LoadingButton>
        <select style={{ display: 'none' }} name="issuer" id="form-checkout__issuer" />

        <Backdrop open={openBackdrop}>
          <CircularProgress size={48} />
        </Backdrop>
      </Box>
    </Box>
  );
};

export default memo(MercadoPagoForm);
