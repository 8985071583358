import { GridColDef } from '@mui/x-data-grid';

import { enrollmentStatesDict } from '../../common/constants';

import { buildColumn } from '../../helpers/tables';

import { EnrollmentInterface, RowInterface } from '../../interfaces/enrollment';

const optionsFilter = [{
  key: 'state',
  label: 'Lista de espera',
  value: 'wait',
  valueOff: 'enrolled',
}];

const columns: GridColDef[] = [
  buildColumn({
    field: 'studentName',
    headerName: 'Nombre y Apellido',
    minWidth: 150,
    flex: 1,
    cellAlign: true,
    renderCellSlice: 32,
  }),
  buildColumn({
    field: 'document',
    headerName: 'Tipo y N° de Documento',
    width: 180,
  }),
  buildColumn({
    field: 'internalCourseId',
    headerName: 'ID Interno',
  }),
  buildColumn({
    field: 'courseEnrolled',
    headerName: 'Curso al que se inscribió',
    minWidth: 150,
    flex: 1,
    cellAlign: true,
    renderCellSlice: 32,
  }),
  buildColumn({
    field: 'startInscriptionDate',
    headerName: 'Fecha de inscripción',
    width: 150,
  }),
  buildColumn({
    field: 'enrollmentStatus',
    headerName: 'Estado de inscripción',
    width: 150,
  }),
];

const buildRows = (
  rows: EnrollmentInterface[],
): RowInterface[] => rows?.map(({
  _id,
  user,
  course,
  lastState,
}) => ({
  courseEnrolled: course.name,
  // eslint-disable-next-line no-underscore-dangle
  courseId: course._id,
  document: user.documentType.toUpperCase().concat(' ', user.document),
  enrollmentStatus: enrollmentStatesDict[lastState.type],
  id: _id,
  internalCourseId: course.internalCourseId,
  removeEnrollment: 'DAR DE BAJA',
  seeDetails: 'VER',
  startInscriptionDate: lastState.date,
  studentId: user.id,
  studentName: user.first_name.concat(' ', user.last_name),
}));

export {
  buildRows,
  columns,
  optionsFilter,
};
