export const rolesDictionary = {
  student: 'Estudiante',
  teacher: 'Docente',
  admin: 'Administrador',
};

export const ROLES = {
  STUDENT: 'student',
  TEACHER: 'teacher',
  ADMIN: 'admin',
};
