import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import _ from 'lodash';

import paymentsService from '../../services/paymentsService';

import paymentsReducer from './reducer';

import PaymentInterface, {
  DownloadPaymentProps,
  FiltersExportReport,
  InstallmentStatuses,
  PaymentMethod,
  PaymentsContextType,
  QueryGetPaymentsInterface,
  ResGetAllPaymentsInterface,
  TypeAction,
  UpdateInstallmentsInterface,
} from '../../interfaces/payments';
import { ReactContainerProps } from '../../interfaces/props';

const PaymentsContext = createContext<PaymentsContextType>(undefined);

export const usePayments = () => {
  const context = useContext(PaymentsContext);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  if (context === undefined) throw new Error('usePayments must be used within a PaymentsProvider');

  const { state, dispatch } = context;

  const checkMPPayment = (payment: PaymentInterface): void => {
    const isMPInscriptionAlreadyPaid = payment
      .installmentsData[1].status === InstallmentStatuses.Approved;

    if (isMPInscriptionAlreadyPaid) {
      enqueueSnackbar('Error al ir a pago', { variant: 'error' });
      navigate('/');
    }
  };

  const fetchPaymentById = async (id: string): Promise<void> => {
    dispatch({ type: TypeAction.REQUESTING_DATA });
    try {
      const payment = await paymentsService.getById(id);
      if (payment.method === PaymentMethod.Mercadopago) checkMPPayment(payment);
      dispatch({ type: TypeAction.GET_PAYMENT, payment });
    } catch (e) {
      enqueueSnackbar('Pago no encontrado', { variant: 'error' });
      navigate('/');
    }
  };

  const fetchPayments = async (
    query?: QueryGetPaymentsInterface,
  ): Promise<ResGetAllPaymentsInterface> => {
    try {
      const payments = await paymentsService.getAll(query);
      return payments;
    } catch (e) {
      enqueueSnackbar('Oops! Error inesperado al obtener los pagos', { variant: 'error' });
      return null;
    }
  };

  const updatePayment = async (
    id: string,
    data: { installmentsData: UpdateInstallmentsInterface[] },
  ): Promise<PaymentInterface> => {
    try {
      const updatedPayment = await paymentsService.update(id, data);
      enqueueSnackbar('Pago actualizado', { variant: 'success' });
      return updatedPayment;
    } catch (e) {
      enqueueSnackbar('Oops! Error inesperado', { variant: 'error' });
      return null;
    }
  };

  const handleExport = async (filters: FiltersExportReport = {}): Promise<void> => {
    try {
      const reportBlob = await paymentsService.handleExport(filters);
      const excelUrl = URL.createObjectURL(reportBlob);

      const a = document.createElement('a');
      a.href = excelUrl;
      a.download = 'ReporteDeCobranzas.xlsx';
      a.click();
      a.remove();

      setTimeout(() => URL.revokeObjectURL(a.href), 7000);
    } catch (e) {
      enqueueSnackbar(e, { variant: 'error' });
    }
  };

  const downloadPayment = useCallback(async ({
    courseName,
    installmentId,
    paymentId,
  }: DownloadPaymentProps): Promise<void> => {
    try {
      const payment = await paymentsService.downloadPayment(paymentId, installmentId);

      const url = URL.createObjectURL(payment);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${courseName}-payment.pdf`;
      link.click();

      URL.revokeObjectURL(url);
    } catch (e) {
      enqueueSnackbar('Oops! Error al descargar el pago.', { variant: 'error' });
    }
  }, [enqueueSnackbar]);

  return {
    dispatch,
    downloadPayment,
    fetchPaymentById,
    fetchPayments,
    handleExport,
    state,
    updatePayment,
  };
};

const PaymentsProvider = ({ children }: ReactContainerProps) => {
  const [state, dispatch] = useReducer(paymentsReducer, {
    payment: null,
  });

  const value = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
    <PaymentsContext.Provider value={value as PaymentsContextType}>
      {children}
    </PaymentsContext.Provider>
  );
};

export default PaymentsProvider;
