import {
  createContext,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { useSnackbar } from 'notistack';

import enrollmentService from '../../services/enrollmentsService';

import { ReactContainerProps } from '../../interfaces/props';
import {
  DiscountTypes,
  EnrollmentInterface,
  GenericResponseEnrollment,
  QueryGetEnrollmentsInterface,
  ResGetEnrollmentsInterface,
  TypeEnrollment,
  UpdateEnrollmentInterface,
} from '../../interfaces/enrollment';

const EnrollmentsContext = createContext(undefined);

export const useEnrollments = () => {
  const context = useContext(EnrollmentsContext);
  const { enqueueSnackbar } = useSnackbar();

  if (context === undefined) {
    throw new Error('useEnrollments must be used within a EnrollmentsProvider');
  }

  const { state, dispatch } = context;

  const fetchEnrollments = async (
    query: QueryGetEnrollmentsInterface,
  ): Promise<ResGetEnrollmentsInterface> => {
    try {
      return await enrollmentService.getAll(query);
    } catch (e) {
      enqueueSnackbar('Oops! Error al obtener las inscripciones', { variant: 'error' });
      return null;
    }
  };

  const removeEnrollment = async (enrollmentId: string): Promise<GenericResponseEnrollment> => {
    try {
      return await enrollmentService.remove(enrollmentId);
    } catch (e) {
      enqueueSnackbar('Oops! Error al borrar la inscripción', { variant: 'error' });
      return null;
    }
  };

  const enroll = async (
    userId: string,
    courseId: string,
    typeEnrollment: TypeEnrollment,
    observations: string = '',
    userCategory?: string,
    discount: number = 0,
    discountType: string = DiscountTypes.Percentage,
  ): Promise<GenericResponseEnrollment> => {
    try {
      return await enrollmentService.postEnrollment(
        userId,
        courseId,
        typeEnrollment,
        observations,
        userCategory,
        discount,
        discountType,
      );
    } catch (e) {
      enqueueSnackbar(typeof e === 'string' ? e : 'Oops! Error al inscribirse', { variant: 'error' });
      return null;
    }
  };

  const enrollToWaitingList = async (
    userId: string,
    courseId: string,
  ): Promise<GenericResponseEnrollment> => {
    try {
      return await enrollmentService.postWaitingList(userId, courseId);
    } catch (e) {
      enqueueSnackbar('Oops! Error al añadirse a la lista de espera', { variant: 'error' });
      return null;
    }
  };

  const updateEnrollment = async (
    enrollmentId: string,
    toUpdate: Partial<UpdateEnrollmentInterface>,
  ): Promise<GenericResponseEnrollment> => {
    try {
      return await enrollmentService.update(enrollmentId, toUpdate);
    } catch (e) {
      enqueueSnackbar('Oops! Error al actualizar la inscripción', { variant: 'error' });
      return null;
    }
  };

  const getAllCertificates = useCallback(async (): Promise<EnrollmentInterface[]> => {
    try {
      const certificates = await enrollmentService.getAllCertificates();
      return certificates;
    } catch (e) {
      enqueueSnackbar('Oops! Error al traer certificados', { variant: 'error' });
      return null;
    }
  }, [enqueueSnackbar]);

  const downloadCertificate = useCallback(async (id: string, title: string): Promise<void> => {
    try {
      const certificate = await enrollmentService.downloadCertificate(id);

      const url = URL.createObjectURL(certificate);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${title}.pdf`;
      link.click();

      URL.revokeObjectURL(url);
    } catch (e) {
      enqueueSnackbar('Oops! Error al descargar certificado', { variant: 'error' });
    }
  }, [enqueueSnackbar]);

  return {
    state,
    dispatch,
    fetchEnrollments,
    removeEnrollment,
    enroll,
    enrollToWaitingList,
    updateEnrollment,
    getAllCertificates,
    downloadCertificate,
  };
};

const EnrollmentsProvider = ({ children }: ReactContainerProps) => {
  const value = useMemo(() => ({}), []);

  return (
    <EnrollmentsContext.Provider value={value}>
      {children}
    </EnrollmentsContext.Provider>
  );
};

export default EnrollmentsProvider;
