import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  List,
  ListItem,
  Tooltip,
  Typography,
} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';

import { useConfiguration } from '../../contexts/logos/context';

import ControlledTextField from '../Inputs/ControlledTextField';

import { capitalizeMonth, formatToValidDayjs } from '../../utils/date';
import rulesConstants from '../../helpers/rulesConstants';

import { MAX_LOGOS } from '../../common/constants';
import { DEFAULT_LOGOS } from './constants';

import { TLogo } from '../../interfaces/logos';
import { ConfigurationFormProps } from './types';

import { configurationClasses as classes } from './classes';

const Configuration = ({
  course,
  onClickBack,
  onSubmit,
}: ConfigurationFormProps) => {
  const { fetchLogos } = useConfiguration();
  const {
    control,
    formState,
    getValues,
    handleSubmit,
    setValue,
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      logos: course?.logos || [],
      certificateContent: course.certificateContent || 'ha aprobado el programa de actividades educativas y el trabajo integral final',
    },
  });
  const [uploadedLogos, setUploadedLogos] = useState<TLogo[]>([]);
  const logos = watch('logos');

  const validateDefaultLogo = (logoId: string) => DEFAULT_LOGOS.find((logo) => logo.id === logoId);

  const getLogos = async () => {
    const fetchLogosResponse = await fetchLogos();

    if (fetchLogosResponse) {
      setUploadedLogos(fetchLogosResponse.logos);
    }
  };

  const handleOnChange = (file: TLogo) => {
    if (logos.length < MAX_LOGOS) {
      if (!logos.includes(file.id)) return setValue('logos', [...logos, file.id]);

      return setValue('logos', logos.filter((logoId) => logoId !== file.id));
    }

    return setValue('logos', logos.filter((logoId) => logoId !== file.id));
  };

  const courseYear = new Date(formatToValidDayjs(course.end_date)).getFullYear();
  const courseStartMonth = capitalizeMonth(new Date(formatToValidDayjs(course.start_date)).toLocaleString('es-AR', { month: 'long' }));
  const courseEndMonth = capitalizeMonth(new Date(formatToValidDayjs(course.end_date)).toLocaleString('es-AR', { month: 'long' }));
  const courseDictatedMonthsText = courseStartMonth === courseEndMonth ? `en ${courseStartMonth}` : `de ${courseStartMonth} a ${courseEndMonth}`;

  useEffect(() => {
    getLogos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      autoComplete="off"
      component="form"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
    >
      <Typography sx={{ fontWeight: 'bold' }}>Logos disponibles</Typography>
      {uploadedLogos?.length ? (
        <List sx={classes.listContainer}>
          {[...DEFAULT_LOGOS, ...uploadedLogos].map((file) => {
            const isDefaultLogo = validateDefaultLogo(file.id);
            const baseUrl = process.env.REACT_APP_API_URL;
            const isDefaultLogoPath = isDefaultLogo ? '/assets' : '';
            const logoSrc = `${baseUrl}/static${isDefaultLogoPath}/${file.name}`;

            return (
              <ListItem key={file.id} sx={classes.listImage}>
                <Box sx={classes.courseImageContainer}>
                  <img
                    alt={file.originalName}
                    loading="lazy"
                    src={logoSrc}
                    style={{
                      ...classes.courseImages,
                      objectFit: 'contain',
                    }}
                  />
                  {isDefaultLogo
                    ? (
                      <Checkbox
                        checked
                        disabled
                        sx={classes.checkbox}
                      />
                    )
                    : (
                      <Checkbox
                        checked={logos.includes(file.id)}
                        onChange={() => handleOnChange(file)}
                        sx={classes.checkbox}
                      />
                    )}
                </Box>
                {file.originalName}
              </ListItem>
            );
          })}
        </List>
      ) : (
        <Typography>
          No contamos con ningún logo disponible para este curso.
          Dirigite a la sección de configuración para poder cargarlos.
        </Typography>
      )}
      <Typography sx={{ fontWeight: 'bold', marginTop: 4 }}>Certificado</Typography>
      <Box sx={{ display: 'flex', marginTop: 1 }}>
        <ControlledTextField
          control={control}
          endAdornment={(
            <Tooltip disableFocusListener title="Ingrese el texto del certificado aquí." placement="top">
              <IconButton
                aria-label="Debe introducir el texto del Certificado."
                color="primary"
                component="label"
                size="small"
                sx={{ p: 0, mb: 0.25 }}
              >
                <HelpIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
          fieldLabel="Texto del Certificado"
          fieldName="certificateContent"
          required
          rules={rulesConstants.textfield({ fieldLabel: 'Texto del Certificado' })}
          type="text"
        />
        <Typography sx={classes.certificateContent}>
          dictado
          {' '}
          {courseDictatedMonthsText}
          {' '}
          {courseYear}
          , horas acreditadas
          {' '}
          {course.lecture_hours}
          .- En fe de lo cual, se extiende el presente CERTIFICADO
        </Typography>
      </Box>
      <Box sx={classes.buttonsContainer}>
        <Button
          color="primary"
          onClick={() => onClickBack(getValues())}
          type="submit"
          variant="outlined"
        >
          Volver
        </Button>
        <Button
          color="primary"
          disabled={!formState.isValid}
          type="submit"
          variant="contained"
        >
          Continuar
        </Button>
      </Box>
    </Box>
  );
};

export default Configuration;
