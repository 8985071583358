import React from 'react';
import { Controller } from 'react-hook-form';
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
  NativeSelect,
  Box,
  Chip,
} from '@mui/material';

import { ControlledSelectProps, OptionInterface } from '../../interfaces/inputs';
import { PaymentDisplayMethods, PaymentMethod } from '../../interfaces/payments';

const classes = {
  nativeSelectFormControl: {
    height: '100%',
    textAlign: 'start',
    display: 'flex',
    justifyContent: 'end',
  },
  nativeSelect: (error) => ({
    fontSize: '1rem',
    fontFamily: ['Inter', 'sans-serif'].join(','),
    color: error ? 'red' : '',
  }),
  selectEmptyOption: { fontSize: '12px' },
};

const ControlledSelect = ({
  control,
  fieldName,
  fieldLabel,
  options,
  disabled = false,
  rules,
  fullWidth = true,
  native = false,
  type = '',
}: ControlledSelectProps) => (
  <Controller
    control={control}
    name={fieldName}
    rules={rules}
    defaultValue=""
    render={({ field, fieldState: { error } }) => {
      // eslint-disable-next-line no-console
      if (field.value === undefined && process.env.REACT_APP_ENV !== 'prod') console.log('Debe enviarse una string vacía "" en lugar de undefined');

      if (native) {
        return (
          <FormControl
            sx={classes.nativeSelectFormControl}
            fullWidth
            variant="standard"
            error={!!error}
          >
            <NativeSelect
              id={fieldName}
              disabled={disabled}
              fullWidth
              variant="standard"
              sx={classes.nativeSelect(error)}
              value={field.value}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...field}
            />
            {error && (
              <FormHelperText color="error.main">
                {error.message}
              </FormHelperText>
            )}
          </FormControl>
        );
      }

      if (type === 'chip') {
        return (
          <FormControl
            sx={{ textAlign: 'start' }}
            fullWidth
            variant="standard"
            error={!!error}
          >
            <InputLabel id={`${fieldName}-label`}>
              {fieldLabel}
            </InputLabel>
            <Select
              id={fieldName}
              multiple
              value={field.value}
              name={fieldName}
              label={fieldLabel}
              variant="standard"
              disabled={disabled}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value in PaymentDisplayMethods ? PaymentDisplayMethods[value] : value} size="small" sx={{ bgcolor: '#E2E5FA' }} />
                  ))}
                </Box>
              )}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...field}
            >
              <MenuItem key={fieldLabel} value="" disabled style={classes.selectEmptyOption}>
                <em>{fieldLabel}</em>
              </MenuItem>
              {options.map((option: OptionInterface) => (
                <MenuItem key={option.name} value={option.id} disabled={disabled}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
            {error && (
              <FormHelperText color="error.main">
                {error.message}
              </FormHelperText>
            )}
          </FormControl>
        );
      }

      return (
        <FormControl
          sx={{
            textAlign: 'start',
            width: {
              xs: !fullWidth ? '80%' : '100%',
              md: !fullWidth ? '40%' : '100%',
            },
          }}
          fullWidth
          variant="standard"
          error={!!error}
        >
          <InputLabel id={`${fieldName}-label`}>
            {fieldLabel}
          </InputLabel>
          <Select
            id={fieldName}
            name={fieldName}
            label={fieldLabel}
            variant="standard"
            disabled={disabled}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...field}
          >
            <MenuItem key={fieldLabel} value="" disabled style={classes.selectEmptyOption}>
              <em>{fieldLabel}</em>
            </MenuItem>
            {options.map((option: OptionInterface) => (
              <MenuItem key={option.name} value={option.id} disabled={disabled}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
          {error && (
            <FormHelperText color="error.main">
              {error.message}
            </FormHelperText>
          )}
        </FormControl>
      );
    }}
  />

);

export default React.memo(ControlledSelect);
