import { memo } from 'react';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Typography,
} from '@mui/material';
import {
  Close as CloseIcon,
} from '@mui/icons-material';

import { EnrollmentInterface } from '../../../interfaces/enrollment';
import { buildModalData } from './constants';

import classes from './classes';

interface Props {
  enrollment?: EnrollmentInterface;
  onCloseModal: () => void;
  openModal: boolean;
}

const SeeDetailsModal = ({
  enrollment,
  onCloseModal,
  openModal,
}: Props) => (
  <Modal open={openModal} onClose={onCloseModal}>
    <Box sx={classes.modalContainer}>
      <Box sx={classes.closeButtonContainer}>
        <IconButton
          onClick={onCloseModal}
          size="large"
          sx={classes.closeIconButton}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Box sx={classes.modalContentContainer}>
        <Typography
          color="primary.main"
          sx={classes.modalTitle}
          variant="h2"
        >
          Ver detalles
        </Typography>
        {
          enrollment && buildModalData(enrollment).map((data) => (
            <Box sx={classes.dataContainer} key={data.label}>
              <Typography variant="h5" color="secondary.main">
                {data.label}
              </Typography>
              <Typography variant="h5">
                {data.value}
              </Typography>
            </Box>
          ))
        }
        <Box sx={classes.observationContainer}>
          <Typography variant="h5" color="secondary.main">
            Observaciones:
          </Typography>
          <Typography variant="h5">
            {enrollment?.observations || 'No tiene'}
          </Typography>
        </Box>
        <Grid container sx={classes.modalButtonContainer}>
          <Grid item flexGrow={1}>
            <Button variant="outlined" fullWidth onClick={onCloseModal}>
              Volver
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  </Modal>
);

export default memo(SeeDetailsModal);
