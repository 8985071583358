import React, {
  useState, useMemo, useCallback, useEffect,
} from 'react';
import {
  Box, Typography, Button, Checkbox,
} from '@mui/material';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Layout from '../../components/Layouts/Layout';
import InscriptionSteps from '../../components/InscriptionSteps';

const classes = {
  titleContainer: {
    display: 'flex',
    justifyContent: { xs: 'center', sm: 'left' },
  },
  title: {
    letterSpacing: '0.15px',
    lineHeight: '24.2px',
    fontSize: '20px',
    marginBottom: 1.5,
    width: '360px',
    textAlign: { xs: 'center', sm: 'left' },
  },
  checkboxContainer: {
    display: 'flex',
    justifyContent: { xs: 'center', sm: 'left' },
    alignItems: 'center',
    marginBottom: 0.5,
  },
  checkboxLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  checkboxText: {
    marginTop: 0,
  },
  checkboxesContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginX: { xs: 'auto', sm: 0 },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: { xs: 'center', sm: 'left' },
  },
  button: {
    width: '360px',
    marginTop: 1,
  },
};

const ConceptUser = () => {
  const { id: idCourse } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    if (!state?.tycApproved) navigate(`/courses/${idCourse}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const categoriesForCheckboxes = state?.course?.tariff.map(
    ({ category }) => ({ label: category, checked: false }),
  );
  const [checkboxes, setCheckboxes] = useState(categoriesForCheckboxes || []);

  const disabledButton = useMemo(
    () => !checkboxes.some(({ checked }) => checked),
    [checkboxes],
  );

  const conceptUserSelected = useMemo(
    () => checkboxes.find(({ checked }) => checked)?.label,
    [checkboxes],
  );

  const handleOnClickCheckbox = useCallback((indexCheckboxSelected: number) => {
    const newCheckboxes = checkboxes.map(
      (checkbox, indexMap) => ({ ...checkbox, checked: indexCheckboxSelected === indexMap }),
    );
    setCheckboxes(newCheckboxes);
  }, [checkboxes]);

  const goToNextStep = () => navigate(`/courses/${idCourse}/payments`, { state: { ...state, conceptUser: conceptUserSelected } });

  return (
    <Layout>
      <InscriptionSteps currentStep={2} />
      <Box sx={classes.titleContainer}>
        <Typography variant="h3" color="primary.main" sx={classes.title}>
          ¿En concepto de qué tipo de usuario te vas a inscribir al curso?
        </Typography>
      </Box>
      <Box sx={classes.checkboxesContainer}>
        {checkboxes.map((checkbox, index) => (
          <Box key={checkbox.label} sx={classes.checkboxContainer}>
            <Checkbox checked={checkbox.checked} onClick={() => handleOnClickCheckbox(index)} size="small" />
            <Box sx={classes.checkboxLabel}>
              <Typography variant="h5" color="text.primary" sx={classes.checkboxText}>
                {checkbox.label}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
      <Box sx={classes.buttonContainer}>
        <Button
          variant="contained"
          disabled={disabledButton}
          onClick={goToNextStep}
          sx={classes.button}
        >
          SIGUIENTE
        </Button>
      </Box>
    </Layout>

  );
};

export default React.memo(ConceptUser);
