const classes = {
  title: {
    maxWidth: '340px',
    textAlign: 'center',
  },
  formContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '328px',
    width: '100%',
  },
  sendButton: {
    display: 'flex',
    mt: 1,
    width: '100%',
  },
  goBackButton: {
    color: '#0C4C90',
    fontWeight: 'bold',
    width: 'fit-content',
  },
  goBackButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
};

export default classes;
