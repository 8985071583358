import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import classes from './classes';
import Uploader from './Uploader';

const LoadDropZone = () => (
  <>
    <Uploader />
    <Box sx={classes.infoRow}>
      <Typography fontSize="10px" color="text.secondary">Formatos permitidos: EXE, XLS, XLSX</Typography>
      <Typography fontSize="10px" color="text.secondary">Tamaño máximo: 5mb</Typography>
    </Box>
  </>
);

export default LoadDropZone;
