import { memo } from 'react';
import { Box, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';

import { useCourses } from '../../../contexts/courses/context';
import { formatPrice } from '../../../helpers/payments';
import CourseInterface from '../../../interfaces/courses';

const classes = {
  summaryContainer: {
    padding: { xs: '31px 0px', lg: '31px 26px' },
    bgcolor: { xs: 'background.paper', lg: 'primary.light' },
    width: '296px',
    ml: { xs: '0px', lg: '8px' },
  },
  summaryTitle: {
    mb: { xs: 1.25, lg: 3.25 },
  },
};

interface Props {
  course?: CourseInterface;
}

const PaymentSummary = ({ course } : Props) => {
  const { state: locationState } = useLocation();
  const { getAmount, isPreinscriptionPayment } = useCourses();

  return (
    <Box sx={classes.summaryContainer}>
      <Typography variant="h2" color="primary.main" fontWeight={700} sx={classes.summaryTitle}>
        Total:
      </Typography>
      <Typography variant="h2" color="text.primary" mb={1} fontWeight={600}>
        {formatPrice(getAmount(course))}
      </Typography>
      <Typography variant="h3" color="text.disabled" fontWeight={500}>
        {`${isPreinscriptionPayment() ? 'Preinscripción' : 'Inscripción'} curso "${locationState?.course?.name || course?.name}"`}
      </Typography>
    </Box>
  );
};

export default memo(PaymentSummary);
