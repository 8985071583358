import { SCOPES } from '../../common/constants';
import { ROLES } from '../../helpers/roles';

const PERMISSIONS = {
  [ROLES.STUDENT]: [
    SCOPES.canAccessVirtualCampus,
    SCOPES.canSeeAcademicOffer,
    SCOPES.canViewOwnCertificates,
    SCOPES.canViewOwnCourses,
    SCOPES.canViewOwnEvaluations,
    SCOPES.canViewPaymentsList,
  ],
  [ROLES.TEACHER]: [
    SCOPES.canAccessVirtualCampus,
    SCOPES.canCreateEvaluations,
    SCOPES.canSeeCourseList,
    SCOPES.canSeeEvaluationsList,
    SCOPES.canViewInscriptionsList,
  ],
  [ROLES.ADMIN]: [
    SCOPES.canAccessVirtualCampus,
    SCOPES.canCreateCourses,
    SCOPES.canCreateEvaluations,
    SCOPES.canCreateInscription,
    SCOPES.canCreateLogo,
    SCOPES.canCreateUser,
    SCOPES.canEditCourses,
    SCOPES.canRemoveCourses,
    SCOPES.canRemoveLogo,
    SCOPES.canSeeCourseList,
    SCOPES.canSeeEvaluationsList,
    SCOPES.canSeeEvaluationsList,
    SCOPES.canSeeUsersList,
    SCOPES.canViewInscriptionsList,
    SCOPES.canViewPaymentsList,
  ],
};

export {
  // eslint-disable-next-line import/prefer-default-export
  PERMISSIONS,
};
