import axios from 'axios';

import { axiosErrorHandler, axiosResponseHandler } from './handler';

import PaymentInterface, {
  CreatePaymentInterface,
  FiltersExportReport,
  QueryGetPaymentsInterface,
  ResGetAllPaymentsInterface,
  UpdateInstallmentsInterface,
} from '../interfaces/payments';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/payments`,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true,
});

const downloadPayment = async (
  paymentId: string,
  installmentId: string,
): Promise<Blob> => instance
  .post(`/download/${paymentId}`, { installmentId }, { responseType: 'blob' })
  .then(axiosResponseHandler)
  .catch(axiosErrorHandler);

const getAll = async (
  query?: QueryGetPaymentsInterface,
): Promise<ResGetAllPaymentsInterface> => instance
  .get('/', { params: query })
  .then(axiosResponseHandler)
  .catch(axiosErrorHandler);

const create = async (
  payment: CreatePaymentInterface,
): Promise<PaymentInterface | { redirect: string }> => instance
  .post('/', payment)
  .then(axiosResponseHandler)
  .catch(axiosErrorHandler);

const getById = async (
  id: string,
): Promise<PaymentInterface> => instance
  .get(`/${id}`)
  .then(axiosResponseHandler)
  .catch(axiosErrorHandler);

const update = async (
  id: string,
  paymentData: {
    mpRequest?: object,
    installmentsData?: UpdateInstallmentsInterface[],
  },
): Promise<PaymentInterface> => instance
  .put(`/${id}`, paymentData)
  .then(axiosResponseHandler)
  .catch(axiosErrorHandler);

const handleExport = async (filters?: FiltersExportReport): Promise<Blob> => instance
  .post('/download', filters, { responseType: 'blob' })
  .then(axiosResponseHandler)
  .catch(axiosErrorHandler);

export default {
  create,
  downloadPayment,
  getAll,
  getById,
  handleExport,
  update,
};
