const configurationClasses = {
  buttonsContainer: {
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    gap: 3,
    justifyContent: { xs: 'center', md: 'end' },
    marginTop: '20px',
  },
  certificateContent: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 1,
    marginTop: 2.5,
  },
  checkbox: {
    position: 'absolute' as 'absolute',
    right: 0,
    top: 0,
  },
  courseImageContainer: {
    position: 'relative' as 'relative',
  },
  courseImages: {
    height: '75px',
    width: '150px',
  },
  fileName: {
    fontSize: '10px',
  },
  listContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 4,
    marginTop: 1,
  },
  listImage: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    listStyle: 'none',
    padding: '0px',
    width: '200px',
  },
};

const courseClasses = {
  nameAndEditionCourseContainer: {
    display: 'flex',
    flexDirection: { xs: 'column', sm: 'row' },
    gap: { xs: 0, sm: 6 },
  },
  nameCourseContainer: {
    width: '100%',
    mb: { xs: 3.5, sm: 0 },
  },
  editionCourseContainer: { width: { xs: '100%', sm: '125px' } },
  imageCourseContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    justifyContent: { xs: 'center', sm: 'flex-start' },
  },
  multimediaButton: {
    width: 'fit-content',
  },
  addTeacherContainer: {
    display: { xs: 'flex', sm: 'initial' },
    justifyContent: 'center',
  },
  addTeacherButton: { gap: 0, p: 0 },
  buttonsContainer: {
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    justifyContent: { xs: 'center', md: 'end' },
    gap: 3,
  },
};

const paymentClasses = {
  presinscriptionSwitchContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 2.5,
    justifyContent: { xs: 'center', sm: 'flex-start' },
  },
  addCategoryContainer: {
    display: { xs: 'flex', sm: 'initial' },
    justifyContent: 'center',
  },
  addCategoryButton: { gap: 0, p: 0 },
  buttonsContainer: {
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    justifyContent: { xs: 'center', md: 'end' },
    gap: 3,
  },
};

export {
  configurationClasses,
  courseClasses,
  paymentClasses,
};
