const downloadReportXLSX = (excel: Blob) => {
  const data = typeof excel === 'string'
    ? new Blob([excel], { type: 'application/octet-stream' })
    : excel;

  const excelUrl = URL.createObjectURL(data);
  const a = document.createElement('a');
  a.href = excelUrl;
  a.download = 'AMM_socios_Template.xlsx';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(excelUrl);
};

export default downloadReportXLSX;
