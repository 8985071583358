export const QUALIFICATION_PENDING = 'pending';
export const QUALIFICATION_IN_PROGRESS = 'inProgress';
export const QUALIFICATION_FINALIZED = 'finalized';
export const QUALIFICATION_APPROVED = 'approved';

export const qualificationsDict = {
  [QUALIFICATION_PENDING]: 'Calificación pendiente',
  [QUALIFICATION_IN_PROGRESS]: 'No finalizado',
  [QUALIFICATION_FINALIZED]: 'Finalizado y no aprobado',
  [QUALIFICATION_APPROVED]: 'Finalizado y aprobado',
};
