import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ProfileSubMenu from './ProfileSubMenu';
import { NavbarInterface } from '../../interfaces/layout';
import idhsLogo from '../../assets/idhs_logo_212x86.svg';
import Profile from './Profile';

const classes = {
  container: { width: '100%' },
  appBar: { width: '100%' },
  toolbar: {
    width: '100%',
    display: 'flex',
    flexDirection: { xs: 'row-reverse', sm: 'row' },
    justifyContent: 'space-between',
    px: 3.5,
  },
  hamburgerMenuIconButton: {
    display: { xs: 'block', sm: 'none' },
    visibility: { xs: 'visible', sm: 'hidden' },
  },
  logoContainer: {
    height: '91px',
    pt: '22px',
    display: { sm: 'none' },
  },
  logo: {
    width: '113px',
  },
  profileContainer: {
    display: { xs: 'none', sm: 'block' },
    visibility: { xs: 'hidden', sm: 'visible' },
  },
  buttonLogo: {
    '&:hover': { bgcolor: 'transparent' },
    p: 0,
    minWidth: 'auto',
    height: 'auto',
    display: 'flex',
  },
};

const Navbar = ({ handleDrawerToggle }: NavbarInterface) => {
  const navigate = useNavigate();

  const [profileSubMenuAnchorElNav, setProfileSubMenuAnchorElNav] = useState<null | HTMLElement>(
    null,
  );
  const isProfileSubMenuOpen = Boolean(profileSubMenuAnchorElNav);

  return (
    <AppBar position="static" sx={classes.appBar}>
      <Box sx={classes.container}>
        <Toolbar sx={classes.toolbar}>

          <IconButton
            color="primary"
            aria-label="Abrir menu"
            edge="start"
            onClick={handleDrawerToggle}
            sx={classes.hamburgerMenuIconButton}
          >
            <MenuIcon fontSize="large" />
          </IconButton>

          <Box />

          <Box sx={classes.logoContainer}>
            <Button
              onClick={() => navigate('/courses')}
              sx={classes.buttonLogo}
            >
              <img src={idhsLogo} alt="IDHS" style={classes.logo} />
            </Button>
          </Box>

          <Box sx={classes.profileContainer}>
            <Profile
              openProfileSubMenu={(event) => { setProfileSubMenuAnchorElNav(event.currentTarget); }}
            />
          </Box>

          <ProfileSubMenu
            open={isProfileSubMenuOpen}
            anchorEl={profileSubMenuAnchorElNav}
            handleClose={() => { setProfileSubMenuAnchorElNav(null); }}
          />
        </Toolbar>
      </Box>
    </AppBar>
  );
};

export default React.memo(Navbar);
