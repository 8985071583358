import { OptionInterface } from '../interfaces/inputs';
import jsonSpecialities from '../utils/especialidades.json';
import jsonHospitals from '../utils/sedes.json';

export const documentTypes: OptionInterface[] = [
  { id: 'dni', name: 'DNI' },
  { id: 'le', name: 'LE' },
];

export const phoneTypes: OptionInterface[] = [
  { id: 'landline', name: 'Fijo' },
  { id: 'mobile', name: 'Móvil' },
];

export const specialities: OptionInterface[] = jsonSpecialities.map((speciality) => ({
  id: speciality.Descripcion, name: speciality.Descripcion,
}));

export const hospitals: OptionInterface[] = jsonHospitals.map((hospital) => ({
  id: hospital.Nombre, name: hospital.Nombre,
}));
