const classes = {
  pageTitle: {
    color: 'primary.main',
    fontSize: '32px',
    fontWeight: 700,
    marginRight: 1,
  },
  optionsContainer: {
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    justifyContent: 'center',
    alignItems: 'center',
    gap: 3,
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: '65px',
    pt: 1,
  },
  contentContainer: {
    paddingY: 5,
    paddingX: 7,
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: 3, sm: 6 },
    height: 'auto',
    flexGrow: 1,
  },
  topContainer: {
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    gap: { xs: 3, md: 0 },
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  infiniteScrollTableContainer: {
    '& .payment-status.pending': {
      color: 'warning.main',
    },
    '& .payment-status.cancelled': {
      color: 'error.main',
    },
    '& .payment-status.payed': {
      color: 'success.main',
    },
    '& .payment.disabled': {
      color: 'text.disabled',
    },
  },
  exportButton: {
    width: '152px',
    fontSize: '11px',
    fontWeight: 600,
    lineHeight: '10px',
    gap: 0,
    minWidth: '152px',
    color: 'secondary.main',
    borderColor: 'secondary.main',
  },
  filtersButton: {
    width: '135px',
    fontSize: '11px',
    fontWeight: 600,
    lineHeight: '10px',
    gap: 0,
    minWidth: '135px',
  },
  editPaymentButton: {
    color: 'secondary.main',
    p: 0,
  },
};

export default classes;
