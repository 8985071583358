const classes = {
  mainMessage: {
    color: 'primary.main',
    fontBold: 600,
    fontSize: {
      xs: 14,
      md: 18,
    },
    paddingTop: '10px',
    textAlign: 'center',
  },
  waitMessage: {
    color: 'primary.main',
    fontBold: 500,
    fontSize: {
      xs: 12,
      md: 16,
    },
    textAlign: 'center',
  },
  loadingContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    justifyContent: 'center',
  },
};

export default classes;
