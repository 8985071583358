const loginFormClasses = {
  formContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '328px',
    width: '100%',
  },
  buttonContainer: {
    display: 'flex',
    mt: 1,
    width: '100%',
  },
  forgotPasswordText: {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '8px',
    textDecoration: 'none',
    width: '100%',
  },
  redirectToRegisterText: {
    textDecoration: 'none',
  },
};

const loginClasses = {
  title: {
    textAlign: 'center',
  },
};

export {
  loginClasses,
  loginFormClasses,
};
