import { memo } from 'react';
import { Box, Typography } from '@mui/material';
import MercadoPagoForm from './MercadoPagoForm';
import PaymentSummary from './PaymentSummary';
import CourseInterface from '../../../interfaces/courses';

const classes = {
  selectPaymentWrapper: {
    display: 'flex',
    flexDirection: { xs: 'column-reverse', lg: 'row' },
    width: '100%',
    paddingX: { xs: '20px', sm: 0 },
    alignItems: { xs: 'center', sm: 'start' },
  },
  selectPaymentContainer: {
    maxWidth: { xs: '328px', sm: '100%' },
    width: '100%',
  },
  desktopPaymentSummary: {
    display: { xs: 'none', lg: 'block' },
    visibility: { xs: 'hidden', lg: 'visible' },
  },
  mobilePaymentSummary: {
    display: { xs: 'block', lg: 'none' },
    visibility: { xs: 'visible', lg: 'hidden' },
  },
};

interface Props {
  course?: CourseInterface;
  returnButtonAction: () => void;
}

// Este componente es del viejo form de Mercadopago, el cual es reemplazado
// por la opcion de Pagos Recurrentes con MP. Se deja y no se borra, por si
// se decide reutilizar nuevamente.
const MercadoPagoPayment = ({ course, returnButtonAction }: Props) => (
  <Box sx={classes.selectPaymentWrapper}>
    <Box sx={classes.selectPaymentContainer}>
      <Typography variant="h2" fontWeight={700} color="primary.main">
        Pago con tarjeta de Crédito/Débito
      </Typography>

      <Box sx={classes.mobilePaymentSummary}>
        <PaymentSummary course={course} />
      </Box>

      <Box my={1.25}>
        <Typography variant="h3" display="inline" fontWeight={500}>
          Introducí los datos de tu tarjeta para realizar el pago.
        </Typography>
      </Box>

      <MercadoPagoForm
        course={course}
        returnButtonAction={returnButtonAction}
      />
    </Box>
    <Box sx={classes.desktopPaymentSummary}>
      <PaymentSummary course={course} />
    </Box>
  </Box>
);

export default memo(MercadoPagoPayment);
