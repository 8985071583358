import { ReactNode } from 'react';

import CourseInterface from './courses';
import UserInterface from './user';

export type TypeEnrollment = 'wait' | 'enroll' | 'cancel';

export enum DiscountTypes {
  Fixed = 'fixed',
  Percentage = 'percentage',
}

export interface QueryGetEnrollmentsInterface {
  course?: string;
  internalCourseId?: string;
  limit?: number;
  moreInfo?: boolean;
  page?: number;
  qualification?: string;
  search?: string;
  state?: string;
  user?: string;
}

export interface RowInterface {
  id: string;
  studentId: string;
  studentName: string;
  document: string;
  courseEnrolled: string;
  startInscriptionDate: string;
  enrollmentStatus: string;
  removeEnrollment: ReactNode,
}

export interface RowQualificationInterface {
  id: string;
  studentId: string;
  studentName: string;
  document: string;
  courseEnrolled: string;
  qualification: string;
  editQualification: ReactNode,
}

export interface EnrollmentInterface {
  _id: string;
  course: CourseInterface;
  discount?: number;
  discountType: DiscountTypes;
  historyStates: {
    type: string;
    date: string;
  }[];
  id: string;
  lastState: {
    type: string;
    date: string;
  };
  observations?: string;
  qualification: string;
  user: UserInterface;
  userCategory?: string;
}

export interface RowSelectedInterface {
  courseId: string;
  studentId: string;
  type: string;
}

export interface GenericResponseEnrollment {
  enrollmentId: string;
}

export interface ResGetEnrollmentsInterface {
  enrollments: EnrollmentInterface[];
  page: number;
  total: number;
}

export interface CreateEnrollmentInterface {
  course: string;
  discount?: number;
  discountType: string;
  observations?: string;
  userCategory?: string;
  userDocument?: string;
  userEmail: string;
}

export interface UpdateEnrollmentInterface {
  state: string;
  qualification: string;
}
