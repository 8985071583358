import { useAuth } from '../../contexts/auth/context';

import { PERMISSIONS } from './constants';

import {
  HasPermissionsProps,
  PermissionsGateProps,
  ScopeDictionaryProps,
} from '../../interfaces/auth';

const hasPermission = ({ permissions, scopes }: HasPermissionsProps): boolean => {
  const scopesMap: ScopeDictionaryProps = {};
  scopes.forEach((scope: string) => { scopesMap[scope] = true; });

  return permissions.some((permission) => scopesMap[permission]);
};

const PermissionsGate = ({ children, scopes = [] }: PermissionsGateProps) => {
  const { state } = useAuth();
  const { role } = state.auth;

  const permissions = PERMISSIONS[role as string];
  const permissionGranted = hasPermission({ permissions, scopes });

  return !permissionGranted ? <div /> : children;
};

export default PermissionsGate;
