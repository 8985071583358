import React from 'react';
import { Controller } from 'react-hook-form';
import {
  FormControl,
  Switch,
  FormControlLabel,
} from '@mui/material';

import { ControlledSwitchProps } from '../../interfaces/inputs';

const ControlledSwitch = ({
  control,
  fieldName,
  fieldLabel,
  onChange = null,
  disabled = false,
}: ControlledSwitchProps) => (
  <Controller
    control={control}
    name={fieldName}
    defaultValue={false}
    render={({ field, fieldState: { error } }) => (
      <FormControl
        variant="standard"
        error={!!error}
      >
        <FormControlLabel
          checked={field.value}
          control={(
            <Switch
              name={fieldName}
              checked={field.value}
              onChange={(event, checked) => {
                if (typeof onChange === 'function') onChange(event, checked);
                field.onChange(event);
              }}
              size="small"
              disabled={disabled}
            />
)}
          label={fieldLabel}
        />
      </FormControl>
    )}
  />
);

export default React.memo(ControlledSwitch);
