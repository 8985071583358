import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, IconButton, Typography } from '@mui/material';
import {
  Clear as ClearIcon,
  Filter as FilterIcon,
} from '@mui/icons-material';

import { useConfiguration } from '../../contexts/logos/context';
import { useMessageModal } from '../../contexts/messageModal/context';

import Layout from '../../components/Layouts/Layout';
import Upload from '../../components/Course/Upload';

import { TLogo } from '../../interfaces/logos';

import classes from './classes';

const Configuration = () => {
  const { createLogo, fetchLogos, removeLogo } = useConfiguration();
  const { openMessageModal } = useMessageModal();
  const {
    formState,
    handleSubmit,
    setValue,
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      logos: [],
    },
  });
  const [uploadedLogos, setUploadedLogos] = useState<TLogo[]>([]);
  const logos = watch('logos');

  const getLogos = async () => {
    const fetchLogosResponse = await fetchLogos();

    if (fetchLogosResponse) {
      setUploadedLogos(fetchLogosResponse.logos);
    }
  };

  const addLogo = async () => {
    const formData = new FormData();

    logos.forEach((file) => {
      formData.append('logos', file, file.name);
    });

    const createLogoResponse = await createLogo(formData);

    if (createLogoResponse) {
      setValue('logos', []);
      getLogos();
    }
  };

  const deleteLogo = async (logoId: string) => {
    openMessageModal({
      title: 'Confirmación de eliminación',
      variant: 'infoError',
      message: '¿Estás seguro/a de que deseas eliminar este logo? Si lo haces, se eliminará de todos los certificados y cursos donde esté configurado.',
      primaryButton: {
        text: 'Sí, eliminar',
        onClick: async () => {
          await removeLogo(logoId);
          getLogos();
        },
      },
      secondaryButton: {
        text: 'Cancelar',
      },
      centerText: true,
    });
  };

  useEffect(() => {
    getLogos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <Box
        autoComplete="off"
        component="form"
        noValidate
        onSubmit={handleSubmit(addLogo)}
      >
        <Upload
          allowedFormatsHelper="PNG"
          files={logos}
          isOnSaveDisabled={!formState.isValid || !logos.length}
          name="logos"
          plural="logos"
          setValue={setValue}
          suggestedSize="150px por 75px"
          title="Configuración"
        />
        <Box sx={classes.removeLogoContainer}>
          {uploadedLogos.map((logo) => (
            <Box key={logo.id} sx={classes.removeLogo}>
              <FilterIcon fontSize="small" color="primary" />
              <Typography sx={classes.removeLogoOriginalName}>{logo.originalName}</Typography>
              <IconButton size="small" onClick={() => deleteLogo(logo.id)}>
                <ClearIcon />
              </IconButton>
            </Box>
          ))}
        </Box>
      </Box>
    </Layout>
  );
};

export default Configuration;
