import { useState, useRef } from 'react';
import Box from '@mui/material/Box';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Controller } from 'react-hook-form';
import { FormControl, FormHelperText } from '@mui/material';

import { ControlledTextFieldProps } from '../../interfaces/inputs';

interface Prop extends ControlledTextFieldProps {
  height?: number
}

const ControlledTextEditor = ({
  control,
  fieldName,
  rules,
  height = 175,
}: Prop) => {
  const [heightCalculated, setHeightCalculated] = useState<number>(height - 44);
  const quillRef = useRef<ReactQuill | null>(null);

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ align: ['', 'right', 'center', 'justify'] }],
      [{ list: 'bullet' }, { list: 'ordered' }],
      [{ background: ['red', 'black'] }],
      [{ header: [1, 2, false] }],
    ],
  };

  return (
    <Controller
      control={control}
      name={fieldName}
      rules={rules}
      defaultValue=""
      render={({ field, fieldState: { error } }) => (
        <FormControl
          sx={{ textAlign: 'start' }}
          fullWidth
          variant="standard"
          error={!!error}
        >
          <Box sx={{ width: '100%', height: `${height}px` }}>
            <Box sx={{ width: '100%', height: `${heightCalculated}px` }}>
              <ReactQuill
                ref={quillRef}
                value={field.value}
                onChange={(e) => field.onChange(e)}
                modules={modules}
                style={{ height: '100%', fontFamily: ['Inter', 'sans-serif'].join(',') }}
              />
            </Box>
          </Box>
          {error && (
          <FormHelperText color="error">
            {error.message}
          </FormHelperText>
          )}
        </FormControl>
      )}
    />

  );
};

export default ControlledTextEditor;
