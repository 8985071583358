import dayjs from 'dayjs';
import CourseInterface from '../interfaces/courses';
import { CoursesFromUserFormattedInterface, CoursesFromUserInterface } from '../interfaces/user';

const dictStatesCourse = {
  TO_START: 'to start',
  FINALIZED: 'finalized',
  IN_PROGRESS: 'in progress',
};

const getCourseDateStatus = (startDate: string, endDate: string) => {
  const now = dayjs();
  if (endDate && now.isAfter(endDate.split('/').reverse().join('/'))) return dictStatesCourse.FINALIZED;
  if (now.isBefore(startDate.split('/').reverse().join('/'))) return dictStatesCourse.TO_START;
  return dictStatesCourse.IN_PROGRESS;
};

const orderStates = {
  [dictStatesCourse.IN_PROGRESS]: 0,
  [dictStatesCourse.TO_START]: 1,
  [dictStatesCourse.FINALIZED]: 2,
};

const formatCourse = (course: CoursesFromUserInterface): CoursesFromUserFormattedInterface => {
  const courseDateStatus = getCourseDateStatus(course.start_date, course.end_date);
  return {
    ...course,
    dateState: courseDateStatus,
    orderDateState: orderStates[courseDateStatus],
  };
};

export {
  formatCourse,
  dictStatesCourse,
};
