import {
  Box,
  Button,
  IconButton,
  Modal,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import ControlledTextField from '../../Inputs/ControlledTextField';

import rulesConstants from '../../../helpers/rulesConstants';

import { EditPaymentEmail } from '../../../interfaces/modals';

import classes from './classes';

const EditPaymentEmailModal = ({
  control,
  isOpen,
  onClick,
  onClose = () => { },
}: EditPaymentEmail) => (
  <Modal
    open={isOpen}
    sx={classes.modal}
  >
    <Box sx={classes.modalContainer}>
      <Box sx={classes.closeButtonContainer}>
        <IconButton onClick={onClose} size="large" sx={classes.closeIconButton}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box sx={classes.messageContainer}>
        <Typography align="center" color="primary.main" fontSize={28} mb={1} variant="h1">
          Por favor, ingresa otro email
        </Typography>
        <Typography align="center" color="text.primary" mb={1} variant="h5">
          Mercado Pago detectó un problema con el email vinculado a tu usuario,
          ingresa un email diferente para poder avanzar.
          Este dato se va a utilizar solo como información de pago.
        </Typography>
        <ControlledTextField
          control={control}
          fieldName="paymentEmail"
          fieldLabel="Email"
          rules={rulesConstants.emailTextfield({ fieldLabel: 'Email' })}
        />
        <Button onClick={onClick} sx={{ mt: 3 }} variant="contained">
          Aceptar
        </Button>
      </Box>
    </Box>
  </Modal>
);

export default EditPaymentEmailModal;
