const drawerWidth = '192px';

const sideBarClasses = {
  drawer: {
    display: { xs: 'none', sm: 'block' },
    flexShrink: 0,
    overflowX: 'hidden',
    overflowY: 'auto',
    width: drawerWidth,
    '& .MuiDrawer-paper': {
      boxSizing: 'border-box',
      width: drawerWidth,
    },
  },
  mobileDrawer: {
    display: { xs: 'block', sm: 'none' },
    width: '233px',
    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '233px' },
  },
  logoContainer: {
    height: '91px',
    pt: '22px',
  },
  logo: {
    width: '123px',
  },
  list: {
    mt: 4,
    p: 0,
    width: '123px',
  },
  subList: {
    py: 2,
  },
  listItem: {
    py: 1,
  },
  listItemButton: {
    p: 0,
    pl: 1.25,
  },
  listItemTextTitle: {
    fontSize: '14px',
    fontWeight: 700,
  },
  listItemTextSubItem: {
    fontSize: '14px',
    fontWeight: 400,
  },
  profileContainer: {
    background: 'background',
    display: 'flex',
    flexDirection: 'column',
    px: 2.5,
    py: 2.75,
    width: '100%',
  },
  buttonLogo: {
    '&:hover': { bgcolor: 'transparent' },
    display: 'flex',
    height: 'auto',
    minWidth: 'auto',
    p: 0,
  },
};

const profileSubMenuClasses = {
  profileSubMenu: {
    borderRadius: '4px',
    height: 'auto',
    width: '231px',
  },
  userDataContainer: {
    alignItems: 'start',
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
  },
  divider: {
    my: 0.5,
  },
};

export {
  profileSubMenuClasses,
  sideBarClasses,
};
