import axios from 'axios';
import CourseInterface, { QueryGetCourses, ResGetCourses } from '../interfaces/courses';
import { axiosErrorHandler, axiosResponseHandler } from './handler';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/courses`,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true,
});

const instanceForm = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/courses`,
  headers: { 'Content-Type': 'multipart/form-data' },
  withCredentials: true,
});

interface GetByIdInterface {
  course: CourseInterface,
  otherCourses: CourseInterface[]
}

const getAll = async (query?: QueryGetCourses): Promise<ResGetCourses> => instance
  .get('/', { params: query })
  .then(axiosResponseHandler)
  .catch(axiosErrorHandler);

const getPublished = async (query?: QueryGetCourses): Promise<CourseInterface[]> => instance
  .get('/?courseType=published', { params: query })
  .then(axiosResponseHandler)
  .catch(axiosErrorHandler);

const getByIdAndOtherCourses = async (
  id: string,
): Promise<GetByIdInterface> => instance
  .get(`/${id}`)
  .then(axiosResponseHandler)
  .catch(axiosErrorHandler);

const getById = async (
  id: string,
): Promise<CourseInterface> => instance
  .get(`/${id}`)
  .then(axiosResponseHandler)
  .catch(axiosErrorHandler);

const create = async (
  course: FormData,
): Promise<CourseInterface> => instanceForm
  .post('/', course)
  .then(axiosResponseHandler)
  .catch(axiosErrorHandler);

const update = async (
  id: string,
  course: FormData,
): Promise<CourseInterface> => instanceForm
  .put(`/${id}`, course)
  .then(axiosResponseHandler)
  .catch(axiosErrorHandler);

const remove = async (
  id: string,
): Promise<CourseInterface> => instance
  .delete(`/${id}`)
  .then(axiosResponseHandler)
  .catch(axiosErrorHandler);

const exportCourse = async (
  id: string,
): Promise<CourseInterface> => instance
  .post(`/${id}/moodle-export`)
  .then(axiosResponseHandler)
  .catch(axiosErrorHandler);

export default {
  getAll,
  getByIdAndOtherCourses,
  create,
  update,
  remove,
  getPublished,
  getById,
  exportCourse,
};
