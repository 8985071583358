const classes = {
  pageTitle: {
    color: 'primary.main',
    fontSize: '32px',
    fontWeight: 700,
    marginRight: { md: 1, lg: 0 },
  },
  optionsContainer: {
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    justifyContent: 'center',
    alignItems: 'center',
    gap: 3,
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: '65px',
    pt: 1,
  },
  contentContainer: {
    paddingY: 5,
    paddingX: 7,
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: 3, sm: 6 },
    height: 'auto',
    flexGrow: 1,
  },
  topContainer: {
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    gap: { xs: 3, md: 0 },
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  seeCourseButton: {
    color: 'secondary.main',
    p: 0,
    minWidth: 'auto',
    textTransform: 'none',
    height: 'auto',
  },
  chip: {
    borderRadius: 0,
    color: 'white',
    letterSpacing: '1.65px',
    paddingX: 0.5,
  },
};

export default classes;
