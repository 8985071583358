import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { Add as AddIcon, Help as HelpIcon } from '@mui/icons-material';

import ControlledAutocomplete from '../Inputs/ControlledAutocomplete';
import ControlledDateField from '../Inputs/ControlledDateField';
import ControlledFileUpload from '../Inputs/ControlledFileUpload';
import ControlledSelect from '../Inputs/ControlledSelect';
import ControlledSwitch from '../Inputs/ControlledSwitch';
import ControlledTextEditor from '../Inputs/ControlledTextEditor';
import ControlledTextField from '../Inputs/ControlledTextField';
import Upload from './Upload';

import { CreateCourseFormInterface, Modality, TeacherCategory } from '../../interfaces/courses';
import { CourseFormProps } from './types';

import rulesConstants from '../../helpers/rulesConstants';
import { specialities } from '../../helpers/optionsInput';

import { formatToValidDayjs } from '../../utils/date';

import {
  defaultTeacher,
  defaultVideo,
  optionsModality,
  optionsRoleTeacher,
  tooltipEmailTeacher,
  tooltipMoodle,
  tooltipVideo,
} from './constants';

import { courseClasses as classes } from './classes';

const CourseForm = ({ onSubmit, course }: CourseFormProps) => {
  const navigate = useNavigate();
  const {
    clearErrors,
    control,
    formState,
    handleSubmit,
    reset,
    setError,
    setValue,
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues: useMemo(() => ({
      name: '',
      edition: '',
      images: [],
      banner: null,
      description: '',
      objectives: '',
      program: '',
      teachers: [defaultTeacher],
      videos: [defaultVideo],
      modality: Modality.Presential,
      moodle: false,
      start_publication_date: dayjs(),
      end_publication_date: dayjs().add(5, 'day'),
      start_inscription_date: dayjs().add(1, 'day'),
      end_inscription_date: dayjs().add(2, 'day'),
      start_date: dayjs().add(3, 'day'),
      end_date: dayjs().add(4, 'day'),
      limit_vacancies: '0',
      specialities: [],
      lecture_hours: '0',
    }), []),
  });
  const [openUploadImagesModal, setOpenUploadImagesModal] = useState(false);
  const courseImages = watch('images');
  const { fields: teachers, append: appendTeacher } = useFieldArray({
    control,
    name: 'teachers',
  });

  const { fields: videos, append: appendVideo } = useFieldArray({
    control,
    name: 'videos',
  });

  const addTeacher = useCallback(() => appendTeacher({
    category: TeacherCategory.Docente,
    email: '',
    name: '',
    signature: [],
  }), [appendTeacher]);

  const addVideo = useCallback(() => {
    if (videos.length === 5) return;
    appendVideo({
      link: '',
    });
  }, [appendVideo, videos]);

  useEffect(() => {
    if (course) {
      reset({
        name: course.name,
        edition: course.edition || undefined,
        images: course.images,
        banner: course.banner,
        description: course.description,
        objectives: course.objectives,
        program: course.program,
        teachers: course.teachers.map((teacher) => ({
          category: teacher.category,
          email: teacher.email,
          name: teacher.name,
          signature: teacher.signature,
        })) || [defaultTeacher],
        videos: course.videos.length ? course.videos
          .map((video) => ({ link: video })) : [defaultVideo],
        modality: course.modality,
        moodle: course.moodle,
        start_publication_date: dayjs(formatToValidDayjs(course.start_publication_date)),
        end_publication_date: dayjs(formatToValidDayjs(course.end_publication_date)),
        start_inscription_date: dayjs(formatToValidDayjs(course.start_inscription_date)),
        end_inscription_date: dayjs(formatToValidDayjs(course.end_inscription_date)),
        start_date: dayjs(formatToValidDayjs(course.start_date)),
        end_date: dayjs(formatToValidDayjs(course.end_date)),
        limit_vacancies: String(course.limit_vacancies),
        specialities: course.specialities,
        lecture_hours: course.lecture_hours,
      });
    }
  }, [course, reset]);

  const validateFieldsOnSubmit = (courseOnSubmit: CreateCourseFormInterface) => {
    clearErrors();
    let isThereError = false;
    courseOnSubmit.teachers.forEach((teacher, index) => {
      const emailsWithoutSpecificEmail = [
        ...courseOnSubmit.teachers.slice(0, index),
        ...(index < courseOnSubmit.teachers.length && courseOnSubmit.teachers.slice(index + 1)),
      ].map(({ email }) => email);

      if (emailsWithoutSpecificEmail.includes(teacher.email)) {
        setError(
          `teachers.${index}.email`,
          { type: 'duplicate', message: 'No pueden haber emails duplicados' },
        );
        isThereError = true;
      }
    });
    if (isThereError) return;
    clearErrors();

    onSubmit(courseOnSubmit);
  };

  return (
    <>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(validateFieldsOnSubmit)}
      >
        <Grid container direction="column" mb={8}>
          <Grid item mb={3.5}>
            <Box sx={classes.nameAndEditionCourseContainer}>
              <Box sx={classes.nameCourseContainer}>
                <ControlledTextField
                  control={control}
                  fieldLabel="Nombre del curso"
                  fieldName="name"
                  required
                  rules={rulesConstants.textfield({ fieldLabel: 'Nombre del curso' })}
                />
              </Box>
              <Box sx={classes.editionCourseContainer}>
                <ControlledTextField
                  control={control}
                  fieldName="edition"
                  fieldLabel="Edición"
                />
              </Box>
            </Box>
          </Grid>
          {course && (
            <Typography variant="h5" mb={3.5}>
              {`ID Interno: ${course.internalCourseId}`}
            </Typography>
          )}
          <Grid item mb={8.5}>
            <Typography
              variant="h5"
              lineHeight="16.94px"
              mb={4}
            >
              Multimedia
            </Typography>
            <Grid item mb={3} sx={classes.imageCourseContainer}>
              <Button
                sx={classes.multimediaButton}
                onClick={() => setOpenUploadImagesModal(!openUploadImagesModal)}
                variant="outlined"
              >
                {course ? 'EDITAR FOTOS' : 'ADJUNTAR FOTOS'}
              </Button>
              <Typography variant="subtitle2">
                {!courseImages.length && 'Obligatorio.'}
              </Typography>
            </Grid>
            <Grid item mb={3}>
              <ControlledFileUpload
                control={control}
                fieldName="banner"
                label={course ? 'Editar banner' : 'Adjuntar banner'}
                hint="Obligatorio. Tamaño sugerido: 1440px por 450px"
                responsive
                rules={rulesConstants.fileUpload()}
              />
            </Grid>
            {videos.map((video, index) => (
              <Grid key={`video-${video.id}`} container columnSpacing={6} rowSpacing={4} mb={3}>
                <Grid item xs={12} sm={6}>
                  <ControlledTextField
                    control={control}
                    fieldName={`videos[${index}].link`}
                    fieldLabel="Video"
                    placeholder="Link de YouTube / Vimeo (opcional)"
                    endAdornment={(
                      <Tooltip disableFocusListener title={tooltipVideo} placement="right">
                        <IconButton color="primary" aria-label="upload video" component="label" size="small" sx={{ p: 0, mb: 0.25 }}>
                          <HelpIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    )}
                    rules={rulesConstants.videoTextfield({ fieldLabel: 'Link' })}
                  />
                </Grid>
              </Grid>
            ))}
            <Box sx={classes.addTeacherContainer}>
              <Button
                startIcon={<AddIcon />}
                sx={classes.addTeacherButton}
                onClick={addVideo}
              >
                AGREGAR VIDEO
              </Button>
            </Box>
          </Grid>
          <Grid item mb={6}>
            <Typography
              variant="h5"
              lineHeight="16.94px"
              mb={2}
            >
              Descripción *
            </Typography>
            <ControlledTextEditor
              control={control}
              fieldName="description"
              fieldLabel="Descripción"
              rules={rulesConstants.textfield({ fieldLabel: 'Descripción' })}
            />
          </Grid>
          <Grid item mb={6}>
            <Typography
              variant="h5"
              lineHeight="16.94px"
              mb={2}
            >
              Objetivos *
            </Typography>
            <ControlledTextEditor
              control={control}
              fieldName="objectives"
              fieldLabel="Objetivos"
              rules={rulesConstants.textfield({ fieldLabel: 'Objetivos' })}
            />
          </Grid>
          <Grid item mb={10.5}>
            <Typography
              variant="h5"
              lineHeight="16.94px"
              mb={2}
            >
              Programa *
            </Typography>
            <ControlledTextEditor
              control={control}
              fieldName="program"
              fieldLabel="Programa"
              rules={rulesConstants.textfield({ fieldLabel: 'Programa' })}
            />
          </Grid>
          <Grid item mb={8.5}>
            <Typography
              variant="h5"
              lineHeight="16.94px"
              mb={4}
            >
              Docentes
            </Typography>
            {teachers.map((teacher, index) => (
              <Grid key={`teacher-${teacher.id}`} container columnSpacing={6} rowSpacing={4} mb={3}>
                <Grid item xs={12} sm={6}>
                  <ControlledSelect
                    control={control}
                    fieldName={`teachers[${index}].category`}
                    fieldLabel="Rol *"
                    options={optionsRoleTeacher}
                    rules={rulesConstants.select({ fieldLabel: 'Rol' })}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ControlledTextField
                    control={control}
                    fieldLabel="Nombre y Apellido"
                    fieldName={`teachers[${index}].name`}
                    required
                    rules={rulesConstants.textfield({ fieldLabel: 'Nombre y Apellido' })}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ControlledTextField
                    control={control}
                    endAdornment={(
                      <Tooltip disableFocusListener title={tooltipEmailTeacher} placement="right">
                        <IconButton color="primary" aria-label="Debe introducir un email válido." component="label" size="small" sx={{ p: 0, mb: 0.25 }}>
                          <HelpIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    )}
                    fieldLabel="Email"
                    fieldName={`teachers[${index}].email`}
                    required
                    rules={rulesConstants.emailTextfield({ fieldLabel: 'Email' })}
                  />
                </Grid>
                <Grid item mb={3} sx={classes.imageCourseContainer}>
                  <ControlledFileUpload
                    control={control}
                    fieldName={`teachers[${index}].signature`}
                    label={teacher.signature ? 'EDITAR FIRMA' : 'ADJUNTAR FIRMA'}
                    hint="Tamaño sugerido: 120px por 100px"
                    responsive
                  />
                  <Typography variant="subtitle2">
                    {!courseImages.length && 'Obligatorio.'}
                  </Typography>
                </Grid>
              </Grid>
            ))}
            <Box sx={classes.addTeacherContainer}>
              <Button
                startIcon={<AddIcon />}
                sx={classes.addTeacherButton}
                onClick={addTeacher}
              >
                AGREGAR DOCENTE
              </Button>
            </Box>
          </Grid>
          <Grid item mb={8.5}>
            <Grid container columnSpacing={6} rowSpacing={4} mb={3}>
              <Grid item xs={12} sm={6}>
                <ControlledSelect
                  control={control}
                  fieldName="modality"
                  fieldLabel="Modalidad *"
                  options={optionsModality}
                  rules={rulesConstants.select({ fieldLabel: 'Modalidad' })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container alignContent="end" alignItems="center">
                  <Grid item mr={0.5}>
                    <Typography
                      variant="h5"
                      lineHeight="16.94px"
                    >
                      Moodle
                    </Typography>
                  </Grid>
                  <Grid item mr={2.5}>
                    <Tooltip disableFocusListener title={tooltipMoodle}>
                      <IconButton color="primary" aria-label="upload picture" component="label" size="small" sx={{ p: 0, mb: 0.25 }}>
                        <HelpIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <ControlledSwitch
                      control={control}
                      fieldName="moodle"
                      fieldLabel={watch('moodle') ? 'Si' : 'No'}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ControlledTextField
                  control={control}
                  fieldLabel="Cupo máximo de alumnos/as"
                  fieldName="limit_vacancies"
                  required
                  rules={rulesConstants.numericFieldValidation({ fieldLabel: 'Cupo máximo de alumnos/as' })}
                  type="number"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <ControlledTextField
                  control={control}
                  fieldLabel="Horas cátedra"
                  fieldName="lecture_hours"
                  required
                  rules={rulesConstants.numericFieldValidation({ fieldLabel: 'Horas cátedra' })}
                  type="number"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <ControlledAutocomplete
                  control={control}
                  fieldLabel="Especialidades *"
                  fieldName="specialities"
                  multiple
                  options={specialities}
                  rules={rulesConstants.select({ fieldLabel: 'Especialidades' })}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item mb={3}>
            <Typography
              variant="h5"
              lineHeight="16.94px"
              mb={2}
            >
              Fecha de publicación *
            </Typography>
            <Grid container columnSpacing={6} rowSpacing={4} mb={3}>
              <Grid item xs={12} sm={6}>
                <ControlledDateField
                  control={control}
                  fieldName="start_publication_date"
                  fieldLabel="Desde"
                  rules={rulesConstants.datefield({ fieldLabel: 'Fecha de inicio de publicación' })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <ControlledDateField
                  control={control}
                  fieldName="end_publication_date"
                  fieldLabel="Hasta"
                  rules={rulesConstants.datefield({ fieldLabel: 'Fecha de fin de publicación' })}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item mb={3}>
            <Typography
              variant="h5"
              lineHeight="16.94px"
              mb={2}
            >
              Fecha de inscripción *
            </Typography>
            <Grid container columnSpacing={6} rowSpacing={4} mb={3}>
              <Grid item xs={12} sm={6}>
                <ControlledDateField
                  control={control}
                  fieldName="start_inscription_date"
                  fieldLabel="Desde"
                  rules={rulesConstants.datefield({ fieldLabel: 'Fecha de inicio de publicación' })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <ControlledDateField
                  control={control}
                  fieldName="end_inscription_date"
                  fieldLabel="Hasta"
                  rules={rulesConstants.datefield({ fieldLabel: 'Fecha de fin de publicación' })}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Typography
              variant="h5"
              lineHeight="16.94px"
              mb={2}
            >
              Fecha de cursada *
            </Typography>
            <Grid container columnSpacing={6} rowSpacing={4} mb={3}>
              <Grid item xs={12} sm={6}>
                <ControlledDateField
                  control={control}
                  fieldName="start_date"
                  fieldLabel="Desde"
                  rules={rulesConstants.datefield({ fieldLabel: 'Fecha de inicio de publicación' })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <ControlledDateField
                  control={control}
                  fieldName="end_date"
                  fieldLabel="Hasta"
                  rules={rulesConstants.datefield({ fieldLabel: 'Fecha de fin de publicación' })}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box sx={classes.buttonsContainer}>
          <Button
            variant="contained"
            disabled={!(formState.isValid && !!courseImages.length)}
            type="submit"
            color="primary"
          >
            Continuar
          </Button>
          <Button variant="outlined" color="error" onClick={() => navigate('/courses')}>
            Cancelar
          </Button>
        </Box>
      </Box>
      <Upload
        files={courseImages}
        isModal
        isOpen={openUploadImagesModal}
        onClose={() => setOpenUploadImagesModal(false)}
        setValue={setValue}
      />
    </>
  );
};

export default React.memo(CourseForm);
