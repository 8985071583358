import React, { useMemo } from 'react';
import {
  Modal, Box, Typography, Button, IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Variant } from '@mui/material/styles/createTypography';
import { MessageModalProps } from '../../../interfaces/modals';
import colors from '../../../core-ui/colors';

const commonAttributesModal = {
  primaryButtonColor: null,
  secondaryButtonColor: null,
  titleColor: null,
  messageColor: null,
  closeButtonColor: null,
  fontWeight: 600,
  descriptionVariant: 'h3',
  maxWidthContainer: '570px',
  maxWidthMessageContainer: '420px',
  alignText: 'center',
};

const commonAttributesInfoModal = {
  bgColor: 'background.paper',
  secondaryButtonColor: 'primary',
  titleColor: 'primary.main',
  messageColor: 'text.primary',
  closeButtonColor: 'primary.main',
  fontWeight: null,
  descriptionVariant: 'h5',
  maxWidthContainer: '673px',
  maxWidthMessageContainer: '567px',
  alignText: 'left',
};

const variantToPropsDictionary = {
  error: {
    bgColor: 'error.main',
    ...commonAttributesModal,
  },
  warning: {
    bgColor: colors.warningYellow,
    ...commonAttributesModal,
  },
  success: {
    bgColor: 'success.main',
    ...commonAttributesModal,
  },
  info: {
    primaryButtonColor: 'primary',
    ...commonAttributesInfoModal,
  },
  infoError: {
    primaryButtonColor: 'error',
    ...commonAttributesInfoModal,
  },
};

const classes = {
  modal: {
    zIndex: 4100,
  },
  modalContainer: (variant: string) => ({
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: variantToPropsDictionary[variant].bgColor,
    p: 2,
    boxShadow: '0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12)',
    borderRadius: '0px',
    padding: '16px 16px 48px',
    maxWidth: variantToPropsDictionary[variant].maxWidthContainer,
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  closeButtonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
  },
  closeIconButton: (variant: string) => ({
    padding: 0,
    color: variantToPropsDictionary[variant].closeButtonColor || 'background.paper',
  }),
  messageContainer: (variant: string) => ({
    mt: 4,
    textAlign: 'center',
    maxWidth: variantToPropsDictionary[variant].maxWidthMessageContainer,
  }),
  buttonsContainer: {
    display: 'flex',
    gap: 3,
    width: '100%',
    justifyContent: 'center',
  },
};

const MessageModal = ({
  open,
  setOpen,
  onClose = () => setOpen(false),
  variant,
  title,
  message,
  secondaryButton,
  primaryButton,
  closeButton,
  centerText,
}: MessageModalProps) => {
  const onClickPrimaryButton = () => {
    if (primaryButton?.onClick !== undefined) {
      primaryButton.onClick();
    }
    onClose();
  };

  const onClickSecondaryButton = () => {
    if (secondaryButton?.onClick !== undefined) {
      secondaryButton.onClick();
    }
    onClose();
  };

  const onClickCloseButton = () => {
    if (closeButton !== undefined && closeButton !== null) {
      closeButton();
    }
    onClose();
  };

  const showCloseButton = useMemo(() => closeButton !== null, [closeButton]);

  return (
    <Modal
      open={open}
      aria-labelledby={title}
      aria-describedby={title}
      sx={classes.modal}
    >
      <Box sx={classes.modalContainer(variant)}>
        { showCloseButton && (
        <Box sx={classes.closeButtonContainer}>
          <IconButton size="large" onClick={onClickCloseButton} sx={classes.closeIconButton(variant)}>
            <CloseIcon />
          </IconButton>
        </Box>
        )}
        <Box sx={classes.messageContainer(variant)}>
          <Typography
            variant="h1"
            color={variantToPropsDictionary[variant].titleColor || 'background.paper'}
            align={centerText ? 'center' : variantToPropsDictionary[variant].alignText as 'center' | 'left'}
            mb={1}
          >
            {title}
          </Typography>
          <Typography
            variant={variantToPropsDictionary[variant].descriptionVariant as Variant}
            color={variantToPropsDictionary[variant].messageColor || 'background.paper'}
            fontWeight={variantToPropsDictionary[variant].fontWeight}
            align={centerText ? 'center' : variantToPropsDictionary[variant].alignText as 'center' | 'left'}
            mb={2}
          >
            {message}
          </Typography>
          { (primaryButton || secondaryButton) && (
          <Box sx={classes.buttonsContainer}>
            { primaryButton && (
            <Button
              variant="contained"
              fullWidth
              color={variantToPropsDictionary[variant].primaryButtonColor || 'info'}
              onClick={onClickPrimaryButton}
            >
              {primaryButton.text || 'Aceptar'}
            </Button>
            )}
            { secondaryButton && (
            <Button
              variant="outlined"
              fullWidth
              color={variantToPropsDictionary[variant].secondaryButtonColor || 'info'}
              onClick={onClickSecondaryButton}
            >
              {secondaryButton.text || 'Cancelar'}
            </Button>
            )}
          </Box>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default React.memo(MessageModal);
