import { memo, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Grid, Typography } from '@mui/material';

import { useCourses } from '../../contexts/courses/context';

import LoadingComponent from '../../components/Controls/LoadingComponent';
import Layout from '../../components/Layouts/Layout';
import CourseCard from '../../components/CourseCard';
import ControlledSelect from '../../components/Inputs/ControlledSelect';

import { optionsSpecialty } from './constants';

import { coursesClasses as classes } from './classes';

const Courses = () => {
  const [hasComponentLoaded, setHasComponentLoaded] = useState(false);
  const { fetchCourses } = useCourses();
  const {
    control,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      specialty: '0',
      courses: [],
    },
  });

  const specialty = watch('specialty');
  const courses = watch('courses');

  const getCourses = async () => {
    try {
      setHasComponentLoaded(false);
      const coursesResponse = await fetchCourses({
        limit: 0,
        specialty: specialty.length && (specialty !== '0') ? optionsSpecialty[specialty].name : '',
      });

      if (coursesResponse) {
        setValue('courses', coursesResponse.courses);
      }
    } finally {
      setHasComponentLoaded(true);
    }
  };

  useEffect(() => {
    getCourses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specialty]);

  return (
    <Layout>
      <Box sx={classes.pageTitleContainer}>
        <Typography
          color="primary.main"
          sx={classes.pageTitle}
          variant="h1"
        >
          Cursos disponibles
        </Typography>
        <ControlledSelect
          control={control}
          fieldLabel="Especialidad del curso"
          fieldName="specialty"
          fullWidth={false}
          options={optionsSpecialty}
        />
      </Box>
      <LoadingComponent
        center
        loading={!hasComponentLoaded}
        loadingSize={100}
      >
        <Grid container spacing={{ xs: 2, sm: 4 }} sx={classes.coursesGridContainer}>
          {courses.map((course) => (
            <Grid key={course.id} item xs={12} md={6} lg={4} sx={classes.coursesGridItem}>
              <CourseCard
                date={{ start: course.start_date, end: course.end_date }}
                doAndSendOnClick={() => ({ course })}
                edition={course.edition}
                image={course.images[0]}
                modality={course.modality}
                tags={course.tags}
                title={course.name}
                to={`./${course.id}`}
              />
            </Grid>
          ))}
        </Grid>
      </LoadingComponent>
    </Layout>
  );
};

export default memo(Courses);
