import React from 'react';
import { Navigate } from 'react-router-dom';

import Layout from '../../components/Layouts/Layout';

const Home = () => (
  <Layout>
    <Navigate to="/courses" />
  </Layout>
);

export default React.memo(Home);
