import React from 'react';
import { Box, Grid, Typography } from '@mui/material';

import leftBackground from '../../assets/login_background.svg';
import idhsLogo from '../../assets/idhs_logo_212x86.svg';
import { ReactContainerProps } from '../../interfaces/props';

const classes = {
  container: {
    minHeight: '100vh',
  },
  leftGridElement: {
    backgroundImage: `url(${leftBackground})`,
    backgroundSize: 'cover',
    display: {
      xs: 'none',
      md: 'flex',
    },
    alignItems: 'center',
    justifyContent: 'center',
  },
  leftGridElementTitle: {
    fontSize: '40px',
    fontWeight: 500,
    lineHeight: '48.41px',
    maxWidth: '401px',
  },
  rightGridElement: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    alignText: 'center',
    padding: '20px',
  },
  logo: {
    width: '212px',
  },
  childrenContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
};

const AuthLayout = ({ children }: ReactContainerProps) => (
  <Box>
    <Grid container sx={classes.container}>
      <Grid item xs={0} md={6} sx={classes.leftGridElement}>
        <Typography variant="h1" color="primary.main" style={classes.leftGridElementTitle}>
          Capacitación médica
          {' '}
          <b>de vanguardia</b>
        </Typography>
      </Grid>
      <Grid item xs={12} md={6} sx={classes.rightGridElement}>
        <img src={idhsLogo} alt="IDHS" style={classes.logo} />
        <Box mt={2.75} sx={classes.childrenContainer}>
          {children}
        </Box>
      </Grid>
    </Grid>
  </Box>
);

export default React.memo(AuthLayout);
