const classes = {
  formContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  inputsContainer: {
    alignItems: 'center',
    display: 'inline-flex',
    justifyContent: 'center',
    maxWidth: {
      xs: '315px',
      sm: '677px',
    },
    mb: 2,
  },
  buttonContainer: {
    display: 'flex',
    mt: 1,
    width: '100%',
  },
  redirectToLoginText: {
    textDecoration: 'none',
  },
};

export default classes;
