import React, { KeyboardEvent, useState } from 'react';
import { Controller } from 'react-hook-form';
import {
  IconButton,
  InputAdornment,
  TextField,
  FormControl,
  FormHelperText,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import { ControlledTextFieldProps } from '../../interfaces/inputs';

const ControlledTextField = ({
  control,
  fieldName,
  fieldLabel,
  rules,
  type = 'text',
  multiline,
  rows,
  disabled = false,
  startAdornment,
  endAdornment,
  trim = false,
  helperText,
  ...other
}: ControlledTextFieldProps) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const handleClickShowPassword = (): void => setShowPassword(!showPassword);
  const handleMouseDownPassword = (): void => setShowPassword(!showPassword);
  const handleType = () => {
    if (type !== 'password') return type;
    return showPassword ? 'text' : 'password';
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    const invalidKeysForNumericInput = ['-', '+', '.', ',', 'ArrowDown', 'ArrowUp', 'e', 'E'];
    const isEnteredKeyInvalidForNumericInput = invalidKeysForNumericInput.includes(event.key) && type === 'number';
    if (isEnteredKeyInvalidForNumericInput) event.preventDefault();
  };

  const chooseEndAndornment = () => {
    if (type === 'password') {
      return (
        <IconButton
          aria-label="Toggle password"
          onClick={handleClickShowPassword}
          onMouseDown={handleMouseDownPassword}
        >
          <InputAdornment position="end">
            {showPassword ? <Visibility /> : <VisibilityOff />}
          </InputAdornment>
        </IconButton>
      );
    }

    return endAdornment;
  };

  return (
    <Controller
      control={control}
      name={fieldName}
      rules={rules}
      defaultValue=""
      render={({ field, fieldState: { error } }) => (
        <FormControl
          sx={{ textAlign: 'start' }}
          fullWidth
          variant="standard"
          error={!!error}
        >
          <TextField
            id={fieldName}
            label={fieldLabel}
            error={!!error}
            variant="standard"
            fullWidth
            disabled={disabled}
            multiline={multiline}
            rows={rows}
            type={handleType()}
            sx={{
              'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
                WebkitAppearance: 'none',
                margin: 0,
              },
              'input[type=number]': {
                MozAppearance: 'textfield',
              },
            }}
            autoComplete={type === 'password' ? 'off' : ''}
            value={field.value}
            onChange={(e) => {
              if (trim) field.onChange(e.target.value.trim());
              else field.onChange(e);
            }}
            onKeyDown={handleKeyDown}
            InputProps={{
              startAdornment,
              endAdornment: chooseEndAndornment(),
            }}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...other}
          />
          {error ? (
            <FormHelperText color="error">
              {error.message}
            </FormHelperText>
          ) : (
            <FormHelperText>
              {helperText}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};

export default React.memo(ControlledTextField);
