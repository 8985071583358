import { Routes, Route } from 'react-router-dom';
import Box from '@mui/material/Box';

// PAGE IMPORTS
import AcademicOffer from './pages/Courses/AcademicOffer';
import BulkAMMUsers from './pages/BulkAMMUsers';
import Campus from './pages/Campus';
import Certificates from './pages/Certificates/Certificates';
import Collections from './pages/Collections';
import ConceptUser from './pages/ConceptUser';
import Configuration from './pages/Configuration';
import Course from './pages/Course/Course';
import CourseEdit from './pages/CourseEdit/CourseEdit';
import Enrollments from './pages/Enrollments';
import Home from './pages/Home/Home';
import Login from './pages/Login/Login';
import Logout from './pages/Logout';
import MyCourses from './pages/MyCourses';
import NewCourse from './pages/NewCourse';
import NewEnrollment from './pages/NewEnrollment';
import PasswordConfirm from './pages/Auth/PasswordConfirm';
import PasswordForgot from './pages/Auth/PasswordForgot';
import PasswordRecover from './pages/Auth/PasswordRecover';
import Payment from './pages/Payment/Payment';
import Payments from './pages/Payments/Payments';
import ProtectedRoute from './components/Auth/ProtectedRoute';
import Qualifications from './pages/Qualifications';
import Register from './pages/Register/Register';
import UserManagement from './pages/UserManagement';
import Users from './pages/Users';

const App = () => (
  <Box className="App">
    <Routes>
      <Route path="/" element={<ProtectedRoute outlet={<Home />} />} />
      <Route path="/auth/password/confirm" element={<PasswordConfirm />} />
      <Route path="/auth/password/forgot" element={<PasswordForgot />} />
      <Route path="/auth/password/recover" element={<PasswordRecover />} />
      <Route path="/campus" element={<ProtectedRoute outlet={<Campus />} />} />
      <Route path="/certificates" element={<ProtectedRoute outlet={<Certificates />} />} />
      <Route path="/collections" element={<ProtectedRoute outlet={<Collections />} />} />
      <Route path="/courses" element={<ProtectedRoute outlet={<AcademicOffer />} />} />
      <Route path="/courses/:id" element={<ProtectedRoute outlet={<Course />} />} />
      <Route path="/courses/:id/concept_user" element={<ProtectedRoute outlet={<ConceptUser />} />} />
      <Route path="/courses/:id/edit" element={<ProtectedRoute outlet={<CourseEdit />} />} />
      <Route path="/courses/:id/payments" element={<ProtectedRoute outlet={<Payments />} />} />
      <Route path="/courses/:id/payments/:paymentId" element={<ProtectedRoute outlet={<Payment />} />} />
      <Route path="/courses/create" element={<ProtectedRoute outlet={<NewCourse />} />} />
      <Route path="/courses/configuration" element={<ProtectedRoute outlet={<Configuration />} />} />
      <Route path="/enrollments" element={<ProtectedRoute outlet={<Enrollments />} />} />
      <Route path="/enrollments/create" element={<ProtectedRoute outlet={<NewEnrollment />} />} />
      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/me/courses" element={<ProtectedRoute outlet={<MyCourses />} />} />
      <Route path="/qualifications" element={<ProtectedRoute outlet={<Qualifications />} />} />
      <Route path="/register" element={<Register />} />
      <Route path="/users" element={<ProtectedRoute outlet={<Users />} />} />
      <Route path="/users/management" element={<ProtectedRoute outlet={<UserManagement />} />} />
      <Route path="/users/AMM/bulk" element={<ProtectedRoute outlet={<BulkAMMUsers />} />} />
    </Routes>
  </Box>
);

export default App;
