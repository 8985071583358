const START_PAGE_SIZE = 1;
const QUERY_LIMIT = 15;

const MAX_LOGOS = 2;

const ENROLLMENT_STATE_CANCELLED = 'cancelled';
const ENROLLMENT_STATE_ENROLLED = 'enrolled';
const ENROLLMENT_STATE_WAIT = 'wait';

const enrollmentStatesDict = {
  [ENROLLMENT_STATE_CANCELLED]: 'Cancelado',
  [ENROLLMENT_STATE_ENROLLED]: 'Confirmado',
  [ENROLLMENT_STATE_WAIT]: 'En lista de espera',
};

const SCOPES = {
  canAccessVirtualCampus: 'can-access-virtual-campus',
  canCreateCourses: 'can-create-courses',
  canCreateEvaluations: 'can-create-evaluations',
  canCreateInscription: 'can-create-inscription',
  canCreateLogo: 'can-create-logo',
  canCreateUser: 'can-create-user',
  canEditCourses: 'can-edit-courses',
  canRemoveCourses: 'can-remove-courses',
  canRemoveLogo: 'can-remove-logo',
  canSeeAcademicOffer: 'can-see-academic-offer',
  canSeeCourseList: 'can-see-course-list',
  canSeeEvaluationsList: 'can-see-evaluations-list',
  canSeeUsersList: 'can-see-users-list',
  canViewInscriptionsList: 'can-view-inscriptions-list',
  canViewOwnCertificates: 'can-view-own-certificates',
  canViewOwnCourses: 'can-view-own-courses',
  canViewOwnEvaluations: 'can-view-own-evaluations',
  canViewOwnPayments: 'can-view-own-payments',
  canViewPaymentsList: 'can-view-payments-list',
};

export {
  enrollmentStatesDict,
  MAX_LOGOS,
  QUERY_LIMIT,
  SCOPES,
  START_PAGE_SIZE,
};
