/* eslint-disable react/jsx-props-no-spreading */
import { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';
import SUsers from 'src/services/usersService';
import { useMessageModal } from 'src/contexts/messageModal/context';
import classes from './classes';

const Uploader = (args) => {
  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);
  const { openMessageModal } = useMessageModal();

  const onDrop = async (acceptedFiles) => {
    const formData = new FormData();
    formData.append(
      'xlsxFile',
      acceptedFiles[0],
    );
    const fileName = await SUsers.uploadFile(formData);

    if (!fileName.ok) {
      setShowError(true);
      return;
    }

    setShowError(false);
    openMessageModal({
      title: '¡Plantilla cargada con éxito!',
      message: 'La plantilla fue cargada de forma exitosa',
      variant: 'success',
      primaryButton: {
        text: 'Aceptar',
      },
    });
  };
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'application/x-msdownload': ['.exe'],
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    },
    onDrop,
  });

  return (
    <Box sx={classes.dropzone} {...getRootProps({ className: 'dropzone' })}>
      <input {...getInputProps()} />
      {
            showError ? (
              <>
                <Typography sx={classes.errorText}>
                  El archivo no es válido
                </Typography>
                <Typography sx={classes.uploadText}>
                  Asegúrate de que todos los campos esten completos o&nbsp;
                  <Box component="span" sx={classes.uploadSpan}>
                    Subí un nuevo archivo
                  </Box>
                </Typography>
              </>
            ) : (
              <>
                <Typography sx={classes.uploadText}>
                  Arrastra y solta tu plantilla aquí
                </Typography>
                <Typography sx={classes.uploadText}>
                  o&nbsp;
                  <Box component="span" sx={classes.uploadSpan}>
                    Subí un archivo
                  </Box>
                </Typography>
              </>
            )
        }
    </Box>
  );
};
export default Uploader;
