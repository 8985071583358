import { memo, useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';

import Layout from '../../components/Layouts/Layout';
import CertificateCard from './CertificateCard';
import { useEnrollments } from '../../contexts/enrollments/context';
import { EnrollmentInterface } from '../../interfaces/enrollment';

const classes = {
  pageTitle: {
    fontSize: '32px',
    fontWeight: 700,
    color: 'primary.main',
  },
  contentContainer: {
    paddingY: 5,
    paddingX: 7,
    display: 'flex',
    flexDirection: 'column',
    gap: { xs: 3, sm: 6 },
    height: 'auto',
  },
};

const Certificates = () => {
  const { getAllCertificates } = useEnrollments();
  const [certificates, setCertificates] = useState<EnrollmentInterface[]>([]);

  useEffect(() => {
    const getCertificates = async () => {
      const fetchedCertificates = await getAllCertificates();
      setCertificates(fetchedCertificates);
    };
    getCertificates();
  }, [getAllCertificates]);

  return (
    <Layout>
      <Box sx={classes.contentContainer}>
        <Typography variant="h1" sx={classes.pageTitle}>
          Certificados
        </Typography>
        <Grid container spacing={3}>
          {certificates?.map(({ id, course }) => (
            <Grid item xs={12} lg={6} xl={4} key={id}>
              <CertificateCard id={id} image={course.images[0]} title={course.name} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Layout>
  );
};

export default memo(Certificates);
