import { memo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Divider,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';

import { useAuth } from '../../contexts/auth/context';

import { ProfileSubMenuProps } from '../../interfaces/layout';

import { profileSubMenuClasses as classes } from './classes';

const ProfileSubMenu = ({ open, anchorEl, handleClose }: ProfileSubMenuProps) => {
  const { state: { auth: user } } = useAuth();
  const navigate = useNavigate();

  return (
    <Menu
      id="projects-sub-menu"
      MenuListProps={{ 'aria-labelledby': 'projects-sub-menu' }}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      sx={classes.profileSubMenu}
    >
      <Box sx={classes.userDataContainer}>
        <Typography variant="h4">
          {`${user.first_name} ${user.last_name}`}
        </Typography>
        <Typography variant="h6" color="text.disabled">
          {user.email}
        </Typography>
      </Box>
      <Divider sx={classes.divider} />
      <MenuItem disableRipple onClick={() => navigate('/logout')}>
        <Typography variant="h6">Cerrar sesión</Typography>
      </MenuItem>
    </Menu>
  );
};

export default memo(ProfileSubMenu);
