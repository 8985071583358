import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import {
  Box,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { Help as HelpIcon } from '@mui/icons-material';

import { useMessageModal } from '../../contexts/messageModal/context';
import { useAuth } from '../../contexts/auth/context';
import ControlledAutocomplete from '../../components/Inputs/ControlledAutocomplete';
import ControlledTextField from '../../components/Inputs/ControlledTextField';
import ControlledSelect from '../../components/Inputs/ControlledSelect';
import LoadingButton from '../../components/Controls/LoadingButton';
import rulesConstants from '../../helpers/rulesConstants';
import {
  documentTypes, hospitals, phoneTypes, specialities,
} from '../../helpers/optionsInput';
import { RegisterUserInterface } from '../../interfaces/user';

import classes from './classes';

const RegisterForm = () => {
  const { retryEmailRegister, registerUser } = useAuth();
  const { openMessageModal } = useMessageModal();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const {
    control, handleSubmit, reset,
  } = useForm<RegisterUserInterface>({
    defaultValues: useMemo(() => ({
      first_name: '',
      last_name: '',
      email: '',
      document: '',
      documentType: 'dni',
      phoneNumber: '',
      phoneType: '',
      hospital: '',
      specialty: '',
    }), []),
  });

  const retrySendEmail = async (idUser: string) => {
    setLoading(true);
    const wasEmailSent = await retryEmailRegister(idUser);

    if (wasEmailSent) {
      openMessageModal({
        title: '¡Tu cuenta ha sido creada con éxito!',
        message: 'Se envió un email de confirmación para poder activar la cuenta',
        variant: 'success',
        primaryButton: {
          text: 'Continuar',
          onClick: () => navigate('/login'),
        },
        secondaryButton: {
          text: 'Reenviar mail',
          onClick: () => retrySendEmail(idUser),
        },
      });
    }

    setLoading(false);
  };

  const register = async (user: RegisterUserInterface) => {
    setLoading(true);
    const userRegistered = await registerUser(user);

    if (userRegistered) {
      openMessageModal({
        title: '¡Tu cuenta ha sido creada con éxito!',
        message: 'Se envió un email de confirmación para poder activar la cuenta',
        variant: 'success',
        primaryButton: {
          text: 'Continuar',
          onClick: () => navigate('/login'),
        },
        secondaryButton: {
          text: 'Reenviar mail',
          onClick: () => retrySendEmail(userRegistered.id),
        },
      });
      reset();
    }

    setLoading(false);
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={classes.formContainer}
      onSubmit={handleSubmit(register)}
    >
      <Grid container spacing={3.25} sx={classes.inputsContainer}>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            control={control}
            fieldName="first_name"
            fieldLabel="Nombre"
            rules={rulesConstants.textfield({ fieldLabel: 'Nombre' })}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            control={control}
            fieldName="last_name"
            fieldLabel="Apellido"
            rules={rulesConstants.textfield({ fieldLabel: 'Apellido' })}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            control={control}
            fieldName="email"
            fieldLabel="Email"
            rules={rulesConstants.emailTextfield({ fieldLabel: 'Email' })}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <ControlledSelect
                control={control}
                fieldName="documentType"
                fieldLabel="Tipo de documento"
                options={documentTypes}
                rules={rulesConstants.select({ fieldLabel: 'Tipo de doc', message: 'Requerido' })}
              />
            </Grid>
            <Grid item xs={9}>
              <ControlledTextField
                control={control}
                fieldName="document"
                type="number"
                fieldLabel="Número de documento"
                endAdornment={(
                  <Tooltip
                    disableFocusListener
                    title="Solo puede ingresar números."
                    placement="right"
                  >
                    <IconButton color="primary" aria-label="upload video" component="label" size="small" sx={{ p: 0, mb: 0.25 }}>
                      <HelpIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
                rules={rulesConstants.textfield({ fieldLabel: 'Número de documento' })}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledSelect
            control={control}
            fieldName="phoneType"
            fieldLabel="Tipo de teléfono (fijo/móvil)"
            options={phoneTypes}
            rules={rulesConstants.select({ fieldLabel: 'Tipo de teléfono (fijo/móvil)' })}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            control={control}
            fieldLabel="Número de teléfono"
            fieldName="phoneNumber"
            rules={rulesConstants.phoneNumberValidation({ fieldLabel: 'Número de teléfono' })}
            type="number"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledAutocomplete
            control={control}
            fieldName="hospital"
            fieldLabel="Hospital"
            options={hospitals}
            rules={rulesConstants.select({ fieldLabel: 'Hospital' })}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledAutocomplete
            control={control}
            fieldName="specialty"
            fieldLabel="Especialidad"
            options={specialities}
            rules={rulesConstants.select({ fieldLabel: 'Especialidad' })}
          />
        </Grid>
        <Grid item xs={12}>
          <Box sx={classes.buttonContainer}>
            <LoadingButton
              type="submit"
              loading={loading}
              variant="contained"
              color="primary"
              fullWidth
            >
              Registrate
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
      <Box>
        <Typography variant="h6" display="inline">
          ¿Ya tenés cuenta?
          {' '}
        </Typography>
        <Typography
          variant="h6"
          display="inline"
          color="primary.main"
          fontWeight={600}
          component={Link}
          to="/login"
          style={classes.redirectToLoginText}
        >
          Ingresá aquí
        </Typography>
      </Box>
    </Box>
  );
};

export default React.memo(RegisterForm);
