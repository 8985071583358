import React from 'react';
import { Breadcrumbs, Typography, Button } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const classes = {
  stepsContainer: {
    marginBottom: 4, display: 'flex', justifyContent: { xs: 'center', sm: 'left' },
  },
  paginationItem: (disabled: boolean = false) => ({
    fontWeight: 600,
    marginRight: 1.5,
    fontSize: '14px',
    opacity: disabled ? '50%' : '100%',
    minWidth: 0,
    padding: 0,
    height: 'auto',
    textTransform: 'none',
  }),
};

export const steps = [
  { text: '1. Información', onClick: (id: string) => `/courses/${id}/` },
  { text: '2. Tipo de usuario', onClick: (id: string) => `/courses/${id}/concept_user/` },
  { text: '3. Pago', onClick: (id: string) => `/courses/${id}/payments` },
];

interface Props {
  currentStep: number;
  customSteps?: { text: string; onClick: () => void }[];
}

const InscriptionSteps = ({ currentStep, customSteps = [] }: Props) => {
  const { id: idCourse } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();

  const isGreaterOrEqualThanCurrentStep = (step: number) => step >= currentStep - 1;

  const iShouldDisable = (step: number) => step !== currentStep - 1;

  const stepsChoose = (customSteps.length > 0 && customSteps) || steps;

  return (
    <Breadcrumbs separator="" aria-label="breadcrumb" sx={classes.stepsContainer}>
      {stepsChoose.map((content, step) => (isGreaterOrEqualThanCurrentStep(step) ? (
        <Typography key={content.text} color="primary.main" sx={classes.paginationItem(iShouldDisable(step))}>
          {stepsChoose[step].text}
        </Typography>
      ) : (
        <Button
          key={content.text}
          sx={classes.paginationItem(iShouldDisable(step))}
          onClick={customSteps.length === 0 ? () => navigate(`${stepsChoose[step].onClick(idCourse)}`, { state }) : content.onClick}
        >
          {stepsChoose[step].text}
        </Button>
      )))}
    </Breadcrumbs>

  );
};

export default React.memo(InscriptionSteps);
