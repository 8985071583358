import React, { useMemo, useState, useEffect } from 'react';
import { Box, Grid } from '@mui/material';

import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { PasswordFormProps, PasswordInterface } from '../../interfaces/auth';
import ControlledTextField from '../../components/Inputs/ControlledTextField';
import rulesConstants from '../../helpers/rulesConstants';
import LoadingButton from '../../components/Controls/LoadingButton';

const classes = {
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    maxWidth: '328px',
  },
  buttonContainer: {
    display: 'flex',
    width: '100%',
    mt: 1,
  },
};

const PasswordForm = ({ buttonText, passwordContextHandler }: PasswordFormProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get('token');
  const {
    control, handleSubmit, watch, clearErrors,
  } = useForm<PasswordInterface>({
    defaultValues: useMemo(() => ({
      password: '',
      confirmPassword: '',
    }), []),
    reValidateMode: 'onSubmit',
  });

  const watchPassword = watch('password');

  const onSubmit = async (password: PasswordInterface): Promise<void> => {
    setLoading(true);
    await passwordContextHandler({ password, token }).finally(() => setLoading(false));
  };

  useEffect(() => {
    clearErrors();
  }, [watchPassword, clearErrors]);

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={classes.formContainer}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container spacing={3.25}>
        <Grid item xs={12}>
          <ControlledTextField
            control={control}
            fieldName="password"
            fieldLabel="Contraseña"
            type="password"
            helperText="La contraseña debe tener al menos 8 caracteres, 1 minúscula, 1 mayúscula, 1 caracter numérico y 1 caracter no alfanumérico"
            rules={rulesConstants.passwordField({ fieldLabel: 'Contraseña' })}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            control={control}
            fieldName="confirmPassword"
            fieldLabel="Repite contraseña"
            type="password"
            rules={rulesConstants.passwordField({ fieldLabel: 'Repite contraseña', password: watchPassword })}
          />
        </Grid>
        <Grid item xs={12}>
          <Box sx={classes.buttonContainer}>
            <LoadingButton
              type="submit"
              loading={loading}
              variant="contained"
              color="primary"
              fullWidth
            >
              {buttonText}
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default React.memo(PasswordForm);
