const classes = {
  removeLogo: {
    display: 'flex',
    alignItems: 'center',
  },
  removeLogoContainer: {
    padding: '8px 32px 32px 32px',
  },
  removeLogoOriginalName: {
    fontWeight: 500,
    marginLeft: 1,
  },
};

export default classes;
