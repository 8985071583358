import { memo, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  Typography,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import InfiniteScroll from 'react-infinite-scroll-component';

import { useEnrollments } from '../../contexts/enrollments/context';

import FilterButton from '../../components/Buttons/FilterButton';
import Layout from '../../components/Layouts/Layout';
import TableSearchInput from '../../components/Inputs/TableSearchInput';
import Tooltip from '../../components/Tooltip';

import EditQualificationModal from './EditQualificationModal';

import { QUALIFICATION_APPROVED, QUALIFICATION_PENDING, qualificationsDict } from '../../helpers/qualification';
import { buildColumn } from '../../helpers/tables';

import {
  EnrollmentInterface,
  QueryGetEnrollmentsInterface,
  RowQualificationInterface,
} from '../../interfaces/enrollment';

import { QUERY_LIMIT, START_PAGE_SIZE } from '../../common/constants';
import { optionsFilters } from './constants';

import classes from './classes';

const buildRows = (rows: EnrollmentInterface[]): RowQualificationInterface[] => rows?.map(({
  _id,
  course,
  qualification,
  user,
}) => ({
  courseEnrolled: course.name,
  courseId: course.id,
  document: user.documentType.toUpperCase().concat(' ', user.document),
  editQualification: qualification !== '' && qualification !== undefined ? 'EDITAR CALIFICACIÓN' : 'CALIFICAR',
  id: _id,
  internalCourseId: course.internalCourseId,
  qualification: qualificationsDict[qualification || 'pending'],
  studentId: user.id,
  studentName: user.first_name.concat(' ', user.last_name),
}));

const getColorChipForQualification = (qualification: string) => {
  if (qualification === '' || qualification === qualificationsDict[QUALIFICATION_PENDING]) return 'secondary';
  if (qualification === qualificationsDict[QUALIFICATION_APPROVED]) return 'success';
  return 'error';
};

const Qualifications = () => {
  const { fetchEnrollments, updateEnrollment } = useEnrollments();

  const [isGetEnrollmentsLoading, setIsGetEnrollmentsLoading] = useState<boolean>(false);
  const [rows, setRows] = useState<RowQualificationInterface[]>([]);
  const [queryParam, setQueryParam] = useState<QueryGetEnrollmentsInterface>({
    limit: QUERY_LIMIT,
    state: 'enrolled',
    moreInfo: true,
  });
  const [hasMoreRows, setHasMoreRows] = useState<boolean>(false);
  const [openEditQualificationModal, setOpenEditQualificationModal] = useState<boolean>(false);
  const [rowSelectedToEdit, setRowSelectedToEdit] = useState<RowQualificationInterface>(null);
  const [search, setSearch] = useState<string>('');
  const [searchInternalCourseId, setSearchInternalCourseId] = useState<string>('');

  const columns: GridColDef<RowQualificationInterface>[] = [
    buildColumn({
      field: 'studentName',
      flex: 1,
      headerName: 'Nombre y Apellido',
      minWidth: 280,
    }),
    buildColumn({
      field: 'document',
      flex: 1,
      headerName: 'Tipo y N° de Documento',
      minWidth: 200,
    }),
    buildColumn({
      field: 'internalCourseId',
      headerName: 'ID Interno',
    }),
    buildColumn({
      field: 'courseEnrolled',
      flex: 1,
      headerName: 'Curso al que se inscribió',
      cellAlign: true,
      renderCellSlice: 32,
      minWidth: 250,
    }),
    {
      field: 'qualification',
      flex: 1,
      hideSortIcons: true,
      minWidth: 250,
      renderHeader: () => (
        <Box sx={{
          lineHeight: '16px',
          color: 'primary.main',
          whiteSpace: 'pre-wrap',
          '&:focus': {
            outlineWidth: 0,
          },
          '&:focus-within': {
            outlineWidth: 0,
          },
        }}
        >
          Calificación
        </Box>
      ),
      renderCell: (cell) => (
        <Chip
          label={cell.value}
          size="small"
          color={getColorChipForQualification(cell.value)}
          sx={classes.chip}
        />
      ),
    },
    {
      align: 'right',
      field: 'editQualification',
      flex: 1,
      headerName: '',
      hideSortIcons: true,
      minWidth: 180,
      renderCell: (cell) => (
        <Button
          variant="text"
          sx={classes.seeCourseButton}
          onClick={() => {
            setRowSelectedToEdit(cell.row);
            setOpenEditQualificationModal(true);
          }}
        >
          {cell.value}
        </Button>
      ),
    }];

  const getRows = async () => {
    try {
      setIsGetEnrollmentsLoading(true);

      const { enrollments: enrollmentsFetched } = await fetchEnrollments(queryParam);
      const enrollmentsHasMoreRows = enrollmentsFetched.length === QUERY_LIMIT;

      setHasMoreRows(enrollmentsHasMoreRows);
      if (enrollmentsFetched.length) {
        const rowsIds = new Set(rows.map(({ id }) => id));
        const newEnrollments = [
          ...rows,
          ...buildRows(enrollmentsFetched.filter(({ _id }) => !rowsIds.has(_id))),
        ];

        setRows(newEnrollments);
      }
    } finally {
      setIsGetEnrollmentsLoading(false);
    }
  };

  const setInitValues = (attributesToUpdate?: Partial<QueryGetEnrollmentsInterface>) => {
    setRows([]);
    setQueryParam((prevState) => ({
      ...prevState,
      ...attributesToUpdate,
      page: START_PAGE_SIZE,
    }));
  };

  const fetchNextEnrollments = (): void => {
    setQueryParam((prevState) => ({ ...prevState, page: prevState.page + 1 }));
  };

  const updateQualification = async (newQualification: string): Promise<void> => {
    const enrollmentUpdated = await updateEnrollment(
      rowSelectedToEdit.id,
      { qualification: newQualification },
    );
    if (enrollmentUpdated) {
      setOpenEditQualificationModal(false);
      setInitValues();
    }
  };

  useEffect(() => {
    getRows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParam]);

  useEffect(() => {
    setInitValues({
      search: search || null,
      internalCourseId: null,
    });
  }, [search]);

  useEffect(() => {
    setInitValues({
      search: null,
      internalCourseId: searchInternalCourseId || null,
    });
  }, [searchInternalCourseId]);

  return (
    <>
      <Layout marginContainer={false}>
        <Box sx={classes.contentContainer}>
          <Box sx={classes.topContainer}>
            <Typography variant="h1" sx={classes.pageTitle}>
              Calificaciones
            </Typography>
            <Box sx={classes.optionsContainer}>
              <FormControl sx={{ width: '235px' }} variant="outlined">
                <TableSearchInput
                  endAdornment={(
                    <Tooltip title="Buscar por nombre, apellido, DNI o email..." />
                  )}
                  label="Buscar"
                  onSearch={setSearch}
                />
              </FormControl>
              <FormControl sx={{ width: '235px' }} variant="outlined">
                <TableSearchInput
                  disabled={!!search}
                  label="Buscar por ID Interno"
                  onSearch={setSearchInternalCourseId}
                />
              </FormControl>
              <FilterButton options={optionsFilters} query={queryParam} setQuery={setInitValues} />
            </Box>
          </Box>
          <Box height="100%" width="100%">
            <InfiniteScroll
              dataLength={rows.length}
              next={fetchNextEnrollments}
              hasMore={hasMoreRows}
              loader={rows.length > 0 ? (
                <Box sx={classes.loaderContainer}>
                  <CircularProgress size={40} color="primary" />
                </Box>
              ) : <div />}
              style={{ maxHeight: '100%' }}
            >
              <DataGrid
                rows={rows}
                columns={columns}
                sx={{ borderWidth: '0px' }}
                loading={isGetEnrollmentsLoading}
                disableRowSelectionOnClick
                hideFooter
                disableColumnFilter
                disableColumnMenu
                autoHeight
              />
            </InfiniteScroll>
          </Box>
        </Box>
      </Layout>
      <EditQualificationModal
        openModal={openEditQualificationModal}
        onClickButton={updateQualification}
        onCloseModal={() => setOpenEditQualificationModal(false)}
        enrollment={rowSelectedToEdit}
      />
    </>
  );
};

export default memo(Qualifications);
