import { memo, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Typography,
  Box,
  Grid,
  Button,
} from '@mui/material';

import { useMessageModal } from '../../contexts/messageModal/context';

import authService from '../../services/authService';

import AuthLayout from '../../components/Layouts/AuthLayout';
import ControlledTextField from '../../components/Inputs/ControlledTextField';
import LoadingButton from '../../components/Controls/LoadingButton';

import { PasswordForgotInterface } from '../../interfaces/auth';

import rulesConstants from '../../helpers/rulesConstants';

import classes from './classes';

const PasswordForgot = () => {
  const [loading, setLoading] = useState(false);
  const { openMessageModal } = useMessageModal();
  const {
    control, handleSubmit, reset,
  } = useForm<PasswordForgotInterface>({
    defaultValues: useMemo(() => ({
      email: '',
    }), []),
  });

  const onSubmit = async (email: PasswordForgotInterface): Promise<void> => {
    setLoading(true);
    await authService.forgotPassword(email)
      .then(() => {
        reset();
        openMessageModal({
          title: 'Se ha enviado un mail a tu casilla de correo.',
          variant: 'success',
          primaryButton: {
            text: 'Aceptar',
          },
        });
      })
      .catch((e) => openMessageModal({
        title: e,
        variant: 'warning',
        primaryButton: {
          text: 'Aceptar',
        },
      }))
      .finally(() => setLoading(false));
  };

  const handleGoBack = () => {
    window.history.back();
  };

  return (
    <AuthLayout>
      <Box mb={4.5} sx={classes.title}>
        <Typography variant="h1" color="primary.main">
          Escribe tu email para poder recuperar tu contraseña
        </Typography>
      </Box>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        sx={classes.formContainer}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={3.25}>
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              fieldName="email"
              fieldLabel="Email"
              rules={rulesConstants.emailTextfield({ fieldLabel: 'Email' })}
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={classes.sendButton}>
              <LoadingButton
                type="submit"
                loading={loading}
                variant="contained"
                color="primary"
                fullWidth
              >
                Enviar
              </LoadingButton>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={classes.goBackButtonContainer}>
              <Button
                color="info"
                disabled={loading}
                onClick={handleGoBack}
                sx={classes.goBackButton}
                variant="outlined"
              >
                Volver al inicio
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </AuthLayout>
  );
};

export default memo(PasswordForgot);
