import {
  memo,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Help as HelpIcon } from '@mui/icons-material';

import { useCourses } from '../../contexts/courses/context';
import { useEnrollments } from '../../contexts/enrollments/context';
import { useMessageModal } from '../../contexts/messageModal/context';
import { useUsers } from '../../contexts/users/context';

import ControlledAutocomplete from '../../components/Inputs/ControlledAutocomplete';
import ControlledSelect from '../../components/Inputs/ControlledSelect';
import ControlledTextField from '../../components/Inputs/ControlledTextField';
import Layout from '../../components/Layouts/Layout';
import LoadingButton from '../../components/Controls/LoadingButton';

import rulesConstants from '../../helpers/rulesConstants';

import { CreateEnrollmentInterface, DiscountTypes } from '../../interfaces/enrollment';
import { QueryGetUserByDocumentOrEmailInterface } from '../../interfaces/user';
import CourseInterface from '../../interfaces/courses';

const NewEnrollment = () => {
  const navigate = useNavigate();
  const { fetchCourses } = useCourses();
  const { enroll } = useEnrollments();
  const { fetchUsersByDocumentOrEmail } = useUsers();
  const { openMessageModal } = useMessageModal();
  const [courses, setCourses] = useState<CourseInterface[]>([]);
  const [isSearchingUser, setIsSearchingUser] = useState<boolean>(false);
  const [isEnrollment, setIsEnrollment] = useState<boolean>(false);

  const {
    clearErrors,
    control,
    formState,
    handleSubmit,
    setError,
    setValue,
    watch,
  } = useForm<CreateEnrollmentInterface>({
    defaultValues: useMemo(() => ({
      course: '',
      discount: 0,
      discountType: DiscountTypes.Percentage,
      observations: '',
      userCategory: '',
      userDocument: '',
      userEmail: '',
    }), []),
  });

  const isPercentage = watch('discountType') === DiscountTypes.Percentage;
  const hasUserCategory = !!watch('userCategory').length;

  useEffect(() => {
    const getCourses = async () => {
      const coursesFetched = await fetchCourses({ limit: 0 });
      setCourses(coursesFetched?.courses || []);
    };

    getCourses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const coursesName = useMemo(() => courses.map(({ id, internalCourseId, name }) => ({ id, name: `${internalCourseId} - ${name}` })), [courses]);

  const userCategories = () => {
    const courseFound = courses?.find((course) => course.id === watch('course'));
    return courseFound?.tariff?.map(({ category }) => ({ id: category, name: category })) || [];
  };

  const onSubmit = async (enrollment: CreateEnrollmentInterface) => {
    try {
      const query: QueryGetUserByDocumentOrEmailInterface = {};
      const {
        course,
        discount,
        discountType,
        observations,
        userCategory,
        userDocument,
        userEmail,
      } = enrollment;

      if (userDocument) query.document = userDocument;
      if (userEmail) query.email = userEmail;

      setIsSearchingUser(true);
      const { user } = await fetchUsersByDocumentOrEmail(query);
      setIsSearchingUser(false);

      if (!user) {
        if (userDocument.length > 0) {
          setError('userDocument', {
            type: 'manual',
            message: 'El número de documento ingresado no está asociado a ningún usuario',
          });
        }
        if (userEmail.length > 0) {
          setError('userEmail', {
            type: 'manual',
            message: 'El email ingresado no está asociado a ningún usuario',
          });
        }
        return;
      }

      clearErrors('userDocument');

      setIsEnrollment(true);
      const courseEnrolled = await enroll(
        user.id,
        course,
        'enroll',
        observations,
        userCategory,
        discount,
        discountType,
      );
      setIsEnrollment(false);

      if (courseEnrolled) {
        openMessageModal({
          title: 'Usuario inscripto con éxito',
          variant: 'success',
          message: 'Se envió el detalle del curso al email del usuario',
          primaryButton: {
            onClick: () => navigate('/enrollments'),
          },
        });
      }
    } catch (error) {
      setIsSearchingUser(false);
      setIsEnrollment(false);
    }
  };

  return (
    <Layout>
      <>
        <Typography variant="h1" color="primary.main" mb={5}>
          Alta de inscriptos
        </Typography>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
          sx={{ overflowX: 'hidden' }}
        >
          <Grid container direction="column" mb={5}>
            <Grid item container columnSpacing={10} mb={8}>
              <Grid item sm={12} md={6}>
                <Typography variant="subtitle1" color="primary.main" mb={2}>
                  Curso al que se va a inscribir
                </Typography>
                <ControlledAutocomplete
                  control={control}
                  fieldName="course"
                  fieldLabel="Nombre del curso"
                  options={coursesName}
                  rules={rulesConstants.select({ fieldLabel: 'Nombre del curso' })}
                />
              </Grid>
            </Grid>
            <Grid item container columnSpacing={10} mb={2}>
              <Grid item sm={12} md={6}>
                <Typography variant="subtitle1" color="primary.main" mb={0.25}>
                  Nombre del usuario
                </Typography>
                <Typography variant="subtitle2" color="secondary.main">
                  Recordá que el usuario tiene que estar dado de alta en el
                  sistema para poder inscribirse a un curso
                </Typography>
              </Grid>
            </Grid>
            <Grid item container columnSpacing={10} rowSpacing={5}>
              <Grid item sm={12} md={6}>
                <ControlledTextField
                  control={control}
                  fieldName="userEmail"
                  fieldLabel="Email"
                  disabled={watch('userDocument').length > 0}
                />
              </Grid>
              <Grid item sm={12} md={6}>
                <ControlledTextField
                  control={control}
                  fieldName="userDocument"
                  type="number"
                  fieldLabel="Número de documento"
                  endAdornment={(
                    <Tooltip
                      disableFocusListener
                      title="Solo puede ingresar números."
                      placement="right"
                    >
                      <IconButton color="primary" aria-label="upload video" component="label" size="small" sx={{ p: 0, mb: 0.25 }}>
                        <HelpIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  )}
                  disabled={watch('userEmail').length > 0}
                />
              </Grid>
              <Grid item sm={12} md={6}>
                <ControlledSelect
                  control={control}
                  fieldName="userCategory"
                  fieldLabel="Categoría de usuario"
                  options={userCategories()}
                  rules={rulesConstants.select({ fieldLabel: 'Categoría de usuario' })}
                />
              </Grid>
              <Grid item sm={12} md={6}>
                <ControlledTextField
                  control={control}
                  disabled={!hasUserCategory}
                  fieldLabel="Descuento sobre el precio total del curso"
                  fieldName="discount"
                  rules={
                    isPercentage
                      ? rulesConstants.discountFieldValidation({ fieldLabel: 'Descuento sobre el precio total del curso' })
                      : rulesConstants.numericFieldValidation({ fieldLabel: 'Descuento sobre el precio total del curso' })
                  }
                  startAdornment={isPercentage && <Box mr={0.5}>%</Box>}
                  type="number"
                />
              </Grid>
              <Grid item sm={12} md={6}>
                <ControlledTextField
                  control={control}
                  fieldLabel="Observaciones"
                  fieldName="observations"
                />
              </Grid>
              {
                hasUserCategory && (
                  <Grid item sm={12} md={6}>
                    <FormControl>
                      <FormLabel id="tipo-descuento-label">Tipo de descuento</FormLabel>
                      <RadioGroup
                        aria-labelledby="tipo-descuento-label"
                        defaultValue={DiscountTypes.Percentage}
                        name="discount-type-option"
                      >
                        <FormControlLabel
                          control={<Radio onChange={({ target }) => setValue('discountType', target.value)} />}
                          label="Por porcentaje"
                          value={DiscountTypes.Percentage}
                        />
                        <FormControlLabel
                          control={<Radio onChange={({ target }) => setValue('discountType', target.value)} />}
                          label="Por monto"
                          value={DiscountTypes.Fixed}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                )
              }
            </Grid>
          </Grid>
          <Box sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: { xs: 'center', md: 'end' },
            gap: 3,
          }}
          >
            <LoadingButton loading={isSearchingUser} variant="contained" type="submit" disabled={!formState.isValid} color="primary">
              Confirmar
            </LoadingButton>
            <Button variant="outlined" color="error" onClick={() => navigate('/')}>
              Cancelar
            </Button>
          </Box>
        </Box>
        <Backdrop open={isEnrollment}>
          <CircularProgress size={80} thickness={5} />
        </Backdrop>
      </>
    </Layout>
  );
};

export default memo(NewEnrollment);
