import { createContext, useContext, useMemo } from 'react';
import { useSnackbar } from 'notistack';

import logosService from '../../services/logosService';
import { ReactContainerProps } from '../../interfaces/props';
import { TLogo, TResponseGetLogos } from '../../interfaces/logos';

const ConfigurationContext = createContext(undefined);

export const useConfiguration = () => {
  const context = useContext(ConfigurationContext);
  const { enqueueSnackbar } = useSnackbar();

  if (context === undefined) {
    throw new Error('useConfiguration must be used within a ConfigurationProvider');
  }

  const createLogo = async (logo: FormData): Promise<TLogo> => {
    try {
      const logoCreated = await logosService.create(logo);

      return logoCreated;
    } catch (e) {
      enqueueSnackbar(
        'Oops! Error al subir el logo',
        { variant: 'error' },
      );

      return null;
    }
  };

  const fetchLogos = async (): Promise<TResponseGetLogos> => {
    try {
      const logos = await logosService.getAll();

      return logos;
    } catch (e) {
      enqueueSnackbar(
        'Oops! Error inesperado al obtener los logos',
        { variant: 'error' },
      );

      return null;
    }
  };

  const removeLogo = async (logoId: string): Promise<TLogo> => {
    try {
      return await logosService.remove(logoId);
    } catch (e) {
      enqueueSnackbar(
        'Oops! Error inesperado al eliminar el logo',
        { variant: 'error' },
      );

      return null;
    }
  };

  return {
    createLogo,
    fetchLogos,
    removeLogo,
  };
};

const ConfigurationProvider = ({ children }: ReactContainerProps) => {
  const value = useMemo(() => ({}), []);

  return (
    <ConfigurationContext.Provider value={value}>
      {children}
    </ConfigurationContext.Provider>
  );
};

export default ConfigurationProvider;
