import { memo, useCallback } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Grid,
  Typography,
} from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';

import ControlledTextField from '../Inputs/ControlledTextField';
import ControlledSelect from '../Inputs/ControlledSelect';
import ControlledSwitch from '../Inputs/ControlledSwitch';

import { CreatePaymentFormInterface, Currency } from '../../interfaces/courses';
import rulesConstants from '../../helpers/rulesConstants';

import {
  optionsCategories,
  optionsPaymentMethods,
  optionsQuotes,
} from './constants';

import { PaymentFormProps } from './types';

import { paymentClasses as classes } from './classes';

const PaymentForm = ({
  course,
  mode,
  onClickBack,
  onSubmit,
}: PaymentFormProps) => {
  const {
    control,
    formState,
    getValues,
    handleSubmit,
    setValue,
    watch,
  } = useForm<CreatePaymentFormInterface>({
    mode: 'onChange',
    defaultValues: {
      categories: course && course.tariff ? course.tariff.map((tariff) => ({
        category: tariff.category,
        amount: tariff.amount,
      })) : [{
        category: '',
        amount: '0',
      }],
      isPreinscription: mode === 'create'
        ? course?.isPreinscription ?? true
        : course?.isPreinscription ?? !!course?.preinscription,
      payment_methods: course && course.payment_methods ? course.payment_methods : [],
      preinscriptionAmount: course && course.preinscription ? String(course.preinscription.amount) : '0',
      quotes: course && course.tariff && course.tariff.length ? String(course.tariff[0].quotes) : '',
    },
  });

  const isPreinscription = watch('isPreinscription');

  const { fields: categories, append } = useFieldArray({
    control,
    name: 'categories',
  });

  const addCategories = useCallback(() => append({
    category: '',
    amount: '0',
  }), [append]);

  const formattedPaymentData = (data: CreatePaymentFormInterface) => ({
    isPreinscription: data.isPreinscription,
    payment_methods: data.payment_methods,
    preinscription: {
      currency: Currency.Ars as string,
      amount: data.isPreinscription ? data.preinscriptionAmount : '0',
    },
    tariff: data.categories.map(({ category, amount }) => ({
      currency: Currency.Ars as string,
      amount,
      category,
      quotes: Number(data.quotes),
    })),
  });

  const mapSubmit = (formData: CreatePaymentFormInterface) => {
    onSubmit(formattedPaymentData(formData));
  };

  return (
    <Box
      autoComplete="off"
      component="form"
      noValidate
      onSubmit={handleSubmit(mapSubmit)}
    >
      <Grid container columnSpacing={{ sm: 7, lg: 14 }} mb={10}>
        <Grid item xs={12} sm={6} mb={5}>
          <ControlledSelect
            control={control}
            fieldLabel="Medio de pago *"
            fieldName="payment_methods"
            options={optionsPaymentMethods}
            rules={rulesConstants.select({ fieldLabel: 'Medio de pago' })}
            type="chip"
          />
        </Grid>
        <Grid item xs={12} sm={6} mb={5}>
          <ControlledSelect
            control={control}
            fieldLabel="Cantidad de cuotas *"
            fieldName="quotes"
            options={optionsQuotes}
            rules={rulesConstants.select({ fieldLabel: 'Cantidad de cuotas' })}
          />
        </Grid>
        <Grid
          item
          mb={2}
          sx={classes.presinscriptionSwitchContainer}
          xs={12}
        >
          <Typography
            lineHeight="16.94px"
            variant="h5"
          >
            Preinscripción
          </Typography>
          <ControlledSwitch
            control={control}
            fieldLabel={isPreinscription ? 'Si' : 'No'}
            fieldName="isPreinscription"
            onChange={(_, checked) => {
              if (!checked) setValue('preinscriptionAmount', '0');
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} mb={5.5}>
          <ControlledTextField
            control={control}
            disabled={!isPreinscription}
            fieldLabel="Monto"
            fieldName="preinscriptionAmount"
            rules={rulesConstants.preinscriptionAmountValidate()}
            startAdornment={<Box mr={0.5}>$</Box>}
            type="number"
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            lineHeight="16.94px"
            mb={2}
            variant="h5"
          >
            Arancel curso *
          </Typography>
          {categories.map((category, index) => (
            <Grid key={`category-${category.id}`} container columnSpacing={{ sm: 7, lg: 14 }} rowSpacing={4} mb={5}>
              <Grid item xs={12} sm={6}>
                <ControlledSelect
                  control={control}
                  fieldLabel="Categoría de usuario"
                  fieldName={`categories[${index}].category`}
                  options={optionsCategories}
                  rules={rulesConstants.select({ fieldLabel: 'Categoría de usuario' })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <ControlledTextField
                  control={control}
                  fieldLabel="Monto"
                  fieldName={`categories[${index}].amount`}
                  rules={rulesConstants.textfield({ fieldLabel: 'Monto' })}
                  startAdornment={<Box mr={0.5}>$</Box>}
                  type="number"
                />
              </Grid>
            </Grid>
          ))}
          <Box sx={classes.addCategoryContainer}>
            <Button
              onClick={addCategories}
              startIcon={<AddIcon />}
              sx={classes.addCategoryButton}
            >
              AGREGAR CATEGORÍA DE USUARIO
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Box sx={classes.buttonsContainer}>
        <Button
          color="primary"
          onClick={() => onClickBack(formattedPaymentData(getValues()))}
          type="submit"
          variant="outlined"
        >
          Volver
        </Button>
        <Button
          color="primary"
          disabled={!formState.isValid}
          type="submit"
          variant="contained"
        >
          {mode === 'create' ? 'Crear curso' : 'Editar curso'}
        </Button>
      </Box>
    </Box>
  );
};

export default memo(PaymentForm);
