const formatSpecialities = (specialities: string[]) => {
  const firstWord = specialities[0]
    .charAt(0).toUpperCase() + specialities[0].substring(1).toLowerCase();

  if (specialities.length === 1) {
    return firstWord;
  }

  return `${firstWord} +${specialities.length - 1}`;
};

export default formatSpecialities;
