import { memo, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import {
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import Layout from '../../components/Layouts/Layout';
import InscriptionSteps from '../../components/InscriptionSteps';
import { useCourses } from '../../contexts/courses/context';
import CourseInterface from '../../interfaces/courses';
import SelectPayment from './SelectPayment';

const Payments = () => {
  const { id: idCourse } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { fetchCourseById } = useCourses();
  const [courseLoading, setCourseLoading] = useState<boolean>(false);
  const [course, setCourse] = useState<CourseInterface>(state?.course);

  const hasCourseLoaded = course && Object.keys(course).length !== 0;

  useEffect(() => {
    if (
      (!state?.conceptUser && hasCourseLoaded && !course.preinscription)
      || !state?.tycApproved
    ) navigate(`/courses/${idCourse}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      if (!hasCourseLoaded) {
        setCourseLoading(true);
        const courseFetched = await fetchCourseById(idCourse);
        setCourse(courseFetched);
        setCourseLoading(false);
      }
    })();
  }, [course, idCourse, hasCourseLoaded, fetchCourseById]);

  return (
    <Layout>
      <Box sx={{ paddingX: { xs: '20px', sm: 0 } }}>
        <InscriptionSteps currentStep={3} />
      </Box>
      <SelectPayment loading={courseLoading} />
    </Layout>
  );
};

export default memo(Payments);
