const colors = {
  blue: '#0C4C90',
  violet: '#909BD7',
  black: '#1E1E1E',
  red: '#F44336',
  lightViolet: '#E2E5FA',
  white: '#FFFFFF',
  successGreen: '#6ADE8C',
  warningYellow: '#FFCF54',
  warningOrange: '#FF9900',
  lightGrey: '#F8F9FC',
  disabled: '#00000080',
};

export default colors;
