import { useLocation, Navigate } from 'react-router-dom';
import { ProtectedRouteProps } from '../../interfaces/auth';

const ProtectedRoute = ({ outlet }: ProtectedRouteProps) => {
  const { state, pathname } = useLocation();

  const isAuthenticated = localStorage.getItem('auth');
  if (!isAuthenticated) return <Navigate to="/login" state={{ ...state, previousURL: pathname }} />;

  return outlet;
};

export default ProtectedRoute;
