const classes = {
  selectPaymentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingX: { xs: '20px', sm: 0 },
    alignItems: { xs: 'center', sm: 'start' },
  },
  selectPaymentContainer: {
    maxWidth: { xs: '328px', sm: '100%' },
    width: '100%',
  },
};

export default classes;
