import React from 'react';
import { Typography, Box } from '@mui/material';

import RegisterForm from './RegisterForm';
import AuthLayout from '../../components/Layouts/AuthLayout';

const classes = {
  title: {
    textAlign: 'center',
  },
};

const Register = () => (
  <AuthLayout>
    <Box mb={4.5} sx={classes.title}>
      <Typography variant="h1" color="primary.main">
        Completa tus datos para crear tu cuenta
      </Typography>
    </Box>
    <RegisterForm />
  </AuthLayout>
);

export default React.memo(Register);
