import {
  Box, Button, List, ListItem, Typography,
} from '@mui/material';
import Layout from 'src/components/Layouts/Layout';
import SUsers from 'src/services/usersService';
import { useMessageModal } from 'src/contexts/messageModal/context';
import downloadReportXLSX from './downloadReportXLSX';

import LoadDropZone from './LoadDropZone';
import classes from './classes';

const BulkAMMUsers = () => {
  const { openMessageModal } = useMessageModal();

  const handleDownloadTemplate = async () => {
    const template = await SUsers.getTemplate();
    downloadReportXLSX(template);
  };

  const restoreLastTemplate = async () => {
    try {
      await SUsers.restoreLastTemplate();
      openMessageModal({
        title: '¡Plantilla recuperada con éxito!',
        message: 'Se recuperó la última plantilla cargada',
        variant: 'success',
        primaryButton: {
          text: 'Aceptar',
        },
      });
    } catch (error) {
      openMessageModal({
        title: error,
        variant: 'error',
        primaryButton: {
          text: 'Aceptar',
        },
      });
    }
  };

  return (
    <Layout>
      <h1>Alta masiva</h1>
      <Typography color="text.secondary" fontSize="14px">
        Podés cargar de manera masiva todos los usuarios en un solo archivo.
      </Typography>
      <Box sx={classes.buttonContainer}>
        <Button
          variant="outlined"
          onClick={restoreLastTemplate}
        >
          Recuperar la última plantilla cargada
        </Button>
        <Button
          variant="outlined"
          onClick={handleDownloadTemplate}
        >
          Descargar plantilla
        </Button>
      </Box>
      <Typography variant="h4" color="primary.main" marginBottom="20px">
        Pasos para el alta masiva:
      </Typography>
      <List sx={classes.list}>
        <ListItem sx={classes.listItem}>
          <strong>1. Descargá</strong>
        &nbsp;la plantilla de Excel
        </ListItem>
        <ListItem sx={classes.listItem}>
          <strong>2. Completá</strong>
        &nbsp;la plantilla con los datos correspondientes de cada columna
        </ListItem>
        <ListItem sx={classes.listItem}>
          <strong>3. Cargá</strong>
        &nbsp;nuevamente la plantilla al sistema para realizar el alta
        </ListItem>
      </List>
      <LoadDropZone />
    </Layout>
  );
};

export default BulkAMMUsers;
