import { PaymentMethod, TariffCategory, TeacherCategory } from '../../interfaces/courses';
import { TLogo } from '../../interfaces/logos';

export const DEFAULT_LOGOS: TLogo[] = [
  {
    id: 'IDHS_Logo',
    name: 'IDHS_Logo.svg',
    originalName: 'IDHS_Logo',
  },
  {
    id: 'AMM_Logo',
    name: 'AMM_Logo.svg',
    originalName: 'AMM_Logo',
  },
];

export const optionsPaymentMethods = [
  { id: PaymentMethod.Mercadopago, name: 'Mercado Pago' },
  { id: PaymentMethod.PaymentLink, name: 'Link de pago' },
];

export const optionsQuotes = [
  { id: '0', name: '0' },
  { id: '1', name: '1' },
  { id: '2', name: '2' },
  { id: '3', name: '3' },
  { id: '4', name: '4' },
  { id: '5', name: '5' },
  { id: '6', name: '6' },
  { id: '7', name: '7' },
  { id: '8', name: '8' },
  { id: '9', name: '9' },
  { id: '10', name: '10' },
  { id: '11', name: '11' },
  { id: '12', name: '12' },
];

export const optionsCategories = [
  {
    id: TariffCategory.ResidentAndConcurrentPartner,
    name: TariffCategory.ResidentAndConcurrentPartner,
  },
  {
    id: TariffCategory.ResidentAndConcurrent,
    name: TariffCategory.ResidentAndConcurrent,
  },
  {
    id: TariffCategory.DoctorPartner,
    name: TariffCategory.DoctorPartner,
  },
  {
    id: TariffCategory.Doctor,
    name: TariffCategory.Doctor,
  },
  {
    id: TariffCategory.Others,
    name: TariffCategory.Others,
  },
];

export const optionsRoleTeacher = [
  { id: TeacherCategory.Director, name: TeacherCategory.Director },
  { id: TeacherCategory.Directora, name: TeacherCategory.Directora },
  { id: TeacherCategory.Subdirector, name: TeacherCategory.Subdirector },
  { id: TeacherCategory.Subdirectora, name: TeacherCategory.Subdirectora },
  { id: TeacherCategory.Coordinador, name: TeacherCategory.Coordinador },
  { id: TeacherCategory.Coordinadora, name: TeacherCategory.Coordinadora },
  { id: TeacherCategory.CoordinadorAcademico, name: TeacherCategory.CoordinadorAcademico },
  { id: TeacherCategory.CoordinadoraAcademico, name: TeacherCategory.CoordinadoraAcademico },
  { id: TeacherCategory.Docente, name: TeacherCategory.Docente },
];

export const optionsModality = [
  { id: 'presencial', name: 'Presencial' },
  { id: 'virtual', name: 'Virtual' },
  { id: 'mixta', name: 'Modalidad Mixta' },
];

export const tooltipEmailTeacher = 'A través del email se vinculará el usuario del docente para que este curso figure dentro de sus cursos propios y pueda administrarlo. Podés dar de alta el usuario desde el menú "Usuarios".';
export const tooltipMoodle = 'A través del email se vinculará el usuario del docente para que este curso figure dentro de sus cursos propios y pueda administrarlo. Podés dar de alta el usuario desde el menú "Usuarios".';
export const tooltipVideo = 'Para que el link funcione correctamente, tienes que copiar el código de inserción que se encuentra desde <iframe> hasta </iframe>. Ejemplo: <iframe>código de prueba</iframe>.';

export const defaultTeacher = {
  category: TeacherCategory.Docente,
  email: '',
  name: '',
  signature: [],
};

export const defaultVideo = {
  link: '',
};
