import { Action } from '../../interfaces/auth';
import UserInterface from '../../interfaces/user';

const authReducer = (
  state: { auth: UserInterface },
  action: Action,
) => {
  switch (action.type) {
    case 'FETCH_AUTH': {
      return { auth: action.auth };
    }
    case 'LOGOUT': {
      return { auth: undefined };
    }
    default: {
      return state.auth;
    }
  }
};

export default authReducer;
