import { SCOPES } from '../../common/constants';

import { SideBarListSubItemInterface } from '../../interfaces/layout';

const usersItems: SideBarListSubItemInterface[] = [
  {
    text: 'Ver usuarios',
    itemDirectTo: '/users',
    itemScopes: [SCOPES.canSeeUsersList],
  },
  {
    text: 'Alta de usuarios',
    itemDirectTo: '/users/management',
    itemScopes: [SCOPES.canCreateUser],
  },
  {
    text: 'Validación socios AMM',
    itemDirectTo: '/users/AMM/bulk',
    itemScopes: [SCOPES.canCreateUser],
  },
];

const academicOfferItems: SideBarListSubItemInterface[] = [
  {
    text: 'Ver cursos',
    itemDirectTo: '/courses',
    itemScopes: [SCOPES.canEditCourses],
  },
  {
    text: 'Alta de cursos',
    itemDirectTo: '/courses/create',
    itemScopes: [SCOPES.canCreateCourses],
  },
  {
    text: 'Configuración',
    itemDirectTo: '/courses/configuration',
    itemScopes: [SCOPES.canCreateLogo, SCOPES.canRemoveLogo],
  },
];

const enrollmentsItems: SideBarListSubItemInterface[] = [
  {
    text: 'Ver inscriptos',
    itemDirectTo: '/enrollments',
    itemScopes: [SCOPES.canViewInscriptionsList],
  },
  {
    text: 'Alta de inscriptos',
    itemDirectTo: '/enrollments/create',
    itemScopes: [SCOPES.canCreateInscription],
  },
];

const evaluationsItems: SideBarListSubItemInterface[] = [
  {
    text: 'Calificaciones',
    itemDirectTo: '/qualifications',
    itemScopes: [SCOPES.canCreateEvaluations],
  },
];

const myCoursesItems: SideBarListSubItemInterface[] = [
  {
    text: 'Ver mis cursos',
    itemDirectTo: '/me/courses',
    itemScopes: [SCOPES.canViewOwnCourses],
  },
  {
    text: 'Certificados',
    itemDirectTo: '/certificates',
    itemScopes: [SCOPES.canViewOwnCertificates],
  },
];

export {
  academicOfferItems,
  enrollmentsItems,
  evaluationsItems,
  myCoursesItems,
  usersItems,
};
