import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import {
  FilterList as FilterListIcon,
} from '@mui/icons-material';

const classes = {
  filtersButton: {
    width: '135px',
    fontSize: '11px',
    fontWeight: 600,
    lineHeight: '10px',
    gap: 0,
    minWidth: '135px',
  },
};

interface ItemFilter {
  label: string;
  key: string;
  value: boolean | string;
  valueOff?: string;
  checked?: boolean;
}

const manipulateFilterString = (query: any, filter: ItemFilter): string => {
  const newArray: string[] = query[filter.key] ? query[filter.key].split(',') : [];
  if (filter.valueOff) {
    return filter.checked ? filter.value as string : filter.valueOff;
  }

  if (filter.checked) {
    newArray.push(filter.value as string);
    const arrayWithValuesRepeated = [...new Set(newArray)];
    return arrayWithValuesRepeated.join(',');
  }

  const arrayWithValuesRepeated = [...new Set(newArray)];
  return arrayWithValuesRepeated.filter((itemArray) => itemArray !== filter.value).join(',');
};

interface Props {
  query: any;
  setQuery: (newQuery: any) => void;
  options: ItemFilter[];
}

const FiltersButton = ({ setQuery, query, options }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const openFilters = Boolean(anchorEl);

  const handleOnClickFilters = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOnCloseFilters = () => {
    setAnchorEl(null);
  };

  const [filters, setFilters] = useState(options.map((option) => ({ ...option, checked: false })));

  const changeStateCheckbox = (indexCheckbox: number) => {
    setFilters(filters.map(
      (filter, indexFilter) => ({
        ...filter,
        checked: indexFilter === indexCheckbox ? !filter.checked : filter.checked,
      }),
    ));
  };

  useEffect(() => {
    const newQuery = query;
    filters.forEach((filter) => {
      if (typeof filter.value === 'string') {
        const newValue = manipulateFilterString(newQuery, filter);
        newQuery[filter.key] = newValue || undefined;
      } else {
        newQuery[filter.key] = filter.checked ? filter.value : undefined;
      }
    });
    setQuery({ ...query, ...newQuery });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return (
    <>
      <Button
        id="filter-courses"
        aria-controls={openFilters ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={openFilters ? 'true' : undefined}
        variant="outlined"
        endIcon={<FilterListIcon />}
        onClick={handleOnClickFilters}
        sx={classes.filtersButton}
      >
        FILTRAR POR
      </Button>
      <Menu
        id="filter-courses"
        anchorEl={anchorEl}
        open={openFilters}
        onClose={handleOnCloseFilters}
      >
        {filters.map((filter, index) => (
          <MenuItem
            // eslint-disable-next-line react/no-array-index-key
            key={`filter-${index}`}
            onClick={() => changeStateCheckbox(index)}
          >
            <Checkbox checked={filter.checked} />
            <Typography>{filter.label}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default React.memo(FiltersButton);
