import { useEffect } from 'react';
import {
  Box,
  CircularProgress,
  Typography,
} from '@mui/material';

import { useUsers } from '../../contexts/users/context';

import classes from './classes';

const Campus = () => {
  const { verifySession } = useUsers();

  const validateUserSession = async () => {
    const isValidSession = await verifySession();

    if (isValidSession) {
      window.location.replace(`${process.env.REACT_APP_API_URL}/campus`);
    }
  };

  useEffect(() => {
    validateUserSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={classes.loadingContainer}>
      <CircularProgress color="primary" size={70} />
      <Typography sx={classes.mainMessage}>Te estamos llevando al Campus Virtual</Typography>
      <Typography sx={classes.waitMessage}>Aguarda un momento</Typography>
    </Box>
  );
};

export default Campus;
