import React from 'react';
import { Controller } from 'react-hook-form';
import {
  FormControl,
  FormHelperText,
  Button,
  Typography,
  Box,
} from '@mui/material';
import { ControlledFileUploadProps } from '../../interfaces/inputs';

const classes = {
  formControl: { textAlign: 'start' },
  imageSize: { fontWeight: 400, lineHeight: '12px', mt: 0.5 },
  nameImage: { lineHeight: '12px', mt: 0.5 },
  subtitleContainer: (responsive: boolean) => ({
    display: 'flex',
    gap: 0.5,
    justifyContent: responsive ? { xs: 'center', sm: 'flex-start' } : 'flex-start',
  }),
};

const getFilenameFromURLString = (url: string): string => url?.slice(url.indexOf('static/') + 'static/'.length);

const ControlledFileUpload = ({
  control,
  fieldName,
  rules,
  label = '',
  defaultValue = undefined,
  responsive = false,
  hint = '',
}: ControlledFileUploadProps) => (
  <Controller
    control={control}
    name={fieldName}
    rules={rules}
    defaultValue={defaultValue}
    render={({ field, fieldState: { error } }) => {
      const isImageValueAnURL = typeof field.value === 'string';
      return (
        <FormControl
          sx={classes.formControl}
          variant="standard"
          error={!!error}
        >
          <Button variant="outlined" component="label">
            {label}
            <input
              hidden
              accept="image/*"
              type="file"
              onChange={(event) => field.onChange(event.target.files[0])}
            />
          </Button>
          <Box sx={classes.subtitleContainer(responsive)}>
            <Typography
              variant="subtitle2"
              sx={classes.imageSize}
            >
              {(field.value?.name) || isImageValueAnURL ? 'Archivo subido:' : hint}
            </Typography>
            {(field.value?.name || isImageValueAnURL) && (
            <Typography
              variant="subtitle1"
              sx={classes.nameImage}
            >
              {field.value?.name || getFilenameFromURLString(field.value)}
            </Typography>
            )}
          </Box>
          {error && (
          <FormHelperText color="error">
            {error.message}
          </FormHelperText>
          )}
        </FormControl>
      );
    }}
  />
);

export default React.memo(ControlledFileUpload);
