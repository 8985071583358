import {
  useReducer,
  createContext,
  useContext,
  useMemo,
  useCallback,
} from 'react';
import { useSnackbar } from 'notistack';
import _ from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';

import CourseInterface, {
  CoursesContextType, QueryGetCourses, ResGetCourses, TariffCategory,
} from '../../interfaces/courses';
import coursesReducer from './reducer';
import { ReactContainerProps } from '../../interfaces/props';
import coursesService from '../../services/coursesService';
import { usePayments } from '../payments/context';

const CoursesContext = createContext<CoursesContextType>(undefined);

export const useCourses = () => {
  const context = useContext(CoursesContext);
  const navigate = useNavigate();
  const { state: locationState } = useLocation();
  const { state: paymentState } = usePayments();
  const { enqueueSnackbar } = useSnackbar();

  if (context === undefined) {
    throw new Error('useCourses must be used within a CoursesProvider');
  }

  const { state, dispatch } = context;

  const fetchCourses = async (query?: QueryGetCourses): Promise<ResGetCourses> => {
    try {
      const courses = await coursesService.getAll(query);
      return courses;
    } catch (e) {
      enqueueSnackbar('Oops! Error inesperado', { variant: 'error' });
      return null;
    }
  };

  const fetchCourseById = useCallback(async (id: string): Promise<CourseInterface> => {
    try {
      const course = await coursesService.getById(id);
      return course;
    } catch (e) {
      enqueueSnackbar('Oops! Error inesperado', { variant: 'error' });
      return null;
    }
  }, [enqueueSnackbar]);

  const fetchCoursesPublished = async (query?: QueryGetCourses): Promise<CourseInterface[]> => {
    try {
      const coursesPublished = await coursesService.getPublished(query);
      return coursesPublished || [];
    } catch (e) {
      enqueueSnackbar('Oops! Error al obtener los cursos publicados', { variant: 'error' });
      return [];
    }
  };

  const createCourse = async (course: FormData): Promise<string> => {
    try {
      await coursesService.create(course);
      return 'OK';
    } catch (e) {
      enqueueSnackbar('Oops! Error inesperado', { variant: 'error' });
      return null;
    }
  };

  const updateCourse = async (id: string, course: FormData): Promise<string> => {
    try {
      await coursesService.update(id, course);
      enqueueSnackbar('Curso actualizado con éxito!', { variant: 'success' });
      navigate('/courses');

      return 'OK';
    } catch (e) {
      enqueueSnackbar('Oops! Error inesperado', { variant: 'error' });
      return null;
    }
  };

  const isPreinscriptionPayment = useCallback(
    (): boolean => Boolean(locationState?.course?.preinscription),
    [locationState],
  );

  const getAmount = useCallback((course?: CourseInterface): string => {
    const courseToCalculate = course || locationState?.course;

    if (courseToCalculate && Object.keys(courseToCalculate).length > 0) {
      if (isPreinscriptionPayment()) return courseToCalculate.preinscription.amount;

      const userType = locationState?.conceptUser || paymentState.payment?.conceptUser;

      const foundTariffBasedOnUserType = courseToCalculate.tariff.find(
        (tariff) => tariff.category === userType,
      ) || courseToCalculate.tariff.find(
        (tariff) => tariff.category === TariffCategory.Others,
      );

      return foundTariffBasedOnUserType.amount;
    }
    return null;
  }, [isPreinscriptionPayment, locationState, paymentState]);

  const getQuotes = useCallback((course?: CourseInterface): number => {
    const courseToCalculate = course || locationState?.course;
    if (courseToCalculate && Object.keys(courseToCalculate).length > 0) {
      if (isPreinscriptionPayment()) return 1;

      const userType = locationState?.conceptUser || paymentState.payment?.conceptUser;

      const foundTariffBasedOnUserType = courseToCalculate.tariff.find(
        (tariff) => tariff.category === userType,
      ) || courseToCalculate.tariff.find(
        (tariff) => tariff.category === TariffCategory.Others,
      );

      return foundTariffBasedOnUserType.quotes;
    }
    return null;
  }, [isPreinscriptionPayment, locationState, paymentState]);

  const removeCourse = async (idCourse: string): Promise<CourseInterface> => {
    try {
      return await coursesService.remove(idCourse);
    } catch (e) {
      enqueueSnackbar('Oops! Error al borrar el curso de la lista de espera.', { variant: 'error' });
      return null;
    }
  };

  const exportCourseToMoodle = async (idCourse: string): Promise<CourseInterface> => {
    try {
      const exportedCourse = await coursesService.exportCourse(idCourse);
      enqueueSnackbar('Curso exportado a Moodle exitosamente.', { variant: 'success' });
      return exportedCourse;
    } catch (e) {
      enqueueSnackbar('Oops! Error al exportar curso a Moodle.', { variant: 'error' });
      return null;
    }
  };

  return {
    state,
    fetchCourses,
    createCourse,
    dispatch,
    getQuotes,
    getAmount,
    isPreinscriptionPayment,
    fetchCoursesPublished,
    fetchCourseById,
    removeCourse,
    exportCourseToMoodle,
    updateCourse,
  };
};

const CoursesProvider = ({ children }: ReactContainerProps) => {
  const [state, dispatch] = useReducer(coursesReducer, {
    courses: [],
    course: {},
  });

  const value = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
    <CoursesContext.Provider value={value as CoursesContextType}>
      {children}
    </CoursesContext.Provider>
  );
};

export default CoursesProvider;
