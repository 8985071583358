import React, { useState } from 'react';
import Box from '@mui/material/Box';

import { ReactContainerProps } from '../../interfaces/props';
import Navbar from '../Navbars/Navbar';
import Sidebar from '../Navbars/Sidebar';

const classes = {
  layoutWrapper: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  bodyContainer: {
    display: 'flex',
    flexGrow: 1,
  },
  body: (marginContainer: boolean, center: boolean) => ({
    mt: marginContainer ? {
      xs: 2,
      sm: 4,
    } : 0,
    mx: marginContainer ? {
      xs: 1,
      sm: 8,
    } : 0,
    pb: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: center ? 'center' : 'flex-start',
    width: '100%',
    overflowX: 'auto',
  }),
};

const Layout = ({ children, marginContainer = true, center = false }: ReactContainerProps) => {
  const [mobileSidebarOpen, setMobileSideBarOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileSideBarOpen(!mobileSidebarOpen);
  };

  return (
    <Box sx={classes.layoutWrapper}>
      <Navbar handleDrawerToggle={handleDrawerToggle} />

      <Box sx={classes.bodyContainer}>
        <Sidebar
          isMobileOpen={mobileSidebarOpen}
          handleDrawerToggle={handleDrawerToggle}
        />
        <Box sx={classes.body(marginContainer, center)}>
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(Layout);
