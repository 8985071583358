import { memo, useState } from 'react';
import {
  Backdrop, Box, Button, CircularProgress, Typography,
} from '@mui/material';
import { useEnrollments } from '../../contexts/enrollments/context';

const classes = {
  cardContainer: {
    width: 320,
    height: 325,
    boxShadow: '0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12)',
    bgcolor: 'primary.light',
    px: 2.25,
    pt: 3,
    pb: 3.5,
    boxSizing: 'border-box',
  },
  imageWrapper: {
    height: '100%',
    maxHeight: '175px',
  },
  contentContainer: {
    height: '100%',
    maxHeight: '98px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    wordWrap: 'break-word',
  },
};

interface CertificateCardProps {
  id: string;
  image: string;
  title: string;
}

const CertificateCard = ({ id, image, title }: CertificateCardProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { downloadCertificate } = useEnrollments();

  const downloadCertificatePdf = async () => {
    setIsLoading(true);
    await downloadCertificate(id, title);
    setIsLoading(false);
  };

  return (
    <Box sx={classes.cardContainer}>
      <Box sx={classes.imageWrapper}>
        <img src={image} alt={title} style={{ width: '100%', maxHeight: '175px' }} />
      </Box>
      <Box sx={classes.contentContainer}>
        <Typography variant="h3" color="primary.main" my={1.5}>
          {title}
        </Typography>
        <Button variant="contained" fullWidth onClick={downloadCertificatePdf}>
          DESCARGAR CERTIFICADO
        </Button>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </Box>
  );
};

export default memo(CertificateCard);
