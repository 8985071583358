import React from 'react';
import {
  Box,
  Grid,
  Typography,
  Button,
  Modal,
  IconButton,
} from '@mui/material';
import {
  Close as CloseIcon,
} from '@mui/icons-material';

enum TypeModalEnum {
  TYC = 'tyc',
  UNSUBSCRIBE = 'unsubscribe',
  WAITING_LIST = 'waiting_list',
  REMOVE_ENROLLMENT = 'removeEnrollment',
  REMOVE = 'remove',
}

export type TypeModal = 'tyc' | 'unsubscribe' | 'waiting_list' | 'remove' | 'removeEnrollment';

interface Props {
  typeModal: TypeModal;
  openModal: boolean;
  onCloseModal: () => void;
  onClickButton: () => void;
}

const classes = {
  modalContainer: (typeModal: TypeModal) => ({
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    p: typeModal === TypeModalEnum.TYC ? 4 : 2,
    boxShadow: '0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12)',
    borderRadius: '0px',
    maxWidth: '570px',
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: typeModal === TypeModalEnum.TYC ? 2 : 0,
  }),
  modalTitle: (typeModal: TypeModal) => ({
    fontWeight: 600,
    textAlign: typeModal === TypeModalEnum.TYC ? { xs: 'center', sm: 'left' } : 'center',
  }),
  modalDescription: (typeModal: TypeModal) => ({
    textAlign: typeModal === TypeModalEnum.UNSUBSCRIBE ? 'center' : { xs: 'center', sm: 'left' },
  }),
  modalButtonContainer: {
    marginTop: 1,
    width: '100%',
    gap: { xs: 2, sm: 4 },
  },
  iconCloseContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
  },
  iconCloseButton: {
    padding: 0,
  },
  iconClose: {
    color: '#909BD7',
  },
  modalUnsubscribeContentContainer: {
    paddingTop: 1,
    paddingX: 4,
    paddingBottom: 4,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
  modalUnsubscribeButton: {
    bgcolor: '#F03A47',
  },
};

const contentModal = {
  tyc: {
    title: 'Términos y condiciones',
    description: `Antes de comenzar el curso se abonará una preinscripción, y hasta 24hs antes de la primer clase, se debe abonar la primer cuota, de no ser abonada se dará de baja la inscripción y la vacante pasará a ser liberada.
    Antes de comenzar el curso se abonará una preinscripción, y hasta 24hs antes de la primer clase, se debe abonar la primer cuota, de no ser abonada se dará de baja la inscripción y la vacante pasará a ser liberada.
    
    Antes de comenzar el curso se abonará una preinscripción, y hasta 24hs antes de la primer clase, se debe abonar la primer cuota, de no ser abonada se dará de baja la inscripción y la vacante pasará a ser liberada.`,
    primaryButton: 'Aceptar',
    secondaryButton: 'Cerrar',
  },
  unsubscribe: {
    title: 'Para darte de baja de este curso comunicate con IDHS',
    description: `Envia un correo electrónico a ${process.env.REACT_APP_EMAIL_IDHS} indicando el motivo de la baja del curso y seguí las indicaciones que se te brinden`,
    primaryButton: '',
    secondaryButton: 'Cerrar',
  },
  waiting_list: {
    title: '¿Estás seguro que querés darte de baja de la lista de espera?',
    description: '',
    primaryButton: 'Si, dar de baja',
    secondaryButton: 'Volver',
  },
  removeEnrollment: {
    title: '¿Estás seguro que querés dar de baja esta inscripción?',
    description: '',
    primaryButton: 'Si, dar de baja',
    secondaryButton: 'Volver',
  },
  remove: {
    title: '¿Estás seguro/a que querés eliminar este curso?',
    description: 'Recordá que no podés deshacer esta acción, se eliminará todo el contenido',
    primaryButton: 'Si, eliminar',
    secondaryButton: 'Volver',
  },
};

const ModalCourse = ({
  typeModal, openModal, onCloseModal, onClickButton,
}: Props) => {
  if (typeModal === TypeModalEnum.WAITING_LIST
     || typeModal === TypeModalEnum.REMOVE
     || typeModal === TypeModalEnum.REMOVE_ENROLLMENT
  ) {
    return (
      <Modal
        open={openModal}
        onClose={onCloseModal}
        aria-labelledby={`${TypeModalEnum.WAITING_LIST}-modal-title`}
        aria-describedby={`${TypeModalEnum.WAITING_LIST}-modal-description`}
      >
        <Box sx={classes.modalContainer(typeModal)}>
          <Box sx={classes.iconCloseContainer}>
            <IconButton
              size="large"
              onClick={onCloseModal}
              sx={classes.iconCloseButton}
            >
              <CloseIcon sx={classes.iconClose} />
            </IconButton>
          </Box>
          <Box sx={classes.modalUnsubscribeContentContainer}>
            <Typography variant="h2" color="primary.main" sx={classes.modalTitle(typeModal)}>
              {contentModal[typeModal].title}
            </Typography>
            {contentModal[typeModal].description && (
            <Typography variant="h5" align="center">
              {contentModal[typeModal].description}
            </Typography>
            )}
            <Grid container sx={classes.modalButtonContainer}>
              {contentModal[typeModal].primaryButton && (
              <Grid item flexGrow={1}>
                <Button variant="contained" fullWidth onClick={onClickButton} color="error" sx={classes.modalUnsubscribeButton}>
                  {contentModal[typeModal].primaryButton}
                </Button>
              </Grid>
              )}
              <Grid item flexGrow={1}>
                <Button variant="outlined" fullWidth onClick={onCloseModal}>
                  {contentModal[typeModal].secondaryButton}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
    );
  }

  if (typeModal === TypeModalEnum.UNSUBSCRIBE) {
    return (
      <Modal
        open={openModal}
        onClose={onCloseModal}
        aria-labelledby={`${TypeModalEnum.UNSUBSCRIBE}-modal-title`}
        aria-describedby={`${TypeModalEnum.UNSUBSCRIBE}-modal-description`}
      >
        <Box sx={classes.modalContainer(typeModal)}>
          <Box sx={classes.iconCloseContainer}>
            <IconButton
              size="large"
              onClick={onCloseModal}
              sx={classes.iconCloseButton}
            >
              <CloseIcon sx={classes.iconClose} />
            </IconButton>
          </Box>
          <Box sx={classes.modalUnsubscribeContentContainer}>
            <Typography variant="h2" color="primary.main" sx={classes.modalTitle(typeModal)}>
              {contentModal[typeModal].title}
            </Typography>
            <Typography variant="h5" sx={classes.modalDescription(typeModal)}>
              {contentModal[typeModal].description}
            </Typography>
            <Grid container sx={classes.modalButtonContainer}>
              {contentModal[typeModal].primaryButton && (
              <Grid item flexGrow={1}>
                <Button variant="contained" fullWidth onClick={onClickButton} color="error" sx={classes.modalUnsubscribeButton}>
                  {contentModal[typeModal].primaryButton}
                </Button>
              </Grid>
              )}
              <Grid item flexGrow={1}>
                <Button variant="outlined" fullWidth onClick={onCloseModal}>
                  {contentModal[typeModal].secondaryButton}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
    );
  }

  return (
    <Modal
      open={openModal}
      onClose={onCloseModal}
      aria-labelledby={`${TypeModalEnum.TYC}-modal-title`}
      aria-describedby={`${TypeModalEnum.TYC}-modal-description`}
    >
      <Box sx={classes.modalContainer(typeModal)}>
        <Typography variant="h2" color="primary.main" sx={classes.modalTitle(typeModal)}>
          {contentModal[typeModal].title}
        </Typography>
        <Typography variant="h5" sx={classes.modalDescription(typeModal)}>
          {contentModal[typeModal].description}
        </Typography>
        <Grid container sx={classes.modalButtonContainer}>
          <Grid item flexGrow={1}>
            <Button variant="contained" fullWidth onClick={onClickButton}>
              {contentModal[typeModal].primaryButton}
            </Button>
          </Grid>
          <Grid item flexGrow={1}>
            <Button variant="outlined" fullWidth onClick={onCloseModal}>
              {contentModal[typeModal].secondaryButton}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default React.memo(ModalCourse);
