import CourseInterface, { Action } from '../../interfaces/courses';

const coursesReducer = (
  state: { courses: CourseInterface[], course: Partial<CourseInterface> },
  action: Action,
) => {
  switch (action.type) {
    case 'FETCH_COURSES': {
      return { ...state, courses: action.courses };
    }
    case 'GET_COURSE': {
      return { ...state, course: action.course };
    }
    default: {
      return state;
    }
  }
};

export default coursesReducer;
