import { useEffect, useState } from 'react';
import { MercadoPago, MercadoPagoInstance } from '../../../interfaces/mercadopago';

declare global {
  interface Window {
    MercadoPago: any;
  }
}

// Este hook es del viejo form de Mercadopago, el cual es reemplazado
// por la opcion de Pagos Recurrentes con MP. Se deja y no se borra, por si
// se decide reutilizar nuevamente.
const useMercadopago = (): MercadoPagoInstance => {
  const [loading, setLoading] = useState<boolean>(true);
  const [mercadopago, setMercadopago] = useState<MercadoPago>(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://sdk.mercadopago.com/js/v2';
    script.async = true;
    script.onload = () => {
      setMercadopago(new window.MercadoPago(process.env.REACT_APP_MERCADOPAGO_PUBLIC_KEY, {
        locale: 'es-AR',
      }));
      setLoading(false);
    };
    document.body.appendChild(script);
  }, []);

  return {
    loading,
    setLoading,
    mercadopago,
  };
};

export default useMercadopago;
