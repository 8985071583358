const classes = {
  errorText: {
    color: 'error.main',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  dropzone: {
    border: '1px dashed #ccc',
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'center',
    width: '619px',
    height: '220px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    cursor: 'pointer',
  },
  uploadText: {
    fontSize: '14px',
    fontWeight: '600',
    color: 'text.primary',
  },
  uploadSpan: {
    cursor: 'pointer',
    color: 'primary.main',
    textDecoration: 'underline',
  },
  buttonContainer: {
    margin: '35px 0px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '10px',
  },
  list: {
    padding: '0px',
    marginBottom: '62px',
  },
  listItem: {
    paddingTop: '0px',
  },
  dropzoneContainer: {
    width: '619px',
    height: '227px',
    display: 'flex',
    textAlign: 'center',
    borderRadius: '4px',
    marginBottom: '9px',
    border: '1px dashed',
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: 'text.disabled',
  },
  label: {
    width: '100%',
    height: '100%',
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  fileIcon: {
    width: '69px',
    height: '62px',
    border: 'none',
    margin: '0 auto 13px auto',
    backgroundImage: 'url("/assets/fileicon.png")',
  },
  infoRow: {
    width: '619px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  uploadTextButton: {
    fontSize: 9,
    textTransform: 'uppercase',
    lineHeight: '15px',
  },
  main: {
    backgroundColor: '#F8F8F8',
    maxWidth: 550,
    paddingTop: 32,
  },
  successAlertContainer: {
    width: 209,
    height: 23,
    marginTop: 8,
  },
  uploadButton: {
    width: '137px',
    height: '25px',
    backgroundColor: '#F16C24',
    color: '#fff',
    cursor: 'pointer',
    padding: '5px 15px',
    borderRadius: '30px',
  },
  uploadContainer: {
    textAlign: 'center',
  },
  justifyButton: {
    display: 'flex',
    justifyContent: 'center',
  },
};

export default classes;
