const formatterPesos = new Intl.NumberFormat('es-AR', {
  style: 'currency',
  currency: 'ARS',
});

export const formatPrice = (price: string) => formatterPesos
  .format(parseFloat(price))
  .replace(/\D00$/, '')
  .replace(/\s/g, '');

export const formatPriceBasedOnInstallments = (price: string, installment: number) => {
  const installmentPrice = parseFloat(price) / installment;
  return formatPrice(String(installmentPrice));
};
