import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { LoadingButtonProps } from '../../interfaces/controls';

const LoadingButton = ({
  disabled, children, loading, fullWidth, ...other
}: LoadingButtonProps) => (
  <Button
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...other}
    fullWidth={fullWidth}
    disabled={loading || disabled}
  >
    {loading ? <CircularProgress size={24} /> : children}
  </Button>
);

export default React.memo(LoadingButton);
