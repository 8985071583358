import PaymentInterface, { Action, TypeAction } from '../../interfaces/payments';

const paymentsReducer = (
  state: { payment: Partial<PaymentInterface> },
  action: Action,
) => {
  switch (action.type) {
    case TypeAction.REQUESTING_DATA: {
      return { payment: null };
    }
    case TypeAction.GET_PAYMENT: {
      return { ...state, payment: action.payment };
    }
    default: {
      return state;
    }
  }
};

export default paymentsReducer;
