const classes = {
  modalContainer: {
    alignItems: 'left',
    bgcolor: 'background.paper',
    borderRadius: '0px',
    boxShadow: '0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12)',
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    justifyContent: 'left',
    left: '50%',
    maxWidth: '502px',
    p: 2,
    position: 'absolute' as 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
  },
  dataContainer: {
    display: 'flex',
    gap: 0.5,
  },
  observationContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 0.5,
  },
  modalContentContainer: {
    alignItems: 'left',
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    justifyContent: 'left',
    pb: 4,
    pt: 1,
    px: 4,
  },
  modalTitle: {
    fontWeight: 600,
  },
  modalButtonContainer: {
    gap: { xs: 2, sm: 4 },
    marginTop: 1,
    width: '100%',
  },
  closeButtonContainer: {
    display: 'flex',
    justifyContent: 'end',
    width: '100%',
  },
  closeIconButton: {
    color: '#909BD7',
    padding: 0,
  },
};

export default classes;
