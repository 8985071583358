import './wdyr';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import dayjs from 'dayjs';
import 'dayjs/locale/ar';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

import App from './App';
import theme from './core-ui/theme';
import IDHSProvider from './contexts/IDHSProvider';

dayjs.locale('ar');
dayjs.extend(isSameOrAfter);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <IDHSProvider>
          <SnackbarProvider maxSnack={3}>
            <App />
          </SnackbarProvider>
        </IDHSProvider>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>,
);
