import { installmentDisplayPreinscription, InstallmentDisplayStatuses, installmentPreinscription } from '../../../interfaces/payments';

import { FiltersExportReport } from './types';

export const statusesOptions = Object
  .values(InstallmentDisplayStatuses)
  .map((value, i) => ({ id: String(i), name: value }));

export const installmentsOptions = [
  { id: installmentPreinscription, name: installmentDisplayPreinscription },
  ...Array
    .from(
      { length: 12 },
      (_, i) => ({ id: `${i + 1}`, name: `Cuota: ${i + 1}` }),
    ),
];

export const initialValues: FiltersExportReport = {
  courses: [],
  installmentStatuses: [],
  installmentsNumber: [],
  paymentDateFrom: null,
  paymentDateTo: null,
  students: [],
};
