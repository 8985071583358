import {
  ChangeEvent,
  FormEvent,
  memo,
  useMemo,
  useState,
} from 'react';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

import { useAuth } from '../../../contexts/auth/context';
import { useMessageModal } from '../../../contexts/messageModal/context';
import { useUsers } from '../../../contexts/users/context';

import paymentsService from '../../../services/paymentsService';

import EditPaymentEmailModal from '../../../components/Modals/EditPaymentEmail';

import { ERROR_CANNOT_OPERATE_BETWEEN_DIFFERENT_COUNTRIES } from '../../../common/errors';

import { CreatePaymentInterface, PaymentMethod } from '../../../interfaces/payments';
import { TPaymentEmail, TProps } from './types';

import classes from './classes';

const SelectPaymentForm = ({ value, setValue }: TProps) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { openMessageModal } = useMessageModal();
  const { enqueueSnackbar } = useSnackbar();
  const { state: { auth: user } } = useAuth();
  const {
    updatePaymentEmail,
  } = useUsers();
  const {
    control,
    handleSubmit,
    reset,
  } = useForm<TPaymentEmail>({
    defaultValues: useMemo(() => ({
      paymentEmail: '',
    }), []),
  });
  const [isloading, setIsLoading] = useState(false);
  const [openEditPaymentEmailModal, setOpenEditPaymentEmailModal] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);

  const hasCourseLoaded = state && Object.keys(state.course).length !== 0;
  const hasCourseAMercadoPagoTariff: boolean = hasCourseLoaded && state.course.payment_methods
    .find((payment_method: PaymentMethod) => payment_method === PaymentMethod.Mercadopago);
  const hasCourseAPaymentLinkTariff: boolean = hasCourseLoaded && state.course.payment_methods
    .find((payment_method: PaymentMethod) => payment_method === PaymentMethod.PaymentLink);

  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setValue((event.target as HTMLInputElement).value);
    setHasErrors(false);
  };

  const handlePaymentLink = (): void => {
    setIsLoading(true);

    const payment: CreatePaymentInterface = {
      course: state.course.id,
      method: PaymentMethod.PaymentLink,
      conceptUser: state.conceptUser,
    };

    paymentsService.create(payment)
      .then(() => {
        setIsLoading(false);
        openMessageModal({
          title: 'VACANTE RESERVADA',
          message: 'Previo al inicio le enviaremos por mail el link con el que se suscribirá al pago mensual del curso.',
          variant: 'success',
          primaryButton: {
            text: 'Aceptar',
            onClick: () => navigate('/courses'),
          },
          closeButton: () => navigate('/courses'),
        });
      })
      .catch((error) => {
        setIsLoading(false);
        enqueueSnackbar(error, { variant: 'error' });
      });
  };

  const handleClose = () => setOpenEditPaymentEmailModal(false);

  const handleMercadoPagoPayment = (): void => {
    setIsLoading(true);

    const payment: CreatePaymentInterface = {
      conceptUser: state.conceptUser,
      course: state.course.id,
      method: PaymentMethod.MercadopagoSubscription,
    };

    paymentsService.create(payment)
      .then(({ redirect }: { redirect: string }) => window.location.replace(redirect))
      .catch((error) => {
        if (error === ERROR_CANNOT_OPERATE_BETWEEN_DIFFERENT_COUNTRIES) {
          setOpenEditPaymentEmailModal(true);
        } else {
          enqueueSnackbar(error, { variant: 'error' });
        }
      })
      .finally(() => setIsLoading(false));
  };

  const handleUpdate = async ({ paymentEmail }: TPaymentEmail) => {
    const updatePaymentEmailResponse = await updatePaymentEmail(user.id, { paymentEmail });

    if (updatePaymentEmailResponse) {
      handleClose();
      reset();
      handleMercadoPagoPayment();
    }
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    if (value === 'mercadopago' && hasCourseAMercadoPagoTariff) handleMercadoPagoPayment();
    else if (value === 'paymentLink' && hasCourseAPaymentLinkTariff) handlePaymentLink();
    else setHasErrors(true);
  };

  return (
    <Box
      component="form"
      mb={1.5}
      mt={2.5}
      onSubmit={onSubmit}
      sx={classes.selectPaymentForm}
    >
      <FormControl
        error={hasErrors}
        sx={classes.selectPaymentFormControl}
        variant="standard"
      >
        <RadioGroup
          aria-labelledby="form-pagos"
          name="paymentMethods"
          onChange={handleRadioChange}
          sx={classes.selectPaymentFormRadioGroup}
          value={value}
        >
          {hasCourseAMercadoPagoTariff && (
            <FormControlLabel
              control={<Radio size="small" sx={classes.radio} />}
              label={(
                <Typography variant="h5" display="inline">
                  Pago con
                  {' '}
                  <Typography component="span" sx={classes.textBold}>tarjeta de débito/crédito</Typography>
                  {' '}
                  o dinero en cuenta de MercadoPago
                </Typography>
              )}
              sx={classes.selectPaymentFormControlLabel}
              value="mercadopago"
            />
          )}
          {hasCourseAPaymentLinkTariff && (
            <>
              <FormControlLabel
                control={<Radio size="small" sx={classes.radio} />}
                label={(
                  <Typography variant="h5" display="inline">
                    Pago por
                    {' '}
                    <Typography component="span" sx={classes.textBold}>link</Typography>
                  </Typography>
                )}
                sx={{
                  ...classes.selectPaymentFormControlLabel,
                  marginTop: 2,
                }}
                value="paymentLink"
              />
              <Box ml={4.5}>
                <Typography variant="subtitle1" color="text.disabled">
                  Se le enviarán por mail las indicaciones
                </Typography>
              </Box>
            </>
          )}
          {hasErrors && (<FormHelperText sx={{ pl: '12px' }}>Debe seleccionar un medio de pago</FormHelperText>)}
        </RadioGroup>
        <Button
          disabled={
            !hasCourseLoaded
            || (!hasCourseAMercadoPagoTariff && !hasCourseAPaymentLinkTariff)
          }
          fullWidth
          type="submit"
          variant="contained"
        >
          ADQUIRIR CURSO
        </Button>
      </FormControl>
      <Backdrop open={isloading}>
        <CircularProgress size={48} />
      </Backdrop>
      <EditPaymentEmailModal
        control={control}
        isOpen={openEditPaymentEmailModal}
        onClick={handleSubmit(handleUpdate)}
        onClose={handleClose}
      />
    </Box>
  );
};

export default memo(SelectPaymentForm);
