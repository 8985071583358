import React, { useState } from 'react';
import { AppBar, Toolbar, Button } from '@mui/material';

const classes = {
  appBar: {
    bgcolor: 'primary.main',
    color: 'background.paper',
    height: '48px',
    minHeight: '48px',
    boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)',
    overflowX: 'auto',
    overflowY: 'hidden',
  },
  toolBar: {
    maxHeight: '48px',
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
  },
  button: {
    height: '100%',
    minWidth: '205px',
    boxShadow: 'none',
    '&:hover': { boxShadow: 'none', opacity: '100%', bgcolor: 'inherit' },
    '&.Mui-disabled': {
      bgcolor: 'inherit', color: 'inherit', borderBottom: '2px solid #E2E5FA', opacity: '100%',
    },
    fontWeight: 400,
    opacity: '70%',
    fontSize: '14px',
    lineHeight: '14px',
    letterSpacing: '0.5px',
    textAlign: 'center',
  },
};

interface Props {
  items: { text: string, onClick: () => void }[];
  itemSelected?: (itemSelected: string) => void;
}

interface ItemsButtonProps {
  text: string;
  onClick: () => void;
  selected: boolean;
}

const NavbarCourse = ({ items, itemSelected }: Props) => {
  const [itemsButton, setItemsButton] = useState<ItemsButtonProps[]>(items.map(
    ({ text, onClick }, index) => ({
      text,
      onClick,
      selected: index === 0,
    }),
  ));

  const changeSelectedItem = (index: number) => {
    setItemsButton(
      itemsButton.map(
        (itemButton, indexItem) => ({ ...itemButton, selected: index === indexItem }),
      ),
    );
    if (itemSelected) itemSelected(itemsButton[index].text);
  };

  return (
    <AppBar
      position="static"
      sx={classes.appBar}
    >
      <Toolbar variant="dense" sx={classes.toolBar}>
        {itemsButton.map(({ onClick, text, selected }, index) => (
          <Button
            key={text}
            variant="contained"
            sx={classes.button}
            disabled={selected}
            onClick={() => {
              changeSelectedItem(index);
              onClick();
            }}
          >
            {text}
          </Button>
        ))}
      </Toolbar>
    </AppBar>
  );
};

export default React.memo(NavbarCourse);
