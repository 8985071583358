import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Button,
  IconButton,
  Modal,
  Typography,
} from '@mui/material';
import {
  Close as CloseIcon,
} from '@mui/icons-material';

import { useCourses } from '../../../contexts/courses/context';
import { useUsers } from '../../../contexts/users/context';

import ControlledAutocomplete from '../../Inputs/ControlledAutocomplete';
import ControlledDateField from '../../Inputs/ControlledDateField';

import {
  FiltersExportReport as PaymentsFiltersExportReport,
  InstallmentDisplayStatuses,
  InstallmentStatuses,
  installmentDisplayPreinscription,
  installmentPreinscription,
} from '../../../interfaces/payments';
import CourseInterface from '../../../interfaces/courses';
import UserInterface from '../../../interfaces/user';

import rulesConstants from '../../../helpers/rulesConstants';

import { FiltersExportReport, Props } from './types';

import { initialValues, installmentsOptions, statusesOptions } from './constants';

import classes from './classes';

const ExportModal = ({ onCloseModal, onSubmit, openModal }: Props) => {
  const { fetchCourses } = useCourses();
  const { fetchUsers } = useUsers();

  const [courses, setCourses] = useState<CourseInterface[]>([]);
  const [users, setUsers] = useState<UserInterface[]>([]);

  const {
    control,
    getValues,
    handleSubmit,
    reset,
    watch,
  } = useForm<FiltersExportReport>({
    defaultValues: useMemo(() => initialValues, []),
  });

  const installmentStatuses = watch('installmentStatuses');

  const isPaymentDateDisabled = !(installmentStatuses
    .includes(InstallmentDisplayStatuses.approved) || !installmentStatuses.length);

  const coursesName = useMemo(() => courses.map(({ id, internalCourseId, name }) => ({ id, name: `${internalCourseId} - ${name}` })), [courses]);
  const usersDocuments = useMemo(() => users.map(({
    document,
    first_name,
    id,
    last_name,
  }) => ({ id, name: `${document} - ${first_name} ${last_name}` })), [users]);

  const getCourses = async () => {
    const coursesFetched = await fetchCourses({ limit: 0 });

    if (coursesFetched?.courses.length) setCourses(coursesFetched?.courses);
  };

  const getUsers = async () => {
    const usersFetched = await fetchUsers({ limit: 0 });

    if (usersFetched?.users.length) setUsers(usersFetched?.users);
  };

  const buildQuery = (formData: FiltersExportReport) => {
    const filteredCoursesInternalIds = formData?.courses?.map((course) => course.split('-')[0].trim());
    const filteredUsersDocuments = formData?.students?.map((student) => student.split('-')[0].trim());

    const query: PaymentsFiltersExportReport = {
      ...formData,
      courses: formData?.courses?.length > 0
        ? courses
          .filter(({
            internalCourseId,
          }) => filteredCoursesInternalIds.includes(String(internalCourseId)))
          .map(({ id }) => id)
        : [],
      installmentStatuses: formData.installmentStatuses
        .map((status) => {
          if (status === InstallmentDisplayStatuses.pending) return InstallmentStatuses.Pending;
          if (status === InstallmentDisplayStatuses.cancelled) return InstallmentStatuses.Cancelled;

          return InstallmentStatuses.Approved;
        }),
      students: filteredUsersDocuments?.length > 0 ? filteredUsersDocuments : [],
      installmentsNumber:
        formData.installmentsNumber
          .map((installment) => installment
            .replace('Cuota: ', '')
            .replace(installmentDisplayPreinscription, installmentPreinscription)),
    };

    onSubmit(query);
  };

  useEffect(() => {
    getCourses();
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal onClose={onCloseModal} open={openModal}>
      <Box
        autoComplete="off"
        component="form"
        noValidate
        onSubmit={handleSubmit(buildQuery)}
        sx={classes.modalContainer}
      >
        <Box sx={classes.closeModalContainer}>
          <IconButton sx={classes.iconButton} onClick={onCloseModal}>
            <CloseIcon sx={classes.icon} />
          </IconButton>
        </Box>
        <Box sx={classes.bodyContainer}>
          <Typography
            color="primary.main"
            sx={classes.modalTitle}
            variant="h2"
          >
            Filtrar
          </Typography>
          <Box display="flex" gap={10}>
            <Box width="60%">
              <>
                <Typography variant="subtitle1" color="secondary.main" mb={2}>
                  Nombre del curso
                </Typography>
                <ControlledAutocomplete
                  control={control}
                  fieldLabel="Nombre"
                  fieldName="courses"
                  multiple
                  options={coursesName}
                />
              </>
              <Box mt={4}>
                <Typography variant="subtitle1" color="secondary.main" mb={2}>
                  Seleccionar estado
                </Typography>
                <ControlledAutocomplete
                  control={control}
                  fieldLabel="Estado"
                  fieldName="installmentStatuses"
                  multiple
                  options={statusesOptions}
                />
              </Box>
              <Box mt={4}>
                <Typography variant="subtitle1" color="secondary.main" mb={2}>
                  Documento del alumno
                </Typography>
                <ControlledAutocomplete
                  control={control}
                  fieldLabel="Documento"
                  fieldName="students"
                  multiple
                  options={usersDocuments}
                />
              </Box>
            </Box>
            <Box width="40%">
              <Typography variant="subtitle1" color="secondary.main">
                Fecha de cobro
              </Typography>
              <Box mt={2}>
                <ControlledDateField
                  control={control}
                  disabled={isPaymentDateDisabled}
                  fieldLabel="Desde"
                  fieldName="paymentDateFrom"
                  rules={rulesConstants.dateRange({
                    compareDateField: 'paymentDateTo',
                    fieldLabel: 'Desde',
                    getValues,
                    message: 'no puede ser mayor que la fecha hasta',
                  })}
                />
              </Box>
              <Box mt={2}>
                <ControlledDateField
                  control={control}
                  disabled={isPaymentDateDisabled}
                  fieldLabel="Hasta"
                  fieldName="paymentDateTo"
                  rules={rulesConstants.dateRange({
                    compareDateField: 'paymentDateFrom',
                    fieldLabel: 'Hasta',
                    getValues,
                    message: 'no puede ser menor que la fecha desde',
                  })}
                />
              </Box>
              <Box mt={4}>
                <Typography variant="subtitle1" color="secondary.main" mb={2}>
                  Número de cuota
                </Typography>
                <ControlledAutocomplete
                  control={control}
                  fieldLabel="Número de cuota"
                  fieldName="installmentsNumber"
                  multiple
                  options={installmentsOptions}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={classes.buttonsContainer}>
          <Button
            onClick={() => reset(initialValues)}
            sx={classes.leftButton}
            variant="outlined"
          >
            REINICIAR
          </Button>
          <Box sx={classes.rightButtonsContainer}>
            <Button
              onClick={onCloseModal}
              sx={classes.middleButton}
              variant="outlined"
            >
              VOLVER
            </Button>
            <Button
              sx={classes.rightButton}
              type="submit"
              variant="contained"
            >
              EXPORTAR
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ExportModal;
