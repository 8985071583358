const classes = {
  buttonsContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: { xs: 'column', sm: 'row' },
    gap: 1,
    justifyContent: 'space-between',
    mt: 8,
    px: { xs: 0, sm: 6 },
    width: '100%',
  },
  bodyContainer: {
    px: { xs: 0, sm: 6 },
    width: '100%',
  },
  closeModalContainer: {
    display: 'flex',
    justifyContent: 'end',
    width: '100%',
  },
  icon: {
    color: 'secondary.main',
  },
  iconButton: {
    padding: 0,
  },
  leftButton: {
    maxWidth: '150px',
  },
  middleButton: {
    marginRight: { xs: 0, sm: '13px' },
    maxWidth: '150px',
  },
  modalContainer: {
    alignItems: 'center',
    bgcolor: 'background.paper',
    borderRadius: '0px',
    boxShadow: '0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    left: '50%',
    maxWidth: '804px',
    p: '16px 16px 48px',
    position: 'absolute' as 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
  },
  modalTitle: {
    fontWeight: 600,
    mb: 4,
  },
  rightButton: {
    maxWidth: '150px',
  },
  rightButtonsContainer: {
    display: 'flex',
    flexDirection: { xs: 'column', sm: 'row' },
    gap: 1,
  },
};

export default classes;
