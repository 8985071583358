const classes = {
  selectPaymentForm: {
    maxWidth: '328px',
    width: '100%',
  },
  textBold: {
    fontWeight: 'bold',
  },
  selectPaymentFormControl: {
    minHeight: '157px',
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  selectPaymentFormRadioGroup: {
    boxSizing: 'border-box',
  },
  radio: {
    padding: '8px',
  },
  selectPaymentFormControlLabel: {
    marginX: 0,
  },
};

export default classes;
