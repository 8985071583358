import {
  memo,
  useEffect,
  useState,
} from 'react';
import {
  Box,
  Button,
  Typography,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import InfiniteScroll from 'react-infinite-scroll-component';

import { useAuth } from '../../contexts/auth/context';
import { useEnrollments } from '../../contexts/enrollments/context';

import FilterButton from '../../components/Buttons/FilterButton';
import Layout from '../../components/Layouts/Layout';
import ModalCourse from '../../components/Modals/ModalCourse';
import SeeDetailsModal from '../../components/Modals/SeeDetailsModal';
import TableSearchInput from '../../components/Inputs/TableSearchInput';
import Tooltip from '../../components/Tooltip';

import { ROLES } from '../../helpers/roles';

import {
  EnrollmentInterface,
  QueryGetEnrollmentsInterface,
  RowInterface,
} from '../../interfaces/enrollment';

import { QUERY_LIMIT, START_PAGE_SIZE } from '../../common/constants';
import {
  buildRows,
  columns,
  optionsFilter,
} from './constants';

import classes from './classes';

const Enrollments = () => {
  const { state: { auth: user } } = useAuth();
  const { fetchEnrollments, removeEnrollment } = useEnrollments();
  const [openDetailsModal, setOpenDetailsModalModal] = useState<boolean>(false);
  const [selectedEnrollment, setSelectedEnrollment] = useState<EnrollmentInterface>(null);
  const [enrollments, setEnrollments] = useState<EnrollmentInterface[]>([]);
  const isAdmin = user.role !== ROLES.ADMIN;

  const [isGetEnrollmentsLoading, setIsGetEnrollmentsLoading] = useState<boolean>(false);
  const [rows, setRows] = useState<RowInterface[]>([]);
  const [queryParam, setQueryParam] = useState<QueryGetEnrollmentsInterface>({
    limit: QUERY_LIMIT,
    moreInfo: true,
    page: START_PAGE_SIZE,
    state: 'enrolled',
  });
  const [search, setSearch] = useState<string>('');
  const [searchInternalCourseId, setSearchInternalCourseId] = useState<string>('');
  const [hasMoreRows, setHasMoreRows] = useState<boolean>(false);
  const [openModalRemoveEnrollment, setOpenModalRemoveEnrollment] = useState<boolean>(false);
  const [rowSelectedToRemove, setRowSelectedToRemove] = useState<string>(null);

  const columnsActions: GridColDef[] = [{
    field: 'seeDetails',
    headerName: '',
    width: 100,
    hideSortIcons: true,
    renderCell: (cell) => (
      <Button
        onClick={() => {
          setOpenDetailsModalModal(true);
          setSelectedEnrollment(
            // eslint-disable-next-line no-underscore-dangle
            enrollments.find((enrollment) => enrollment._id === cell.row.id),
          );
        }}
        sx={classes.seeDetailsButton}
        variant="text"
      >
        {cell.value}
      </Button>
    ),
  },
  {
    field: 'removeEnrollment',
    headerName: '',
    hideSortIcons: true,
    renderCell: (cell) => (
      <Button
        variant="text"
        sx={classes.deleteButton}
        onClick={() => {
          setOpenModalRemoveEnrollment(true);
          setRowSelectedToRemove(cell.row.id);
        }}
      >
        {cell.value}
      </Button>
    ),
    width: 100,
  }];

  const getEnrollments = async () => {
    try {
      setIsGetEnrollmentsLoading(true);

      const { enrollments: enrollmentsFetched } = await fetchEnrollments(queryParam);
      const enrollmentsHasMoreRows = enrollmentsFetched.length === QUERY_LIMIT;

      setHasMoreRows(enrollmentsHasMoreRows);
      if (enrollmentsFetched.length) {
        const enrollmentsIds = new Set(enrollments.map(({ _id }) => _id));
        const newEnrollments = [
          ...enrollments,
          ...enrollmentsFetched.filter(({ _id }) => !enrollmentsIds.has(_id)),
        ];
        setEnrollments(newEnrollments);
        const newEnrollmentsRows = buildRows(newEnrollments);
        setRows(newEnrollmentsRows);
      }
    } finally {
      setIsGetEnrollmentsLoading(false);
    }
  };

  useEffect(() => {
    getEnrollments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParam]);

  const setInitValues = (attributesToUpdate?: Partial<QueryGetEnrollmentsInterface>) => {
    setEnrollments([]);
    setRows([]);
    setQueryParam((prevState) => ({
      ...prevState,
      ...attributesToUpdate,
      page: START_PAGE_SIZE,
    }));
  };

  const fetchNextEnrollments = () => {
    setQueryParam((prevQuery) => ({
      ...prevQuery,
      page: prevQuery.page + 1,
    }));
  };

  const onClickRemoveEnrollment = async () => {
    const enrollmentRemoved = await removeEnrollment(rowSelectedToRemove);
    if (enrollmentRemoved) {
      setOpenModalRemoveEnrollment(false);
      setInitValues();
    }
  };

  useEffect(() => {
    setInitValues({
      search: search || null,
      internalCourseId: null,
    });
  }, [search]);

  useEffect(() => {
    setInitValues({
      search: null,
      internalCourseId: searchInternalCourseId || null,
    });
  }, [searchInternalCourseId]);

  return (
    <>
      <Layout marginContainer={false}>
        <Box sx={classes.contentContainer}>
          <Box sx={classes.topContainer}>
            <Typography variant="h1" sx={classes.pageTitle}>
              Inscriptos
            </Typography>
            <Box sx={classes.optionsContainer}>
              <TableSearchInput
                disabled={!!searchInternalCourseId}
                endAdornment={(
                  <Tooltip title="Buscar por nombre, apellido, DNI o email..." />
                )}
                label="Buscar"
                onSearch={setSearch}
              />
              <TableSearchInput
                disabled={!!search}
                label="Buscar por ID Interno"
                onSearch={setSearchInternalCourseId}
              />
              <FilterButton
                options={optionsFilter}
                query={queryParam}
                setQuery={setInitValues}
              />
            </Box>
          </Box>
          <InfiniteScroll
            dataLength={rows.length}
            hasMore={hasMoreRows}
            loader={<div />}
            next={fetchNextEnrollments}
            scrollThreshold="100px"
            style={classes.infiniteScroll}
          >
            <Box sx={{
              width: isAdmin ? 'calc(100% - 100px)' : 'calc(100% - 200px)',
            }}
            >
              <DataGrid
                autoHeight
                columns={columns}
                disableColumnFilter
                disableColumnMenu
                disableRowSelectionOnClick
                hideFooter
                loading={isGetEnrollmentsLoading}
                rows={rows}
                sx={classes.dataGrid}
              />
            </Box>
            <Box width={isAdmin ? '100px' : '200px'}>
              <DataGrid
                autoHeight
                columns={isAdmin ? [columnsActions[0]] : columnsActions}
                disableColumnFilter
                disableColumnMenu
                disableRowSelectionOnClick
                hideFooter
                loading={isGetEnrollmentsLoading}
                rows={rows}
                sx={classes.dataGrid}
              />
            </Box>
          </InfiniteScroll>
        </Box>
      </Layout>
      <ModalCourse
        onClickButton={() => onClickRemoveEnrollment()}
        onCloseModal={() => setOpenModalRemoveEnrollment(false)}
        openModal={openModalRemoveEnrollment}
        typeModal="removeEnrollment"
      />
      <SeeDetailsModal
        enrollment={selectedEnrollment}
        onCloseModal={() => setOpenDetailsModalModal(false)}
        openModal={openDetailsModal}
      />
    </>
  );
};

export default memo(Enrollments);
