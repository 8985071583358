import { createTheme } from '@mui/material/styles';

import InterBoldTTF from './fonts/Inter-Bold.ttf';
import InterTTF from './fonts/Inter-Regular.ttf';
import InterMediumTTF from './fonts/Inter-Medium.ttf';
import colors from './colors';

const theme = createTheme({
  palette: {
    primary: {
      main: colors.blue,
      light: colors.lightGrey,
    },
    secondary: {
      main: colors.violet,
      light: colors.lightViolet,
    },
    error: {
      main: colors.red,
    },
    warning: {
      main: colors.warningYellow,
    },
    background: {
      paper: colors.white,
    },
    text: {
      primary: colors.black,
      disabled: colors.disabled,
    },
    success: {
      main: colors.successGreen,
    },
  },
  typography: {
    fontFamily: ['Inter', 'sans-serif'].join(','),
    h1: {
      fontWeight: 600,
      fontSize: '24px',
      lineHeight: '29.05px',
    },
    h2: {
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '24.2px',
    },
    h3: {
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '18px',
      letterSpacing: 0.15,
    },
    h4: {
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '18px',
      letterSpacing: 0.15,
    },
    h5: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '18px',
    },
    h6: {
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '13px',
    },
    subtitle1: {
      fontSize: '11px',
      fontWeight: 500,
      lineHeight: '13.31px',
      letterSpacing: '0.15px',
    },
    subtitle2: {
      fontSize: '10px',
      fontWeight: 500,
      lineHeight: '14px',
      color: colors.disabled,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family:'Inter';
          font-weight: normal;
          font-style: normal;
          src: url(${InterTTF}) format('truetype');
          unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF';
        }
        @font-face {
          font-family:'Inter';
          font-weight: bold;
          font-style: normal;
          src: url(${InterBoldTTF}) format('truetype');
          unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF';
        }
        @font-face {
          font-family:'Inter';
          font-weight: 500;
          font-style: normal;
          src: url(${InterMediumTTF}) format('truetype');
          unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF';
        }
        p {
          font-size: 13px;
        }
        h2 {
          font-size: 20px;
        }
        h1 {
          font-size: 24px;
        }
      `,
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          zIndex: 6000,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: colors.lightGrey,
          color: colors.black,
          boxShadow: 'none',
          boxSizing: 'border-box',
          height: '91px',
          minHeight: '91px',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          background: colors.lightGrey,
          color: colors.black,
          width: '192px',
          boxSizing: 'border-box',
          minHeight: '100vh',
          display: 'flex',
          alignItems: 'center',
          borderRight: 'none',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          height: '91px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          minWidth: '177px',
          textTransform: 'uppercase',
          borderRadius: '0px',
          padding: '12px 16px',
          gap: '10px',
          height: '34px',
        },
        contained: {
          background: colors.blue,
          color: colors.white,
        },
        containedSecondary: {
          background: colors.violet,
          color: colors.white,
        },
        containedInfo: {
          background: colors.white,
          color: colors.black,
          boxShadow: 'none',
          fontWeight: 600,
          '&:hover': {
            background: colors.white,
            color: colors.black,
            boxShadow: 'none',
          },
        },
        containedError: {
          background: colors.red,
          color: colors.white,
        },
        outlined: {
          borderColor: colors.blue,
          color: colors.blue,
        },
        outlinedSecondary: {
          borderColor: colors.violet,
          color: colors.violet,
        },
        outlinedInfo: {
          borderColor: colors.white,
          color: colors.white,
          '&:hover': {
            background: 'transparent',
            borderColor: colors.white,
            boxShadow: 'none',
          },
        },
        outlinedError: {
          borderColor: colors.red,
          color: colors.red,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          height: '48px',
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          background: 'inherit',
          color: colors.disabled,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        colorSecondary: {
          backgroundColor: colors.lightViolet,
          letterSpacing: '0.8px !important',
          '& .MuiChip-label': {
            color: colors.violet,
          },
        },
        colorWarning: {
          backgroundColor: colors.warningOrange,
          letterSpacing: '0.8px !important',
          '& .MuiChip-label': {
            color: colors.white,
          },
        },
      },
    },
  },
});

export default theme;
