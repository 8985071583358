import { memo, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import {
  EditOutlined as EditIcon,
  FilterList as FilterListIcon,
  Help as HelpIcon,
  Visibility as VisibilityIcon,
} from '@mui/icons-material';
import InfiniteScroll from 'react-infinite-scroll-component';

import { useAuth } from '../../contexts/auth/context';
import { usePayments } from '../../contexts/payments/context';

import Export from '../../components/Modals/Export';
import Layout from '../../components/Layouts/Layout';
import PaymentDetailsModal from '../../components/Modals/PaymentDetails';
import TableSearchInput from '../../components/Inputs/TableSearchInput';

import { QUERY_LIMIT, START_PAGE_SIZE } from '../../common/constants';
import { buildRows, getColumns } from './constants';

import { FilterInterface } from './types';

import { ROLES } from '../../helpers/roles';

import {
  FiltersExportReport,
  PaymentDisplayStatuses,
  PaymentStatuses,
  PopulatedPaymentInterface,
  QueryGetPaymentsInterface,
  RowInterface,
  UpdateInstallmentsInterface,
} from '../../interfaces/payments';

import classes from './classes';

const Collections = () => {
  const { state } = useAuth();
  const { role } = state.auth;
  const isAdmin = role === ROLES.ADMIN;
  const columns = getColumns(isAdmin);
  const { fetchPayments, updatePayment, handleExport } = usePayments();
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState<RowInterface[]>([]);
  const [queryParam, setQueryParam] = useState<QueryGetPaymentsInterface>({
    limit: QUERY_LIMIT,
    page: START_PAGE_SIZE,
  });
  const [filters, setFilters] = useState<FilterInterface[]>(
    [{
      label: PaymentDisplayStatuses.cancelled,
      checked: false,
      value: PaymentStatuses.cancelled,
    }, {
      label: PaymentDisplayStatuses.pending,
      checked: false,
      value: PaymentStatuses.pending,
    }, {
      label: PaymentDisplayStatuses.payed,
      checked: false,
      value: PaymentStatuses.payed,
    }],
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [hasMoreRows, setHasMoreRows] = useState(true);
  const [openPaymentDetailsModal, setOpenPaymentDetailsModal] = useState(false);
  const [search, setSearch] = useState('');
  const [searchInternalCourseId, setSearchInternalCourseId] = useState<string>('');
  const [
    selectedPaymentToEdit,
    setSelectedPaymentToEdit,
  ] = useState<PopulatedPaymentInterface>(null);
  const [openExportModal, setOpenExportModal] = useState<boolean>(false);

  const openFilters = Boolean(anchorEl);

  if (isAdmin) {
    columns.push({
      field: 'editCollection',
      headerName: '',
      hideSortIcons: true,
      renderCell: (cell) => {
        const isDisabled = cell.row.status === PaymentDisplayStatuses.cancelled;
        return (
          isDisabled ? (
            <Tooltip title="Ver detalles" arrow>
              <IconButton
                sx={classes.editPaymentButton}
                onClick={() => {
                  setSelectedPaymentToEdit(cell.row);
                  setOpenPaymentDetailsModal(true);
                }}
              >
                <VisibilityIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Editar" arrow>
              <IconButton
                sx={classes.editPaymentButton}
                onClick={() => {
                  setSelectedPaymentToEdit(cell.row);
                  setOpenPaymentDetailsModal(true);
                }}
                disabled={isDisabled}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          )
        );
      },
      sortable: false,
      width: 100,
    });
  } else {
    columns.push({
      field: 'seeDetails',
      headerName: '',
      hideSortIcons: true,
      renderCell: (cell) => (
        <Tooltip title="Ver detalles" arrow>
          <IconButton
            sx={classes.editPaymentButton}
            onClick={() => {
              setSelectedPaymentToEdit(cell.row);
              setOpenPaymentDetailsModal(true);
            }}
          >
            <VisibilityIcon />
          </IconButton>
        </Tooltip>
      ),
      sortable: false,
      width: 100,
    });
  }

  const handleOnClickFilters = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOnCloseFilters = () => {
    setAnchorEl(null);
  };

  const changeStateCheckbox = (indexCheckbox: number) => {
    setFilters((prevState) => prevState.map((filterState, indexState) => ({
      ...filterState,
      checked: indexState === indexCheckbox ? !filterState.checked : filterState.checked,
    })));
  };

  const fetchNextPayments = () => {
    setQueryParam((prevQuery) => ({
      ...prevQuery,
      page: prevQuery.page + 1,
    }));
  };

  const getPayments = async () => {
    try {
      setIsLoading(true);

      const { payments } = await fetchPayments(queryParam);

      setHasMoreRows(payments.length === QUERY_LIMIT);

      if (payments.length) {
        const newPaymentsRows = buildRows(payments);
        setRows(rows.length ? [...rows, ...newPaymentsRows] : newPaymentsRows);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const onClickUpdatePayment = async (
    installmentsData: UpdateInstallmentsInterface[],
  ): Promise<void> => {
    try {
      await updatePayment(selectedPaymentToEdit.id, { installmentsData });
    } finally {
      setRows([]);
      setQueryParam((prevQuery) => ({
        ...prevQuery,
        page: START_PAGE_SIZE,
      }));
      setOpenPaymentDetailsModal(false);
    }
  };

  useEffect(() => {
    getPayments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParam]);

  useEffect(() => {
    if (search || searchInternalCourseId || filters) {
      setRows([]);
      const stateFilter = filters
        .filter((filter) => filter.checked)
        .map((filter) => filter.value)
        .join(',');

      setQueryParam(() => ({
        limit: QUERY_LIMIT,
        page: START_PAGE_SIZE,
        ...(stateFilter && { state: stateFilter }),
        ...(search.length > 0 && { search }),
        ...(searchInternalCourseId.length > 0 && { internalCourseId: searchInternalCourseId }),
      }));
    }
  }, [filters, search, searchInternalCourseId]);

  return (
    <Layout marginContainer={false}>
      <Box sx={classes.contentContainer}>
        <Box sx={classes.topContainer}>
          <Typography variant="h1" sx={classes.pageTitle}>
            {isAdmin ? 'Cobranzas' : 'Mis pagos'}
          </Typography>
          {isAdmin && (
            <Box sx={classes.optionsContainer}>
              <TableSearchInput
                disabled={!!searchInternalCourseId}
                endAdornment={(
                  <Tooltip title="Buscar por número de documento o nombre del curso...">
                    <IconButton
                      aria-label="search payments"
                      color="primary"
                      component="label"
                      size="small"
                      sx={{ p: 0, mb: 0.25 }}
                    >
                      <HelpIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
                label="Buscar"
                onSearch={setSearch}
              />
              <TableSearchInput
                disabled={!!search.length}
                label="Buscar por ID Interno"
                onSearch={setSearchInternalCourseId}
              />
              <Button
                id="export-payments"
                onClick={() => setOpenExportModal(true)}
                sx={classes.exportButton}
                variant="outlined"
              >
                EXPORTAR REPORTE
              </Button>
              <Button
                aria-controls={openFilters ? 'basic-menu' : undefined}
                aria-expanded={openFilters ? 'true' : undefined}
                aria-haspopup="true"
                endIcon={<FilterListIcon />}
                id="filter-payments"
                onClick={handleOnClickFilters}
                sx={classes.filtersButton}
                variant="outlined"
              >
                FILTRAR POR
              </Button>
              <Menu
                id="filter-payments"
                anchorEl={anchorEl}
                open={openFilters}
                onClose={handleOnCloseFilters}
              >
                {filters.map((filter, index) => (
                  <MenuItem
                    key={`filter-${filter.label}`}
                    onClick={() => changeStateCheckbox(index)}
                  >
                    <Checkbox checked={filter.checked} />
                    <Typography>{filter.label}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          )}
        </Box>
        <Box height="100%" sx={classes.infiniteScrollTableContainer} width="100%">
          <InfiniteScroll
            dataLength={rows.length}
            hasMore={hasMoreRows}
            loader={null}
            next={fetchNextPayments}
            scrollThreshold="100px"
            style={{ maxHeight: '100%' }}
          >
            <DataGrid
              autoHeight
              columns={columns}
              disableColumnFilter
              disableColumnMenu
              disableRowSelectionOnClick
              hideFooter
              loading={isLoading}
              rows={rows}
              sx={{ borderWidth: '0px' }}
            />
          </InfiniteScroll>
        </Box>
      </Box>
      {openExportModal && (
        <Export
          onSubmit={(query: FiltersExportReport) => {
            handleExport(query);
            setOpenExportModal(false);
          }}
          onCloseModal={() => setOpenExportModal(false)}
          openModal={openExportModal}
        />
      )}
      {openPaymentDetailsModal && (
        <PaymentDetailsModal
          onClickPaymentModify={onClickUpdatePayment}
          onCloseModal={() => setOpenPaymentDetailsModal(false)}
          openModal={openPaymentDetailsModal}
          paymentData={selectedPaymentToEdit}
        />
      )}
    </Layout>
  );
};

export default memo(Collections);
