import axios from 'axios';

import { axiosErrorHandler, axiosResponseHandler } from './handler';
import { TResponseGetLogos, TLogo } from '../interfaces/logos';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/logos`,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true,
});

const instanceForm = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/logos`,
  headers: { 'Content-Type': 'multipart/form-data' },
  withCredentials: true,
});

const getAll = async (): Promise<TResponseGetLogos> => instance
  .get('/')
  .then(axiosResponseHandler)
  .catch(axiosErrorHandler);

const create = async (
  course: FormData,
): Promise<TLogo> => instanceForm
  .post('/', course)
  .then(axiosResponseHandler)
  .catch(axiosErrorHandler);

const remove = async (
  id: string,
): Promise<TLogo> => instance
  .delete(`/${id}`)
  .then(axiosResponseHandler)
  .catch(axiosErrorHandler);

export default {
  getAll,
  create,
  remove,
};
