import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useAuth } from '../../contexts/auth/context';

const Logout = () => {
  const { logout } = useAuth();
  const [searchParams] = useSearchParams();
  const sessionExpired = searchParams.has('sessionExpired');
  const omitMoodleLogout = searchParams.has('omitMoodleLogout');

  const signOut = async () => {
    await logout({
      omitMoodleLogout,
      sessionExpired,
    });
  };

  useEffect(() => {
    signOut();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default Logout;
