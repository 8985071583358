import {
  useState,
  createContext,
  useContext,
  useMemo,
  useCallback,
} from 'react';
import MessageModal from '../../components/Modals/MessageModal';
import { ReactContainerProps } from '../../interfaces/props';
import {
  MessageVariant, Message, ModalContextType, CommonButtonProps,
} from '../../interfaces/modals';

const ModalContext = createContext<ModalContextType>({
  isOpen: false,
  openMessageModal: () => {},
});

const MessageModalProvider = ({ children }: ReactContainerProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>('');
  const [modalMessage, setModalMessage] = useState<string>('');
  const [modalVariant, setModalVariant] = useState<MessageVariant>('success');
  const [modalButtonText, setModalButtonText] = useState<string>('Volver');
  const [modalPrimaryButton, setModalPrimaryButton] = useState<Partial<CommonButtonProps>>(null);
  const [
    modalSecondaryButton,
    setModalSecondaryButton,
  ] = useState<Partial<CommonButtonProps>>(null);
  const [modalRedirectTo, setModalRedirectTo] = useState<string>(null);
  const [modalCloseButton, setModalCloseButton] = useState<() => void>(undefined);
  const [modalCenterText, setModalCenterText] = useState<boolean>(false);

  const openMessageModal = useCallback(({
    title,
    message,
    variant,
    buttonText,
    buttonRedirectTo,
    primaryButton,
    secondaryButton,
    closeButton,
    centerText = false,
  }: Message) => {
    setIsOpen(!isOpen);
    setModalTitle(title);
    setModalMessage(message);
    setModalVariant(variant);
    setModalButtonText(buttonText);
    setModalPrimaryButton(primaryButton);
    setModalSecondaryButton(secondaryButton);
    setModalRedirectTo(buttonRedirectTo);
    setModalCloseButton(closeButton);
    setModalCenterText(centerText);
  }, [isOpen, setIsOpen]);

  const value = useMemo(() => ({ isOpen, openMessageModal }), [isOpen, openMessageModal]);

  return (
    <ModalContext.Provider value={value}>
      {children}
      {isOpen && (
        <MessageModal
          open={isOpen}
          setOpen={setIsOpen}
          variant={modalVariant}
          title={modalTitle}
          message={modalMessage}
          buttonText={modalButtonText}
          primaryButton={modalPrimaryButton}
          secondaryButton={modalSecondaryButton}
          buttonRedirectTo={modalRedirectTo}
          closeButton={modalCloseButton}
          centerText={modalCenterText}
        />
      )}
    </ModalContext.Provider>
  );
};

const useMessageModal = () => useContext<ModalContextType>(ModalContext);

export { MessageModalProvider, useMessageModal };
