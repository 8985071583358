import React from 'react';
import { Controller } from 'react-hook-form';
import {
  FormControl,
  FormHelperText,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/es';

import { ControlledDateFieldProps } from '../../interfaces/inputs';

const classes = {
  datePicker: {
    '& .MuiInputBase-root': {
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '& .MuiInputBase-input': {
        paddingLeft: '0px',

      },
    },
    '& .MuiFormLabel-root': {
      left: '-12px',
    },
  },
};

const ControlledDateField = ({
  control,
  disabled,
  fieldLabel,
  fieldName,
  minDate = undefined,
  rules,
}: ControlledDateFieldProps) => (
  <Controller
    control={control}
    name={fieldName}
    rules={rules}
    defaultValue={undefined}
    render={({ field, fieldState: { error } }) => (
      <FormControl
        sx={{ textAlign: 'start' }}
        fullWidth
        variant="standard"
        error={!!error}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
          <DatePicker
            disabled={disabled}
            format="DD / MM / YYYY"
            label={fieldLabel}
            minDate={minDate}
            onChange={(e) => field.onChange(e)}
            slotProps={{ textField: { variant: 'filled', size: 'small' } }}
            sx={classes.datePicker}
            value={field.value || null}
          />
        </LocalizationProvider>
        {error && (
          <FormHelperText color="error">
            {error.message}
          </FormHelperText>
        )}
      </FormControl>
    )}
  />
);

export default React.memo(ControlledDateField);
