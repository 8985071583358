import dayjs from 'dayjs';

import { formatToValidDayjs } from '../../utils/date';

import {
  QUALIFICATION_APPROVED,
  QUALIFICATION_IN_PROGRESS,
  QUALIFICATION_PENDING,
  qualificationsDict,
} from '../../helpers/qualification';

import { Tags } from '../../interfaces/courses';

const dictModalities = {
  mixta: 'Modalidad mixta',
  presencial: 'Presencial',
  virtual: 'Virtual',
};

const BIG_CARD = 'big';
const FINALIZED = 'finalized';
const IN_PROGRESS = 'in progress';
const MEDIUM_CARD = 'medium';
const SMALL_CARD = 'small';
const TO_START = 'to start';

const getStatusDate = (startDate: string, endDate: string) => {
  const now = dayjs().startOf('day');
  if (now.isAfter(formatToValidDayjs(endDate))) return FINALIZED;
  if (now.isBefore(formatToValidDayjs(startDate))) return TO_START;
  return IN_PROGRESS;
};

const getTypeCard = (image: string, edition: string) => {
  if (image) return BIG_CARD;
  if (!image && edition) return MEDIUM_CARD;
  if (!image && !edition) return SMALL_CARD;
  throw new Error('No existe diseño para el caso con imagen pero no con edición');
};
const getDateMessage = (statusDate: string, date: string) => {
  if (statusDate === IN_PROGRESS) return 'En curso';
  if (statusDate === FINALIZED) return 'Finalizado';
  return `Inicia el ${date.slice(0, 5)}`;
};
const getColorChipForQualification = (value: string) => {
  if (value === '' || value === qualificationsDict[QUALIFICATION_PENDING]) return 'secondary';
  if (value === qualificationsDict[QUALIFICATION_APPROVED]) return 'success';
  if (value === qualificationsDict[QUALIFICATION_IN_PROGRESS]) return 'warning';
  return 'error';
};

type TagsColors = {
  [key in Tags]: string
};

const TAGS_COLORS: TagsColors = {
  [Tags.FewVacanciesLeft]: '#FF6C19',
  [Tags.Finished]: '',
  [Tags.InscriptionsOpen]: '#00AB82',
  [Tags.VacanciesFull]: '#EC0303',
};

export {
  BIG_CARD,
  dictModalities,
  FINALIZED,
  getColorChipForQualification,
  getDateMessage,
  getStatusDate,
  getTypeCard,
  IN_PROGRESS,
  MEDIUM_CARD,
  SMALL_CARD,
  TAGS_COLORS,
};
