/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';
import { useDropzone, DropzoneOptions } from 'react-dropzone';
import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  Modal,
  Typography,
} from '@mui/material';
import {
  Filter as FilterIcon,
  Close as CloseIcon,
} from '@mui/icons-material';

import formatFilename from '../../../utils/formatFilename';

import { FilesWithPreview, UploadProps } from './types';

import classes from './classes';

const allowedFormats = {
  'image/jpeg': [],
  'image/png': [],
};

const Upload = ({
  allowedFormatsHelper = 'JPG, PNG',
  files,
  isModal,
  isOnSaveDisabled = false,
  isOpen = false,
  isSignature = false,
  name = 'images',
  onClose,
  onSave = () => { },
  plural = 'imágenes',
  setValue,
  suggestedSize = '800px por 450px',
  teachers,
  teacherSignature,
  title = 'Adjuntar Imagenes',
}: UploadProps) => {
  const onDrop = useCallback((filesOnDrop: File[]) => {
    if (filesOnDrop.length) {
      const newCourseImages = [
        ...files,
        ...filesOnDrop.map((file) => Object.assign(file, { preview: URL.createObjectURL(file) })),
      ];

      if (isSignature) {
        // eslint-disable-next-line no-param-reassign
        teachers[teacherSignature].signature = newCourseImages;
        setValue('teachers', teachers);
        return;
      }

      setValue(name, newCourseImages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, isSignature, setValue, teacherSignature, teachers]);

  const dropzoneOptions: DropzoneOptions = {
    accept: allowedFormats,
    onDrop,
  };

  const {
    getInputProps,
    getRootProps,
    open,
  } = useDropzone(dropzoneOptions);

  const removeFile = (fileId: string) => {
    const newFiles = files.filter((file) => {
      if (typeof file === 'string') {
        return file !== fileId;
      }
      if (fileId === file.preview) URL.revokeObjectURL(fileId);
      return file.preview !== fileId;
    });

    if (isSignature) {
      setValue(`teachers[${teacherSignature}].signature`, newFiles);
      return;
    }

    setValue(name, newFiles);
  };

  const handleOnClick = (file: FilesWithPreview) => {
    removeFile(typeof file === 'string' ? file : file.preview);
  };

  if (isModal) {
    return (
      <Modal open={isOpen} onClose={onClose}>
        <Box sx={classes.modalContainer}>
          <Box sx={classes.closeButtonContainer}>
            <IconButton size="large" onClick={onClose} sx={classes.closeIconButton}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={classes.contentContainer}>
            <Box sx={classes.modalTitleContainer}>
              <Typography variant="h2" color="primary.main" sx={classes.title}>
                {title}
              </Typography>
              <Button variant="outlined" onClick={open}>
                AÑADIR IMAGEN
              </Button>
            </Box>
            <Box sx={classes.imagesContainer}>
              {files?.length ? (
                <List sx={classes.listContainer}>
                  {files.map((file, index) => (
                    <ListItem
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${typeof file === 'string' ? file : file.name}-${index}`}
                      sx={classes.listImage}
                    >
                      <Box sx={classes.courseImageContainer}>
                        <img
                          alt={typeof file === 'string' ? file : file.name}
                          loading="lazy"
                          src={typeof file === 'string' ? file : file.preview}
                          style={classes.courseImages}
                        />
                        <IconButton
                          onClick={() => handleOnClick(file)}
                          size="small"
                          sx={classes.deleteImage}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Box>
                      {file.name}
                      <Typography sx={classes.fileName}>
                        {typeof file === 'string' ? formatFilename(file, true) : formatFilename(file.name)}
                      </Typography>
                    </ListItem>
                  ))}
                </List>
              ) : (
                <Box {...getRootProps()} sx={classes.selectFilesMessage}>
                  <input {...getInputProps()} />
                  <FilterIcon fontSize="large" color="primary" />
                  <Typography sx={classes.dragAndDropMessage}>
                    Arrastrá y soltá tus
                    {' '}
                    {plural}
                    {' '}
                    acá o
                    {' '}
                    <Typography component="span" sx={classes.dragAndDropMessageSpan}>
                      subí un archivo
                    </Typography>
                  </Typography>
                </Box>
              )}
            </Box>
            <Box sx={classes.suggestions}>
              <Typography sx={classes.allowedFormats}>
                Formatos permitidos:
                {' '}
                {allowedFormatsHelper}
              </Typography>
              <Typography sx={classes.suggestedSize}>{suggestedSize}</Typography>
            </Box>
            <Box sx={classes.buttonContainer}>
              <Button variant="outlined" onClick={onClose}>
                Volver
              </Button>
              <Button variant="contained" onClick={onClose}>
                Aceptar
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    );
  }

  return (
    <Box sx={classes.container}>
      <Box sx={classes.contentContainer}>
        <Box sx={classes.titleContainer}>
          <Typography variant="h2" color="primary.main" sx={classes.title}>
            {title}
          </Typography>
          <Button
            disabled={isOnSaveDisabled}
            onClick={onSave}
            type="submit"
            variant="contained"
          >
            GUARDAR
          </Button>
        </Box>
        <Box sx={classes.imagesContainer}>
          {files?.length ? (
            <List sx={classes.listContainer}>
              {files.map((file, index) => (
                <ListItem
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${typeof file === 'string' ? file : file.name}-${index}`}
                  sx={classes.listImage}
                >
                  <Box sx={classes.courseImageContainer}>
                    <img
                      alt={typeof file === 'string' ? file : file.name}
                      loading="lazy"
                      src={typeof file === 'string' ? file : file.preview}
                      style={classes.courseImages}
                    />
                    <IconButton
                      onClick={() => handleOnClick(file)}
                      size="small"
                      sx={classes.deleteImage}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                  {file.name}
                  <Typography sx={classes.fileName}>
                    {typeof file === 'string' ? formatFilename(file, true) : formatFilename(file.name)}
                  </Typography>
                </ListItem>
              ))}
            </List>
          ) : (
            <Box {...getRootProps()} sx={classes.selectFilesMessage}>
              <input {...getInputProps()} />
              <FilterIcon fontSize="large" color="primary" />
              <Typography sx={classes.dragAndDropMessage}>
                Arrastrá y soltá tus
                {' '}
                {plural}
                {' '}
                acá o
                {' '}
                <Typography component="span" sx={classes.dragAndDropMessageSpan}>
                  subí un archivo
                </Typography>
              </Typography>
            </Box>
          )}
        </Box>
        <Box sx={classes.suggestions}>
          <Typography sx={classes.allowedFormats}>
            Formatos permitidos:
            {' '}
            {allowedFormatsHelper}
          </Typography>
          <Typography sx={classes.suggestedSize}>{suggestedSize}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(Upload);
