import {
  BIG_CARD,
  IN_PROGRESS,
  MEDIUM_CARD,
  SMALL_CARD,
  TAGS_COLORS,
} from './constants';

const customClasses = {
  [BIG_CARD]: {
    container: {
      minHeight: '368px',
    },
  },
  [MEDIUM_CARD]: {
    container: {
      minHeight: '222px',
    },
  },
  [SMALL_CARD]: {
    container: {
      minHeight: '183px',
    },
  },
};

const classes = {
  container: (typeCard: string, disable: boolean) => ({
    width: '274px',
    boxShadow: '0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12)',
    bg: 'background.paper',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    p: 3,
    opacity: disable ? '50%' : '100%',
    ...customClasses[typeCard].container,
  }),
  image: {
    width: '100%',
    height: '149px',
    marginBottom: '16px',
  },
  tagChip: (index: number, name: string) => ({
    backgroundColor: TAGS_COLORS[name],
    borderRadius: 0,
    color: 'white',
    cursor: 'default',
    fontSize: '12px',
    fontWeight: 600,
    position: 'absolute',
    right: 0,
    textTransform: 'uppercase',
    top: `${index * 26}px`,
  }),
  title: {
    mb: 1.25,
    height: '36px',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  },
  edition: {
    display: 'flex',
    alignItems: 'center',
    mb: 1.5,
  },
  divider: {
    bgcolor: 'secondary.main',
  },
  scheduleContainer: {
    mt: 2.5,
    mb: 2.5,
  },
  scheduleItemContainer: (statusDate?: string) => ({
    display: 'flex',
    alignItems: 'center',
    color: statusDate === IN_PROGRESS ? '#389D55' : 'inherit',
  }),
  scheduleIcon: {
    fontSize: '15px',
    mr: 0.5,
  },
  scheduleTitle: {
    lineHeight: '15px',
    letter: '0.15px',
  },
  button: {
    '&.Mui-disabled': {
      bgcolor: 'primary.main', color: 'background.paper',
    },
  },
  chip: {
    borderRadius: 0,
    color: 'white',
    letterSpacing: '0.65px',
    paddingX: 0.5,
    fontSize: '11px',
    textTransform: 'uppercase',
  },
};

export default classes;
