import { IconButton, Tooltip as MuiTooltip } from '@mui/material';
import { Help } from '@mui/icons-material';
import { Props } from './types';

const Tooltip = ({ title, icon }: Props) => (
  <MuiTooltip title={title}>
    <IconButton
      color="primary"
      component="label"
      size="small"
      sx={{ p: 0, mb: 0.25 }}
    >
      {icon || <Help fontSize="small" />}
    </IconButton>
  </MuiTooltip>
);

export default Tooltip;
