import { memo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';

import { useAuth } from '../../contexts/auth/context';

import PermissionsGate from '../Auth/PermissionsGate';
import Profile from './Profile';

import { ROLES } from '../../helpers/roles';

import {
  CustomListItemInterface,
  SideBarListSubItemInterface,
  SidebarInterface,
} from '../../interfaces/layout';

import idhsLogo from '../../assets/idhs_logo_212x86.svg';

import {
  academicOfferItems,
  enrollmentsItems,
  evaluationsItems,
  myCoursesItems,
  usersItems,
} from './constants';

import { SCOPES } from '../../common/constants';

import { sideBarClasses as classes } from './classes';

const NestedListItem = ({
  itemDirectTo,
  itemScopes,
  text,
}: SideBarListSubItemInterface) => (
  <PermissionsGate scopes={itemScopes}>
    <ListItem key={text} disablePadding sx={classes.listItem}>
      <ListItemButton sx={classes.listItemButton} component={Link} to={itemDirectTo}>
        <ListItemText primary={text} primaryTypographyProps={classes.listItemTextSubItem} />
      </ListItemButton>
    </ListItem>
  </PermissionsGate>
);

const CustomListItem = ({
  directTo,
  nestedItems,
  scopes,
  title,
}: CustomListItemInterface) => (
  <PermissionsGate scopes={scopes}>
    <>
      <ListItem key={title} disablePadding>
        <ListItemButton component={Link} to={directTo} sx={{ padding: 0 }}>
          <ListItemText primary={title} primaryTypographyProps={classes.listItemTextTitle} />
        </ListItemButton>
      </ListItem>
      {nestedItems
        ? (
          <List sx={classes.subList}>
            {nestedItems.map(
              ({ text, itemDirectTo, itemScopes }) => (
                <NestedListItem
                  key={text}
                  text={text}
                  itemDirectTo={itemDirectTo}
                  itemScopes={itemScopes}
                />
              ),
            )}
          </List>
        )
        : (<List sx={classes.subList} />)}
    </>
  </PermissionsGate>
);

const DrawerList = () => {
  const { state: { auth } } = useAuth();
  const { role } = auth;
  const isAdmin = role === ROLES.ADMIN;

  return (
    <List sx={classes.list}>
      <CustomListItem title="Usuarios" nestedItems={usersItems} scopes={[SCOPES.canSeeUsersList]} directTo="/users" />
      <CustomListItem title="Oferta académica" nestedItems={academicOfferItems} scopes={[SCOPES.canSeeCourseList, SCOPES.canSeeAcademicOffer]} directTo="/courses" />
      <CustomListItem title="Inscripciones" nestedItems={enrollmentsItems} scopes={[SCOPES.canViewInscriptionsList]} directTo="/enrollments" />
      <CustomListItem title="Evaluaciones" nestedItems={evaluationsItems} scopes={[SCOPES.canSeeEvaluationsList]} directTo="/qualifications" />
      <CustomListItem title="Mis cursos" nestedItems={myCoursesItems} scopes={[SCOPES.canViewOwnCourses]} directTo="/me/courses" />
      <CustomListItem title="Pagos" scopes={[SCOPES.canViewOwnPayments]} directTo="/" />
      <CustomListItem title={isAdmin ? 'Cobranzas' : 'Mis pagos'} scopes={[SCOPES.canViewPaymentsList]} directTo="/collections" />
      {auth.moodleId && <CustomListItem title="Campus Virtual" scopes={[SCOPES.canAccessVirtualCampus]} directTo="/campus" />}
    </List>
  );
};

const MobileDrawer = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box sx={classes.profileContainer}>
        <Profile />
        <Button
          variant="contained"
          sx={{ mt: 1.5 }}
          onClick={() => navigate('/logout')}
        >
          CERRAR SESIÓN
        </Button>
      </Box>
      <DrawerList />
    </>
  );
};

const DesktopDrawer = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box sx={classes.logoContainer}>
        <Button
          onClick={() => navigate('/courses')}
          sx={classes.buttonLogo}
        >
          <img src={idhsLogo} alt="IDHS" style={classes.logo} />
        </Button>
      </Box>
      <DrawerList />
    </>
  );
};

const Sidebar = ({ isMobileOpen, handleDrawerToggle }: SidebarInterface) => (
  <>
    <Drawer
      sx={classes.mobileDrawer}
      variant="temporary"
      anchor="right"
      open={isMobileOpen}
      onClose={handleDrawerToggle}
      ModalProps={{
        keepMounted: true,
      }}
    >
      <MobileDrawer />
    </Drawer>

    <Drawer
      sx={classes.drawer}
      variant="permanent"
      anchor="left"
      open
    >
      <DesktopDrawer />
    </Drawer>
  </>
);

export default memo(Sidebar);
