import { memo } from 'react';

import PermissionsGate from '../../components/Auth/PermissionsGate';
import CoursesReport from './CoursesReport';
import Courses from './Courses';

import { SCOPES } from '../../common/constants';

const AcademicOffer = () => (
  <>
    <PermissionsGate scopes={[SCOPES.canSeeAcademicOffer]}>
      <Courses />
    </PermissionsGate>
    <PermissionsGate scopes={[SCOPES.canSeeCourseList]}>
      <CoursesReport />
    </PermissionsGate>
  </>
);

export default memo(AcademicOffer);
