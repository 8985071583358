const formatFilename = (name: string, isUrl = false) => {
  if (isUrl) {
    const extractedNameFromUrl = name.slice(name.indexOf('static/') + 'static/'.length);
    const newFilenameFromExtractedName = `${extractedNameFromUrl.substring(0, 10)}...${extractedNameFromUrl.split('.').at(-1)}`;
    return newFilenameFromExtractedName;
  }
  const newFilename = `${name.substring(0, 10)}...${name.split('.').at(-1)}`;
  return newFilename;
};

export default formatFilename;
