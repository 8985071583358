import { Box, Typography } from '@mui/material';

const RenderHeader = (headerName: string) => (
  <Box sx={{
    color: 'secondary.main',
    lineHeight: '16px',
    whiteSpace: 'pre-wrap',
    '&:focus': {
      outlineWidth: 0,
    },
    '&:focus-within': {
      outlineWidth: 0,
    },
  }}
  >
    <Typography variant="h6">
      {headerName}
    </Typography>
  </Box>
);

export default RenderHeader;
