import {
  memo,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  DataGrid,
  GridColDef,
  // GridRenderCellParams,
  GridRowId,
  // GridTreeNodeWithRender,
} from '@mui/x-data-grid';
import {
  Edit as EditIcon,
  DeleteOutline as DeleteOutlineIcon,
} from '@mui/icons-material';
import _ from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';

import { useAuth } from '../../contexts/auth/context';
import { useCourses } from '../../contexts/courses/context';
// import { useMessageModal } from '../../contexts/messageModal/context';

import NavbarCourse from '../../components/Navbars/NavbarCourse';
import Layout from '../../components/Layouts/Layout';
import PermissionsGate from '../../components/Auth/PermissionsGate';
import FilterButton from '../../components/Buttons/FilterButton';
import TableSearchInput from '../../components/Inputs/TableSearchInput';

import { buildColumn } from '../../helpers/tables';

import { QueryGetCourses, RowCourseInterface } from '../../interfaces/courses';

import { SCOPES, START_PAGE_SIZE } from '../../common/constants';
import { buildRows, optionsFilter } from './constants';

import { coursesReportClasses as classes } from './classes';

const CoursesReport = () => {
  const { state: { auth: user } } = useAuth();
  // const { openMessageModal } = useMessageModal();
  const {
    fetchCourses,
    // removeCourse,
  } = useCourses();
  const navigate = useNavigate();

  const [search, setSearch] = useState<string>('');
  const [searchInternalCourseId, setSearchInternalCourseId] = useState<string>('');
  const [pageDataGrid, setPageDataGrid] = useState<number>(START_PAGE_SIZE);
  const [hasComponentLoaded, setHasComponentLoaded] = useState<boolean>(false);
  const [rows, setRows] = useState<RowCourseInterface[]>([]);
  const [hasMoreRows, setHasMoreRows] = useState<boolean>(false);
  const [itemNavbarSelected, setItemNavbarSelected] = useState<string>('Cursos publicados');
  const [queryParam, setQueryParam] = useState<QueryGetCourses>({
    courseType: 'published',
    limit: 10,
    page: START_PAGE_SIZE,
  });

  const setInitValues = (attributesToUpdate?: Partial<QueryGetCourses>) => {
    setRows([]);
    setQueryParam((prevState) => ({
      ...prevState,
      ...attributesToUpdate,
      page: START_PAGE_SIZE,
    }));
  };

  const itemsNavbar = [
    {
      text: 'CURSOS PUBLICADOS',
      onClick: () => {
        setInitValues({ courseType: 'published' });
      },
    },
    {
      text: 'CURSOS A PUBLICAR',
      onClick: () => {
        setInitValues({ courseType: 'toPublish' });
      },
    },
    {
      text: 'CURSOS FINALIZADOS',
      onClick: () => {
        setInitValues({ courseType: 'finalized' });
      },
    },
  ];

  useEffect(() => {
    const getRows = async () => {
      setHasComponentLoaded(false);
      const coursesFetched = await fetchCourses(queryParam);
      if (coursesFetched) {
        const newRows = rows.length > 0
          ? [...rows, ...buildRows(coursesFetched.courses)]
          : buildRows(coursesFetched.courses);

        setRows(newRows);
        setHasMoreRows(newRows.length < coursesFetched.total);
      }
      setHasComponentLoaded(true);
    };

    getRows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParam]);

  const onClickEditIcon = (id: GridRowId): void => {
    navigate(`/courses/${id}/edit`);
  };

  /*
  const onClickDeleteIcon = (cell: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>) => {
    openMessageModal({
      title: '¿Estás seguro/a que querés eliminar este curso?',
      variant: 'infoError',
      message: 'Recordá que no podés deshacer esta acción, se eliminará todo el contenido',
      primaryButton: {
        text: 'Si, eliminar',
        onClick: async () => {
          await removeCourse(String(cell.id));
          setInitValues();
          setQueryParam((prevState) => ({ ...prevState, page: START_PAGE_SIZE }));
        },
      },
      secondaryButton: {
        text: 'Volver',
      },
      centerText: true,
    });
  };
  */

  const userIsAdmin = useMemo(() => user.role === 'admin', [user]);

  const columnsMain: GridColDef[] = [
    buildColumn({
      field: 'internalCourseId',
      headerName: 'ID Interno',
    }),
    buildColumn({
      field: 'name',
      headerName: 'Nombre del curso',
      minWidth: 280,
      flex: 1,
      renderCellSlice: 48,
      cellAlign: true,
    }),
    buildColumn({
      field: 'publication_date',
      headerName: 'Periodo de publicación',
      width: 160,
      cellAlign: true,
    }),
    buildColumn({
      field: 'inscription_date',
      headerName: 'Periodo de inscripción',
      width: 180,
      cellAlign: true,
    }),
    buildColumn({
      field: 'date',
      headerName: 'Periodo de cursada',
      width: 180,
      cellAlign: true,
    }),
    buildColumn({
      field: 'modality',
      headerName: 'Modalidad',
      width: 130,
      cellAlign: true,
    }),
    buildColumn({
      field: 'speciality',
      headerName: 'Área Temática',
      minWidth: 160,
      cellAlign: true,
      renderCellSlice: 28,
    }),
    {
      field: 'see_course',
      headerName: '',
      width: 110,
      hideSortIcons: true,
      renderCell: (cell) => (
        <Button
          variant="text"
          sx={classes.seeCourseButton}
          onClick={() => navigate(`/courses/${cell.id}`)}
        >
          {cell.value}
        </Button>
      ),
    },
  ];

  const columnsActions: GridColDef[] = [{
    field: 'options',
    headerName: '',
    width: userIsAdmin ? 100 : 50,
    hideSortIcons: true,
    renderCell: (cell) => (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
        <PermissionsGate scopes={[SCOPES.canEditCourses, SCOPES.canRemoveCourses]}>
          <>
            <IconButton
              aria-label="edit"
              size="small"
              sx={{ width: '24px' }}
              onClick={() => onClickEditIcon(cell.id)}
            >
              <EditIcon color="primary" sx={{ fontSize: 16 }} />
            </IconButton>
            <Tooltip placement="top" title="Podés ocultar cursos a través de las fechas de publicación">
              <DeleteOutlineIcon color="disabled" sx={{ fontSize: 16 }} />
            </Tooltip>
          </>
        </PermissionsGate>
        <Box
          component="img"
          src="https://store-images.s-microsoft.com/image/apps.47156.14141525700856349.6cb32918-57b4-48f6-a08a-1a68b084ceb7.f58be292-bfeb-4eed-ae02-6a0c91032b0c"
          alt="moodle"
          sx={{ width: 24, borderRadius: 50, filter: cell.row.moodle ? '' : 'saturate(0%)' }}
        />
      </Box>
    ),
  }];

  const fetchNextCourses = () => {
    setPageDataGrid(pageDataGrid + 1);
    setQueryParam((prevState) => ({ ...prevState, page: pageDataGrid + 1 }));
  };

  const title = itemNavbarSelected ? itemNavbarSelected[0].toUpperCase() + itemNavbarSelected.slice(1).toLowerCase() : '';

  useEffect(() => {
    setInitValues({
      courseName: search || null,
      internalCourseId: null,
    });
  }, [search]);

  useEffect(() => {
    setInitValues({
      courseName: null,
      internalCourseId: searchInternalCourseId || null,
    });
  }, [searchInternalCourseId]);

  return (
    <Layout marginContainer={false}>
      <NavbarCourse
        items={itemsNavbar}
        itemSelected={setItemNavbarSelected}
      />
      <Box sx={classes.contentContainer}>
        <Box sx={classes.topContainer}>
          <Typography
            sx={classes.pageTitle}
            variant="h1"
          >
            {title}
          </Typography>
          <Box sx={classes.optionsContainer}>
            <FormControl
              sx={classes.formControl}
              variant="outlined"
            >
              <TableSearchInput
                disabled={!!searchInternalCourseId.length}
                label="Buscar por nombre"
                onSearch={setSearch}
              />
            </FormControl>
            <FormControl
              sx={classes.formControl}
              variant="outlined"
            >
              <TableSearchInput
                disabled={!!search.length}
                label="Buscar por ID Interno"
                onSearch={setSearchInternalCourseId}
              />
            </FormControl>
            <FilterButton
              options={optionsFilter}
              query={queryParam}
              setQuery={setInitValues}
            />
          </Box>
        </Box>
        <InfiniteScroll
          dataLength={rows.length}
          hasMore={hasMoreRows}
          loader={<div />}
          next={fetchNextCourses}
          scrollThreshold="100px"
          style={classes.infiniteScroll}
        >
          <Box sx={classes.columnsMainContainer}>
            <DataGrid
              autoHeight
              columns={columnsMain}
              disableColumnFilter
              disableColumnMenu
              disableRowSelectionOnClick
              hideFooter
              loading={!hasComponentLoaded}
              rows={rows}
              sx={classes.dataGrid}
            />
          </Box>
          <Box width="100px">
            <DataGrid
              autoHeight
              columns={columnsActions}
              disableColumnFilter
              disableColumnMenu
              disableRowSelectionOnClick
              hideFooter
              loading={!hasComponentLoaded}
              rows={rows}
              sx={classes.dataGrid}
            />
          </Box>
        </InfiniteScroll>
      </Box>
    </Layout>
  );
};

export default memo(CoursesReport);
