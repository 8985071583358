const optionsFilters = [
  {
    label: 'Calificacion pendiente',
    key: 'qualification',
    value: 'pending',
  },
  {
    label: 'No finalizado',
    key: 'qualification',
    value: 'inProgress',
  },
  {
    label: 'Finalizado y no aprobado',
    key: 'qualification',
    value: 'finalized',
  },
  {
    label: 'Finalizado y aprobado',
    key: 'qualification',
    value: 'approved',
  },
];

export {
  // eslint-disable-next-line import/prefer-default-export
  optionsFilters,
};
