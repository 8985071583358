const classes = {
  modal: {
    zIndex: 9999,
  },
  modalContainer: {
    alignItems: 'center',
    bgcolor: 'background.paper',
    boxShadow: '0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    left: '50%',
    maxWidth: '662px',
    padding: '16px 16px 48px',
    position: 'absolute' as 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
  },
  closeButtonContainer: {
    display: 'flex',
    justifyContent: 'end',
    width: '100%',
  },
  closeIconButton: {
    color: 'primary.main',
    padding: 0,
  },
  messageContainer: {
    maxWidth: '527px',
    mt: 4,
    textAlign: 'center',
  },
};

export default classes;
