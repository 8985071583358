import { enrollmentStatesDict } from '../../../common/constants';

import { DiscountTypes, EnrollmentInterface } from '../../../interfaces/enrollment';

const buildModalData = (enrollment: EnrollmentInterface) => [{
  label: 'Nombre y apellido:',
  value: `${enrollment.user.first_name} ${enrollment.user.last_name}`,
},
{
  label: 'Documento:',
  value: enrollment.user.document,
},
{
  label: 'Email:',
  value: enrollment.user.email,
},
{
  label: 'Curso al que se inscribió:',
  value: enrollment.course.name,
},
{
  label: 'Fecha de inscripción:',
  value: enrollment.lastState.date,
},
{
  label: 'Categoría de usuario:',
  value: enrollment.userCategory || 'No tiene',
},
{
  label: 'Descuento:',
  value: enrollment.discountType === DiscountTypes.Fixed ? `$${enrollment.discount || 0}` : `${enrollment.discount || 0}%`,
},
{
  label: 'Estado de inscripción:',
  value: enrollmentStatesDict[enrollment.lastState.type],
},
];

export {
  // eslint-disable-next-line import/prefer-default-export
  buildModalData,
};
