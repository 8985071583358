import { ReactContainerProps } from '../interfaces/props';
import { MessageModalProvider } from './messageModal/context';
import AuthProvider from './auth/context';
import CoursesProvider from './courses/context';
import PaymentsProvider from './payments/context';
import UsersProvider from './users/context';
import EnrollmentsProvider from './enrollments/context';
import ConfigurationProvider from './logos/context';

const IDHSProvider = ({ children }: ReactContainerProps) => (
  <AuthProvider>
    <MessageModalProvider>
      <UsersProvider>
        <CoursesProvider>
          <EnrollmentsProvider>
            <PaymentsProvider>
              <ConfigurationProvider>
                {children}
              </ConfigurationProvider>
            </PaymentsProvider>
          </EnrollmentsProvider>
        </CoursesProvider>
      </UsersProvider>
    </MessageModalProvider>
  </AuthProvider>
);

export default IDHSProvider;
