import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Grid,
  Typography,
  Button,
  Modal,
  IconButton,
  Autocomplete,
  TextField,
} from '@mui/material';
import {
  Close as CloseIcon,
} from '@mui/icons-material';
import LoadingButton from '../../components/Controls/LoadingButton';
import { RowQualificationInterface } from '../../interfaces/enrollment';
import { OptionInterface } from '../../interfaces/inputs';

interface Props {
  openModal: boolean;
  onCloseModal: () => void;
  onClickButton: (newQualification: string) => Promise<void>;
  enrollment: RowQualificationInterface;
}

const classes = {
  modalContainer: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    p: 2,
    boxShadow: '0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12)',
    borderRadius: '0px',
    maxWidth: '502px',
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'left',
    alignItems: 'left',
    gap: 2,
  },
  modalContentContainer: {
    pt: 1,
    px: 4,
    pb: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'left',
    alignItems: 'left',
    gap: 2,
  },
  modalTitle: {
    fontWeight: 600,
  },
  modalButtonContainer: {
    marginTop: 1,
    width: '100%',
    gap: { xs: 2, sm: 4 },
  },
  closeButtonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
  },
  closeIconButton: {
    padding: 0,
    color: '#909BD7',
  },
};

const qualifications: OptionInterface[] = [
  { id: 'pending', name: 'Calificación pendiente' },
  { id: 'inProgress', name: 'No finalizado' },
  { id: 'finalized', name: 'Finalizado y no aprobado' },
  { id: 'approved', name: 'Finalizado y aprobado' },
];

const EditQualificationModal = ({
  openModal, onCloseModal, onClickButton, enrollment,
}: Props) => {
  const [field, setField] = useState<string>(enrollment?.qualification || '');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleOnClick = async (): Promise<void> => {
    setIsLoading(true);

    const newQualification = qualifications.find(({ name }: OptionInterface) => field === name).id;
    await onClickButton(newQualification);

    setIsLoading(false);
  };

  useEffect(() => {
    setField(enrollment?.qualification);
  }, [enrollment?.qualification]);

  const isThereErrorInQualificationInput = useMemo(() => field === '' || field === undefined, [field]);

  return (
    <Modal
      open={openModal}
      onClose={onCloseModal}
      aria-labelledby="qualification-modal-title"
      aria-describedby="qualification-modal-description"
    >
      <Box sx={classes.modalContainer}>
        <Box sx={classes.closeButtonContainer}>
          <IconButton size="large" onClick={onCloseModal} sx={classes.closeIconButton}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={classes.modalContentContainer}>
          <Typography variant="h2" color="primary.main" sx={classes.modalTitle}>
            {enrollment?.qualification === 'pending'
              ? 'Calificar alumno/a'
              : 'Editar calificación'}
          </Typography>
          <Typography variant="h5">
            Seleccioná una calificación
          </Typography>
          <Box sx={{ display: 'flex', gap: 0.5 }}>
            <Typography variant="h5" color="secondary.main">
              Alumno/a:
            </Typography>
            <Typography variant="h5">
              {enrollment?.studentName}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: 0.5 }}>
            <Typography variant="h5" color="secondary.main">
              Documento:
            </Typography>
            <Typography variant="h5">
              {enrollment?.document.split(' ')[1]}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: 0.5 }}>
            <Typography variant="h5" color="secondary.main">
              Curso al que se inscribió:
            </Typography>
            <Typography variant="h5">
              {enrollment?.courseEnrolled}
            </Typography>
          </Box>

          <Autocomplete
            disablePortal
            value={qualifications.find(({ name }: OptionInterface) => field === name) || ''}
            onChange={(e, v: OptionInterface) => setField(v?.name || '')}
            options={qualifications}
            onKeyDown={(e) => e.stopPropagation()}
            getOptionLabel={(option: OptionInterface) => option.name || ''}
            renderInput={(params) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                error={isThereErrorInQualificationInput}
                helperText={isThereErrorInQualificationInput && 'Calificación es requerida'}
                label="Calificación"
                variant="standard"
              />
            )}
          />
          <Grid container sx={classes.modalButtonContainer}>
            <Grid item flexGrow={1}>
              <Button variant="outlined" fullWidth onClick={onCloseModal}>
                Volver
              </Button>
            </Grid>
            <Grid item flexGrow={1}>
              <LoadingButton loading={isLoading} variant="contained" fullWidth onClick={handleOnClick}>
                {enrollment?.qualification === 'pending' ? 'Aceptar' : 'Guardar'}
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default React.memo(EditQualificationModal);
