import { memo } from 'react';
import { Box, Typography } from '@mui/material';

import AuthLayout from '../../components/Layouts/AuthLayout';
import LoginForm from './LoginForm';

import { loginClasses as classes } from './classes';

const Login = () => (
  <AuthLayout>
    <Box mb={4.5} sx={classes.title}>
      <Typography variant="h1" color="primary.main">
        ¡Hola! Ingresa a tu cuenta
      </Typography>
    </Box>
    <LoginForm />
  </AuthLayout>
);

export default memo(Login);
