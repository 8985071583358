import { GridColDef } from '@mui/x-data-grid';

import RenderHeader from './components/renderHeader';

import { formatPrice } from '../../../helpers/payments';

import { InstallmentStatuses, PopulatedPaymentInterface } from '../../../interfaces/payments';

interface RowInterface {
  amount: string;
  detail: string;
  id: number | 'preinscription';
  paymentDate: Date;
  reason: string;
  status: InstallmentStatuses;
}

const buildColumn = ({
  field,
  flex = null,
  headerName,
  width,
}: GridColDef): GridColDef => ({
  field,
  flex,
  headerName,
  hideSortIcons: true,
  minWidth: width,
  renderHeader: () => RenderHeader(headerName),
  sortable: false,
  width,
});

const buildRows = (
  paymentData: PopulatedPaymentInterface,
): RowInterface[] => paymentData.installmentsData.map(({
  amount,
  installment,
  paymentDate,
  reason,
  status,
}) => {
  const lastInstallmentNumberForDisplay = paymentData.installmentsData.length - (paymentData.installmentsData[0].installment === 'preinscription' ? 1 : 0);

  return ({
    amount: formatPrice(String(amount)),
    detail: installment === 'preinscription'
      ? 'Preinscripción'
      : `Cuota ${installment} de ${lastInstallmentNumberForDisplay}`,
    id: installment,
    paymentDate: paymentDate || null,
    reason,
    status,
  });
});

export {
  buildColumn,
  buildRows,
  RowInterface,
};
