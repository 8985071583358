import { Dispatch, ReactNode } from 'react';
import dayjs from 'dayjs';

import UserInterface from './user';
import CourseInterface, { TariffCategory } from './courses';

export enum TypeAction {
  REQUESTING_DATA = 'REQUESTING_DATA',
  GET_PAYMENT = 'GET_PAYMENT',
}

// CoursesContext Interfaces
export type Action = {
  type: TypeAction.REQUESTING_DATA;
} | {
  type: TypeAction.GET_PAYMENT;
  payment: PaymentInterface
};

export interface PaymentsContextType {
  state: { payment: PaymentInterface };
  dispatch: Dispatch<Action>;
}

// Payment Interfaces
export enum InstallmentStatuses {
  Approved = 'approved',
  Cancelled = 'cancelled',
  Pending = 'pending',
}

export enum InstallmentDisplayStatuses {
  approved = 'Aprobado',
  cancelled = 'Cancelado',
  pending = 'Pendiente',
}

export const installmentDisplayPreinscription = 'Preinscripción';
export const installmentPreinscription = 'preinscription';

export enum PaymentStatuses {
  cancelled = 'cancelled',
  payed = 'payed',
  pending = 'pending',
}

export enum PaymentDisplayStatuses {
  cancelled = 'Cancelado',
  payed = 'Confirmado',
  pending = 'Pendiente',
}

export enum PaymentDisplayMethods {
  mercadopago = 'Mercadopago',
  mercadopagoSubscription = 'Mercadopago',
  paymentLink = 'Link de pago',
}

export enum PaymentDisplayDocumentTypes {
  dni = 'DNI',
  cuil = 'CUIL',
  cuit = 'CUIT',
  le = 'LE',
}

export enum PaymentMethod {
  Mercadopago = 'mercadopago',
  MercadopagoSubscription = 'mercadopagoSubscription',
  PaymentLink = 'paymentLink',
}

export interface CreatePaymentInterface {
  course: string,
  mpRequest?: object,
  method: PaymentMethod,
  conceptUser: string,
}

export interface UpdateInstallmentsInterface {
  installment: number | 'preinscription';
  paymentDate: Date;
  reason: string;
  status: InstallmentStatuses;
}

interface Installment {
  amount: number;
  installment: number | 'preinscription';
  mpRequest?: object;
  mpResponse?: object;
  paymentDate?: Date;
  reason?: string;
  status: InstallmentStatuses;
}
export default interface PaymentInterface {
  conceptUser: TariffCategory;
  course: string;
  currency: 'ars';
  id: string;
  installmentsData: Installment[];
  method: PaymentMethod;
  status: PaymentDisplayStatuses;
  user: string;
}

export interface PopulatedPaymentInterface extends Omit<PaymentInterface, 'course' | 'user'> {
  _id?: string;
  course: CourseInterface;
  courseName?: string;
  user: UserInterface;
}
export interface QueryGetPaymentsInterface {
  limit?: number;
  page?: number;
  state?: string;
  search?: string;
}

export interface FiltersExportReport {
  courses?: string[];
  installmentStatuses?: InstallmentStatuses[];
  installmentsNumber?: string[];
  paymentDateFrom?: dayjs.Dayjs | null;
  paymentDateTo?: dayjs.Dayjs | null;
  students?: string[];
}

export interface RowInterface {
  amount: string;
  courseName: string;
  document: string;
  editCollection?: ReactNode;
  email: string;
  fullName: string;
  id: string;
  isPartner: string;
  method: string;
  seeDetails?: ReactNode;
  status: ReactNode;
}

export interface ResGetAllPaymentsInterface {
  payments: PopulatedPaymentInterface[];
  page: number;
  total: number;
}

export type DownloadPaymentProps = {
  courseName: string;
  installmentId: string;
  paymentId: string;
};
