const coursesReportClasses = {
  pageTitle: {
    fontSize: '32px',
    fontWeight: 700,
    marginRight: { md: 1, lg: 0 },
  },
  optionsContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: {
      md: 'row',
      xs: 'column',
    },
    gap: 3,
    justifyContent: 'center',
  },
  inputOptions: {
    borderRadius: 0,
    height: '34px',
    pl: 1,
    '&:hover': {
    },
    'MuiOutlinedInput-notchedOutline': { border: '1px solid #909BD7' },
  },
  loaderContainer: {
    display: 'flex',
    height: '65px',
    justifyContent: 'center',
    pt: 1,
  },
  columnsMainContainer: {
    width: 'calc(100% - 100px)',
  },
  formControl: {
    width: '235px',
  },
  dataGrid: {
    borderWidth: '0px',
  },
  infiniteScroll: {
    display: 'flex',
    maxHeight: '100%',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    gap: {
      sm: 6,
      xs: 3,
    },
    height: 'auto',
    paddingX: 7,
    paddingY: 5,
  },
  topContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: {
      md: 'row',
      xs: 'column',
    },
    gap: {
      md: 0,
      xs: 3,
    },
    justifyContent: 'space-between',
  },
  seeCourseButton: {
    color: '#909BD7',
    height: 'auto',
    minWidth: 'auto',
    p: 0,
    textTransform: 'none',
  },
};

const coursesClasses = {
  pageTitleContainer: {
    display: 'flex',
    flexDirection: {
      xs: 'column',
      md: 'row',
    },
    justifyContent: {
      xs: 'flex-start',
      md: 'space-between',
    },
    alignItems: 'center',
    textAlign: { xs: 'center', md: 'left' },
    width: '100%',
  },
  pageTitle: {
    fontSize: '32px',
    letterSpacing: '0.15px',
    lineHeight: '39px',
    marginBottom: {
      xs: '20px',
      md: '0px',
    },
  },
  coursesGridContainer: {
    mt: {
      xs: 4,
      sm: 2,
    },
  },
  coursesGridItem: {
    display: { xs: 'flex' },
    justifyContent: { xs: 'center' },
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '25vh',
  },
};

export {
  coursesClasses,
  coursesReportClasses,
};
