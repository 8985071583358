const classes = {
  allowedFormats: {
    fontSize: '10px',
  },
  closeButtonContainer: {
    display: 'flex',
    justifyContent: 'end',
    width: '100%',
  },
  closeIconButton: {
    color: 'secondary.main',
    padding: 0,
  },
  courseImageContainer: {
    position: 'relative' as 'relative',
  },
  courseImages: {
    borderRadius: '9px',
    height: '100px',
    width: '100px',
  },
  deleteImage: {
    color: 'white',
    position: 'absolute' as 'absolute',
    right: 0,
    top: 0,
  },
  dragAndDropMessage: {
    cursor: 'pointer',
    fontSize: '14px',
    width: '44%',
    textAlign: 'center',
  },
  dragAndDropMessageSpan: {
    color: 'primary.main',
    fontSize: '14px',
    textDecoration: 'underline',
  },
  fileName: {
    fontSize: '10px',
  },
  listContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 4,
  },
  listImage: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    listStyle: 'none',
    padding: '0px',
    width: '100px',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: {
      xs: 'column',
      sm: 'row',
    },
    gap: { xs: 2, sm: 4 },
    justifyContent: 'end',
    marginTop: '20px',
    width: '100%',
  },
  container: {},
  modalContainer: {
    alignItems: 'left',
    borderRadius: '0px',
    bgcolor: 'background.paper',
    boxShadow: '0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12)',
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    justifyContent: 'left',
    left: '50%',
    maxWidth: '804px',
    padding: {
      xs: 1,
      sm: 2,
    },
    position: 'absolute' as 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
      xs: '94%',
      sm: '100%',
    },
  },
  contentContainer: {
    alignItems: 'left',
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    justifyContent: 'left',
    padding: {
      xs: '4px',
      sm: '8px 32px 32px 32px',
    },
  },
  imagesContainer: {
    border: '2px dotted #2B1D1D33',
    borderRadius: '4px',
    display: 'flex',
    flexWrap: 'wrap',
    height: '227px',
    marginTop: '20px',
    maxHeight: '500px',
    overflow: 'auto',
    padding: '8px',
  },
  title: {
    fontWeight: 600,
  },
  titleContainer: {
    alignItems: 'center',
    justifyContent: 'space-between',
    display: 'flex',
    gap: 4,
  },
  modalTitleContainer: {
    alignItems: 'center',
    display: 'flex',
    gap: 4,
  },
  selectFilesMessage: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
  },
  suggestedSize: {
    fontSize: '10px',
  },
  suggestions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

export default classes;
