import {
  memo,
  useMemo,
  useState,
} from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import {
  Typography,
  Grid,
  Box,
} from '@mui/material';

import { useAuth } from '../../contexts/auth/context';

import ControlledTextField from '../../components/Inputs/ControlledTextField';
import LoadingButton from '../../components/Controls/LoadingButton';

import rulesConstants from '../../helpers/rulesConstants';

import { LoginUserInterface } from '../../interfaces/user';

import { loginFormClasses as classes } from './classes';

const LoginForm = () => {
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams] = useSearchParams();
  const sessionExpired = searchParams.has('sessionExpired');
  const { login } = useAuth();

  const {
    control,
    handleSubmit,
  } = useForm<LoginUserInterface>({
    defaultValues: useMemo(() => ({
      identifier: '',
      password: '',
    }), []),
  });

  const onSubmit = async (user: LoginUserInterface): Promise<void> => {
    setLoading(true);
    const redirectTo = searchParams.get('return_url');
    const loginCandidateUser: LoginUserInterface = {
      ...user,
      identifier: user.identifier.trim(),
    };
    await login({
      redirectTo,
      user: loginCandidateUser,
    }).finally(() => setLoading(false));
  };

  if (sessionExpired) {
    enqueueSnackbar('Tu sesión ha expirado. Por favor, inicia sesión nuevamente.', { variant: 'warning' });
  }

  return (
    <Box
      autoComplete="off"
      component="form"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      sx={classes.formContainer}
    >
      <Grid container spacing={3.25}>
        <Grid item xs={12}>
          <ControlledTextField
            control={control}
            fieldLabel="Email / Documento"
            fieldName="identifier"
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            control={control}
            fieldLabel="Contraseña"
            fieldName="password"
            rules={rulesConstants.passwordField({})}
            type="password"
          />
          <Typography
            color="primary.main"
            component={Link}
            display="inline"
            fontWeight={600}
            sx={classes.forgotPasswordText}
            to="/auth/password/forgot"
            variant="h6"
          >
            ¿Olvidaste tu contraseña?
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box sx={classes.buttonContainer}>
            <LoadingButton
              color="primary"
              fullWidth
              loading={loading}
              type="submit"
              variant="contained"
            >
              INICIAR SESIÓN
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
      <Box mt={3}>
        <Typography variant="h6" display="inline">
          ¿No tienes usuario?
          {' '}
        </Typography>
        <Typography
          color="primary.main"
          component={Link}
          display="inline"
          fontWeight={600}
          sx={classes.redirectToRegisterText}
          to="/register"
          variant="h6"
        >
          Regístrate aquí
        </Typography>
      </Box>
    </Box>
  );
};

export default memo(LoginForm);
